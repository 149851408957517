import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { OrganizationService } from '../..';

export interface MediaPreviewDialogData {
  name: string;
  url: string;
  type: string;
  assetUrl: string;
  videoCaptionsUrl: string;
}

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit {
  // assetsUrl$: Observable<string> = this.organizationService.assetsUrl$;
  storageUrl: string = '';
  assetUrl: string = '';
  assetType: string = '';
  assetExtenstion: string = '';
  videoCaptionsUrl: string = '';

  constructor(
    private organizationService: OrganizationService,
    private dialogRef: MatDialogRef<MediaPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaPreviewDialogData,
  ) { }

  ngOnInit(): void {
    let imagesrc = '';
    if (this.data.type === 'application/pdf') {
      imagesrc = '../../../../assets/images/pdf.png';
    } else if (this.data.type.indexOf('audio') > -1) {
      imagesrc = '../../../../assets/images/mp3.jpg';
    } else if (this.data.type.indexOf('video') > -1) {
      imagesrc = '../../../../assets/images/mp4.png';
    } else if (this.data.type.indexOf('text') > -1) {
      imagesrc = '../../../../assets/images/text.png';
    // } else if (this.data.type.includes('x-zip')) {
    //   imagesrc = '../../../../assets/images/mp4.png';
    }

    this.assetUrl = this.data.assetUrl ? this.data.assetUrl : imagesrc;

    if (this.assetUrl && this.data.url && this.data.type) {
      this.assetExtenstion = this.data.type.split('/')[1];

      if (this.data.type.indexOf('pdf') > -1) {
        this.assetType = 'pdf';
        window.open(this.assetUrl, '_blank');
        this.dialogRef.close();
      } else if (this.data.type.indexOf('audio') > -1) {
        this.assetType = 'audio';
      } else if (this.data.type.indexOf('video') > -1) {
        this.assetType = 'video';
      } else if (this.data.type.indexOf('text') > -1) {
        this.assetType = 'text';
        window.open(this.assetUrl, '_blank');
        this.dialogRef.close();
      } else if (this.data.type.indexOf('image') > -1) {
        this.assetType = 'image';
      } else if (this.data.type.includes('x-zip')) {
        this.assetType = 'x-zip-compressed';
      }
    } else {
      this.assetType = '';
    }

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
