import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, map, Observable } from 'rxjs';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import {
  OfferPromo,
  PromoDomain,
  OfferPromoService,
  OfferService,
  Offer,
  OfferDomain,
} from '../..';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

const log = new Logger('VersionPromoCardsComponent');

@Component({
  selector: 'app-version-promo-cards',
  templateUrl: './version-promo-cards.component.html',
  styleUrls: ['./version-promo-cards.component.scss'],
})
export class VersionPromoCardsComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>,
  T extends Offer,
  TOfferDomain extends OfferDomain<Offer>
> implements OnInit, OnDestroy
{
  
  viewModel$ = combineLatest([
    this.offerpromoService.getpromoChildVersions$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.totalVersionsRecords$,
    this.offerpromoService.page$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerService.page(page);
        this.offerService.reload();
      }
      this.form = new FormGroup({
        versions: new FormArray([])
      });
      offers.map((x: any) => this.addVersion(x));
      return { offers, isLoading, totalRecords, page };
    })
  );
  form = new FormGroup({
    versions: new FormArray([]),
  });
  promoVersionsList: TPromoDomain[] = [];
  selection = new SelectionModel<TPromoDomain>(true, []);
  versionsList: any[] = [];
  readOnlyMode: boolean = false;
  PreviewMode = PreviewMode;

  constructor(
    public utilityService: UtilityService,
    private authorizationService: AuthorizeControlService,
    public offerService: OfferService<T, TOfferDomain>,
    private router: Router,
    private route: ActivatedRoute,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>
  ) {}

  ngOnInit(): void {
    log.debug('init');
    this.offerpromoService.reload();

    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.offerpromoService.offerDetailsReadOnlyMode$.subscribe((res) => {
      this.readOnlyMode = res;
    });
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

  onSort(sortState: Sort): void {
    this.offerService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerService.page(pageEvent);
  }

  onEdit(id: string) {
    this.router.navigate([`../../../${id}/promos/promodetails`], {
      relativeTo: this.route,
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.promoVersionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.promoVersionsList.forEach((row) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = JSON.parse(
      JSON.stringify(this.getSelectedSectionRecords())
    );
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateTableStatus(status: Status, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => {
        x.Detail.Status = status;
        return x.Detail;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerService.reload();
          this.offerpromoService.reload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }


  canEdit(): boolean {
    const hasAccess =
      this.authorizationService.checkAccess('edit||offermang') &&
      this.readOnlyMode != true;
    return hasAccess;
  }

  ngOnDestroy(): void {
    this.form = new FormGroup({
      versions: new FormArray([]),
    });
  }

  getVersionNames(offer: PromoDomain<OfferPromo>) {
    let versionNames = [
      { Id: offer.Detail.Id, Version: offer.Detail.OfferVariantName },
    ];
    versionNames = [...versionNames, ...offer.OfferVariants];
    return versionNames;
  }

  getSelectedVersionDetail(event: any, offer: any) {
    offer.activeItem = false;
    offer.Versions = offer.Versions.map((x: any) => (x.activeItem = false));
    if (event.value && offer) {
      if (event.value.Id === offer.Detail.Id) {
        offer.activeItem = true;
        return;
      }
      const offerVersion = offer.Versions.find(
        (x: any) => x.Id === event.value.Id
      );
      if (offerVersion) {
        offerVersion.activeItem = true;
      }
    }
  }

  getActiveOffer(offer: any) {
    if (offer.activeItem) {
      return offer;
    }
    const versionOffer = offer.Versions.find((x: any) => x.activeItem === true);
    return versionOffer;
  }

  get versions(): FormArray {
    return this.form.get('versions') as FormArray;
  }
  addVersion(offer: any) {
    if (this.versions) {
      const formcontrolInfo = new FormControl(offer);
      formcontrolInfo.disable();
      this.versions.push(formcontrolInfo);
    }
  }
}
