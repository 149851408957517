<form *ngIf="offer$ | async as OfferDetails; else loading" [formGroup]="formGroup">

    <div formGroupName="Detail" fxLayout="column">
        <h2><b> New Variant </b> </h2>
        <p>{{offerName}} will be duplicated into a new variant.</p>
        <mat-form-field class="version" appearance="outline">
            <mat-label>Variant Name</mat-label>
            <input matInput type="text" formControlName="Name" />
            <mat-error *ngIf="formGroup.controls.Detail.controls.Name.hasError('required')">
                Name is required
            </mat-error>
        </mat-form-field>

    </div>
</form>
<ng-container *ngIf="versionList && versionList.length > 0">
<table mat-table [dataSource]="versionList" matSort (matSortChange)="onSort($event)" matSortDisableClear
    [matSortStart]="'desc'">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null; getSelectedRow()" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null; getSelectedRow()"
                [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="VersionName">
        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
            Version
        </th>
        <td mat-cell *matCellDef="let record">{{ record.VersionName }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
</table>

<table *ngIf="!versionList.length">
    <tr>
        <td class="no-records" [attr.colspan]="displayedColumns.length">
            <h4>No Records Found</h4>
        </td>
    </tr>
</table>
</ng-container>



<mat-card-actions fxLayout="row" fxLayoutAlign="end">

    <div>
        <button mat-stroked-button type="button" (click)="cancel()">
            Cancel
        </button>
        <button mat-raised-button type="button" color="accent"  [disabled]="formGroup.invalid" (click)="saveData()">
            Create
        </button>
    </div>
</mat-card-actions>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>