<mat-form-field appearance="outline" fxFlex="100">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-chip-grid #chipList aria-label="Tags selection">
    <mat-chip-row *ngFor="let tag of selectedTags" (removed)="removeTag(tag)">
      {{ tag.OfferTagName }}
      <button type="button" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input [placeholder]="placeholder || 'Add Tag...'" #tagInput [readonly]="!canEdit()" [matChipInputFor]="chipList"
      [matAutocomplete]="auto" (matChipInputTokenEnd)="addTag($event)" (input)="onSearchTag($event)"
      [formControl]="offerTagsControl">
  </mat-chip-grid>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
    <ng-container *ngFor="let tag of filteredOfferTagsList$ | async">
      <mat-option [value]="tag" [disabled]="(maxTagsLength > 0) && (selectedTags.length >= maxTagsLength)">
        {{ tag.OfferTagName }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <mat-error *ngIf="offerTagsFormControl.hasError('required')">{{label}} field is required</mat-error>
  <mat-error *ngIf="formErrors && formErrors['maxlength']">
    {{ Maximum length can be ' + formErrors['maxlength'].requiredLength + '.' }}
  </mat-error>
</mat-form-field>