import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { OfferAsset } from '..';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class OfferAssetsService<T extends OfferAsset> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private offerIdBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private offerGrpAPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private offerGrpASearchBehaviorSubject = new BehaviorSubject<string>('');
  private offerGrpASortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc',});
  private offerGrpAReloadBehaviorSubject = new BehaviorSubject<string>('');
  private offerGrpAtotalRecordsCount = new BehaviorSubject<number>(0);
  private offerGrpAviewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public offerId$ = this.offerIdBehaviorSubject.asObservable();
  public offerGrpId$ = this.offerIdBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public offerGrpPage$ = this.offerGrpAPageBehaviorSubject.asObservable();
  public offerGrpSearch$ = this.offerGrpASearchBehaviorSubject.asObservable();
  public offerGrpSort$ = this.offerGrpASortBehaviorSubject.asObservable();
  public offerGrptotalRecordsCount$ = this.offerGrpAtotalRecordsCount.asObservable();
  public offerGrpviewMode$ = this.offerGrpAviewModeBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        offerId: this.offerIdBehaviorSubject.value,
        // mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogParams$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        offerId: this.offerIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

    // create the parameters observable that looks for changes in page, startDate, endDate, etc
    public offerGrpAparams$ = combineLatest([
      this.offerIdBehaviorSubject,
      this.offerGrpAPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
      this.offerGrpASortBehaviorSubject,
      this.offerGrpASearchBehaviorSubject.pipe(debounceTime(300)),
      this.offerGrpAReloadBehaviorSubject,
    ]).pipe(
      distinctUntilChanged((previous, current) => {
        // if the values coming down this pipe are the same, don't continue the pipe
        return JSON.stringify(previous) === JSON.stringify(current);
      }),
      map(([offerGroupId, page, sort, search, reload]) => {
  
        // set the query string odata parameters
        let params: HttpParams = new HttpParams({
          fromObject: {
          offerGroupId: this.offerIdBehaviorSubject.value,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $expand: 'Detail',
          $count: true
        }
      });
  
        // if there is a search, add the search to the parameters
        if (search.length) {
          params = params.append('$search', `"${search}"`);
        }
  
        return params;
      })
    );

  set offerId(offerId: string) {
    this.offerIdBehaviorSubject.next(offerId);
  }

  set offerGrpId(offerGrpId: string){
    this.offerIdBehaviorSubject.next(offerGrpId);
  }

  //get the list of assets by offerId
  private offerAssetResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetsByOfferId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //assets listing by productId
  public assets$: Observable<T[]> = this.offerAssetResponse$.pipe(
    map((res: any) => res.value)
  );

  public totalRecords$: Observable<number> = this.offerAssetResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  //get the list of assets by offerId
  private offerGrpAssetResponse$ = this.offerGrpAparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetsByOfferGroupId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //assets listing by productId
  public offerGrpassets$: Observable<T[]> = this.offerGrpAssetResponse$.pipe(
    map((res: any) => res.value)
  );

  public offerGrptotalRecords$: Observable<number> = this.offerGrpAssetResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

   // set the current page
   offerGrpPage(page: any) {
    this.offerGrpAPageBehaviorSubject.next(page);
  }

  offerGrptoggleViewMode(mode: string) {
    this.offerGrpAviewModeBehaviorSubject.next(mode);
  }

   // sets the sort property and order
   offerGrpsort(sort: any) {
    this.offerGrpASortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  offerGrpsearch(search: string) {
    this.offerGrpASearchBehaviorSubject.next(search);
    this.offerGrpAPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the store listing
  offerGrpreload() {
    // reload the Store data
    this.offerGrpAReloadBehaviorSubject.next(uuidv4());
  }

  prepareOfferIdString(offerIds: any) {
    let urlParams = '';
    if (offerIds && offerIds.length > 0) {
      for (let index = 0; index <= offerIds.length - 1; index++) {
        urlParams += `offerIds=${offerIds[index]}`;
        if (index != offerIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }


  saveRank(offerIds: string[], dataSave: OfferAsset) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateOfferAssetRank?${this.prepareOfferIdString(offerIds)}`;
    return this.httpClient.post(url, dataSave);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    if (start && end) {
      const range = { startDate: start, endDate: end };
      this.pageBehaviorSubject.next(DEFAULT_PAGING);

      this.dateRangeBehaviorSubject.next(range);
    }
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // reset the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  getRecordsForFilters() {
    return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`);
  }

  prepareOfferGrpIdString(offerGroupIds: any) {
    let urlParams = '';
    if (offerGroupIds && offerGroupIds.length > 0) {
      for (let index = 0; index <= offerGroupIds.length - 1; index++) {
        urlParams += `offerGroupIds=${offerGroupIds[index]}`;
        if (index != offerGroupIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

}
