import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Product, ProductService } from '../..';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('ProductListTableComponent');

@Component({
  selector: 'app-product-list-table',
  templateUrl: './product-list-table.component.html',
  styleUrls: ['./product-list-table.component.scss']
})
export class ProductListTableComponent<T extends Product> implements OnInit {
  viewModel$ = combineLatest([
    this.productService.products$,
    this.productService.isLoading$,
    this.productService.totalRecords$,
    this.productService.page$,
  ]).pipe(
    map(([products, isLoading, totalRecords, page]) => {
      if (products.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.productService.page(page);
        this.productService.reload();
        }
      return { products, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'ProductImage', 'ProductName', 'UPC', 'SKU', 'ClientKey', 'Actions'];
  productsList: Product[] = [];
  selection = new SelectionModel<Product>(true, []);
  PreviewMode = PreviewMode;

  constructor(private productService: ProductService<T>, public utilityService: UtilityService, private mediaObserver: MediaObserver) { }

  ngOnInit() {

    log.debug('init');
    this.productService.products$.subscribe((data) => {
      this.productsList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'ClientKey', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'UPC', 'SKU', 'ClientKey', 'Actions'];
        }
      });
  }

  onSort(sortState: Sort): void {
    this.productService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.productService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;

  }

  public clearSelection() {
    this.selection.clear();
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }

}
