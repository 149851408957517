<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <header fxLayout="row" fxLayoutAlign="space-between center" class="assets-header">
        <div class="actions-bar" fxLayoutAlign="start center">
            <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent"
                matTooltip="Add new record" (click)="openSearchDialog()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Assets
            </button>

            <button type="button" *ngIf="selection.selected.length && canEdit()" mat-raised-button
                [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item
                    (click)="onDelete()"><mat-icon>delete</mat-icon> Delete</button>
                <button type="button" mat-menu-item>{{selection.selected.length}} Selected</button>
            </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-label>{{searchPlaceholder}}</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="searchQuery" type="text" placeholder="Search" (keyup.enter)="onSearch()"
                    (input)="onSearch()" />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchQuery"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div fxFlex="auto" fxLayoutAlign="end center">
            <mat-button-toggle-group #group="matButtonToggleGroup" value="{{defaultViewMode}}"
                (change)="toggleView($event.value)" aria-label="Select view mode">
                <mat-button-toggle value="{{ViewMode.Card}}" matTooltip="Card view" aria-label="Card view">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="{{ViewMode.Table}}" matTooltip="Table view" aria-label="Table view">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </header>

    <div fxLayout="column" class="search-content">
        <div *ngIf="viewModel.assets.length > 0; else noSearchResults">
            <div class="grid" *ngIf="defaultViewMode == ViewMode.Card; else defaultView">
                <mat-card appearance="outlined" *ngFor="let asset of viewModel.assets">
                    <div fxLayout="row" fxLayoutGap="16px">
                        <!-- Image Section -->
                        <div fxFlex="40" fxLayoutAlign="center center" class="image-container">
                            <img mat-card-md-image [src]="utilityService.getFilePreviewUrl(asset, PreviewMode.Medium)"
                                alt="Asset Image">
                            <mat-checkbox class="corner-checkbox" appAuthorizeControl="edit||offermang"
                                (click)="$event.stopPropagation()" fxFlex="30px"
                                (change)="$event ? selection.toggle(asset) : null"
                                [checked]="selection.isSelected(asset)">
                            </mat-checkbox>
                        </div>

                        <!-- Content Section -->
                        <div fxFlex="60">
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <span *ngIf="asset?.Rank" class="offer-rank">
                                        Rank: {{asset?.Rank}}</span>
                                </div>
                                <strong *ngIf="asset?.Detail?.AssetName"
                                    class="offer-headline ellipsis-3-line">{{asset?.Detail?.AssetName }}</strong>
                                <div *ngIf="asset?.Detail?.DateCreated" class="offer-event-name">
                                    Created: {{asset?.Detail?.DateCreated }}</div>
                                <div *ngIf="asset?.Detail?.DateAdded" class="offer-event-name">
                                    Uploaded: {{asset?.Detail?.DateAdded }}</div>
                                <div *ngIf="asset?.AssetGroupName"> {{ asset?.AssetGroupName}}
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <span class="footer-text">{{ asset?.Detail?.FileType }}</span>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="end end">
                                    <a appAuthorizeControl="edit||offermang" class="footer-link"
                                        (click)="openRankDialog(asset)">Edit Rank</a>
                                </div>
                            </mat-card-content>
                        </div>
                    </div>
                </mat-card>
            </div>

            <ng-template #defaultView>
                <table mat-table [dataSource]="viewModel.assets" matSort (matSortChange)="onSort($event)"
                    matSortDisableClear [matSortStart]="'asc'">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox appAuthorizeControl="edit||offermang" [checked]="isAllSelectedOnPage()"
                                [indeterminate]="isSomeSelectedOnPage() && !isAllSelectedOnPage()"
                                (change)="toggleSelectAll($event.checked)" [disabled]="viewModel.assets.length === 0">
                            </mat-checkbox>
                        </th>
                        <td class="no-ellipsis" mat-cell *matCellDef="let row">
                            <mat-checkbox appAuthorizeControl="edit||offermang" (click)="$event.stopPropagation()"
                                (change)="toggleSelect($event.checked, row)" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!--  Image Column -->
                    <ng-container matColumnDef="Image">
                        <th style="width:8%" mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                            Preview
                        </th>
                        <td mat-cell class="offer-image" *matCellDef="let record">
                            <img mat-card-md-image
                                [src]="utilityService.getFilePreviewUrl(record, PreviewMode.Thumbnail)" alt="Image">
                        </td>
                    </ng-container>

                    <!-- AssetName Column -->
                    <ng-container matColumnDef="AssetName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
                            [resizeColumn]="true">
                            Asset Name
                        </th>
                        <td mat-cell *matCellDef="let record"> {{ record.Detail.AssetName }}</td>
                    </ng-container>

                    <!-- FileName Column -->
                    <ng-container matColumnDef="FileName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FileName"
                            [resizeColumn]="true">
                            File Name
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Detail.FileName }}</td>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type"
                            [resizeColumn]="true">
                            Type
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Detail.FileType }}</td>
                    </ng-container>

                    <!-- Group Column -->
                    <ng-container matColumnDef="Group">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Group"
                            [resizeColumn]="true">
                            Group
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.AssetGroupName }}</td>
                    </ng-container>

                    <!-- ClientKey Column -->
                    <ng-container matColumnDef="ClientKey">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey"
                            [resizeColumn]="true">
                            Client Key
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
                    </ng-container>

                    <!-- DateAdded Column -->
                    <ng-container matColumnDef="DateAdded">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DateAdded"
                            [resizeColumn]="true">
                            Date Uploaded
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Detail.DateAdded }}</td>
                    </ng-container>

                    <!-- DateCreated Column -->
                    <ng-container matColumnDef="DateCreated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by DateCreated" [resizeColumn]="true">
                            Date Created
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Detail.DateCreated }}</td>
                    </ng-container>

                    <!-- Rank Column -->
                    <ng-container matColumnDef="Rank">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank"
                            [resizeColumn]="true">
                            <span>Rank</span>
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Rank }}</td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                        <th appAuthorizeControl="edit||offermang" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td appAuthorizeControl="edit||offermang" mat-cell *matCellDef="let record">
                            <a (click)="openRankDialog(record)" class="footer-link">Edit Rank</a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </ng-template>
        </div>
        <ng-template #noSearchResults>
            <div class="no-records">
                <h2 aria-live="polite">No records found.</h2>
            </div>
        </ng-template>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="viewModel.assets.length > 0">
        <mat-paginator (page)="onPageChange($event)" [length]="viewModel.totalRecords"
            [pageSize]="viewModel.page.pageSize" [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="pageSizeOptions" [pageIndex]="viewModel.page.pageIndex" aria-label="Select page">
        </mat-paginator>
    </div>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>