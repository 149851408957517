import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  EventComponent,
  EventDetailsComponent,
  EventsComponent,
  OfferComponent,
  OffersComponent,
  VersionComponent,
  VersionDetailsComponent,
  VersionListComponent,
  VersionStoreListComponent,
  DivisionsComponent,
  DivisionComponent,
  OfferDetailsComponent,
  OfferVersionsComponent,
  OfferProductsComponent,
  OfferAssetsComponent,
  StoresComponent,
  StoreComponent,
  OfferTagsComponent,
  OfferTagComponent,
  EventTypesComponent,
  EventTypeComponent,
  ProductBrandsComponent,
  ProductBrandComponent,
  ProductCategoriesComponent,
  ProductCategoryComponent,
  ProductsComponent,
  ProductComponent,
  PromoAssetsComponent,
  PromoDetailsComponent,
  PromoVersionsComponent,
  OfferProductUpdateRankComponent,
  AssetsComponent,
  UploadAssetsComponent,
  AssetsEditDetailsComponent,
  ProductTabsComponent,
  ProductAssetListComponent,
  ProductBrandTabsComponent,
  ProductBrandAssetListComponent,
  VendorComponent,
  VendorsComponent,
  EventAssetListComponent,
  VersionAssetListComponent,
  VersionOffersComponent,
  VersionPromosComponent,
  EventWorkflowComponent,
  ShoppersComponent,
  ShopperComponent,
  ShopperDetailsComponent,
  ShoppingStatsComponent,
  UsersComponent,
  UserComponent,
  WorkflowManagementComponent,
  PromoActivityLogComponent,
  OfferGroupComponent,
  OfferGroupEditVersionsComponent,
  OfferGroupOffersComponent,
  OfferGroupDetailsComponent,
  OfferGroupAssetsComponent,
} from '../../../standard/v1';
import { HomeComponent } from '../../../home';
import { Shell } from '../../../shell';
import { CouponManagementComponent } from 'src/app/modules/standard/v1/pages/coupon-management/coupon.management.component';
import { CouponComponent } from 'src/app/modules/standard/v1/pages/coupon/coupon.component';
import { CouponDetailsComponent } from 'src/app/modules/standard/v1/components/coupon-details/coupon.details.component';
import { CouponProductUpdateRankComponent } from 'src/app/modules/standard/v1/components/coupon-product-update-rank/coupon-product-update-rank.component';
import { CouponAssetsComponent } from 'src/app/modules/standard/v1/components/coupon-assets/coupon-assets.component';
import { CouponProductsComponent } from 'src/app/modules/standard/v1/components/coupon-products/coupon.products.component';
import { environment as env } from 'src/environments/environment';
import { OfferActivityLogComponent } from 'src/app/modules/standard/v1/components/offer-activity-log/offer-activity-log.component';
import { BannersComponent } from 'src/app/modules/standard/v1/pages/banners/banners.component';
import { BannerDetailsComponent } from 'src/app/modules/standard/v1/pages/banners/banner-details/banner-details.component';
import { commonRoutes } from 'src/app/@shared/shared.routes';

const routes: Routes = [
  Shell.childRoutes([
    ...commonRoutes,
    {
      path: 'walgreens',
      data: { breadcrumb: 'Dashboard' },
      children: [
        {
          path: '',
          component: HomeComponent,
          data: {
            title: 'Dashboard',
            breadcrumb: `${env.appKey} / Dashboard`,
          },
        },
        {
          path: 'divisions',
          children: [
            {
              path: '',
              component: DivisionsComponent,
              data: { title: 'Divisions', breadcrumb: 'Divisions' },
            },
            {
              path: ':divisionId',
              component: DivisionComponent,
              data: { title: 'Division Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'banners',
          children: [
            {
              path: '',
              component: BannersComponent,
              data: { title: 'Banners', breadcrumb: 'Banners' },
            },
            {
              path: ':bannerId',
              component: BannerDetailsComponent,
              data: { title: 'Banner Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'events',
          children: [
            {
              path: '',
              component: EventsComponent,
              data: { title: 'Events', breadcrumb: 'Events' },
            },
            {
              path: ':eventId',
              children: [
                {
                  path: '',
                  component: EventComponent,
                  data: { title: 'Event Details', breadcrumb: { skip: true } },
                  children: [
                    {
                      path: 'details',
                      component: EventDetailsComponent,
                      data: { title: 'Event Details', breadcrumb: 'Details' },
                    },
                    {
                      path: 'versions',
                      component: VersionListComponent,
                      data: { title: 'Versions', breadcrumb: 'Versions' },
                    },
                    {
                      path: 'event-assets',
                      component: EventAssetListComponent,
                      data: { title: 'Assets', breadcrumb: 'Assets' },
                    },
                    {
                      path: 'workflow',
                      component: EventWorkflowComponent,
                      data: { title: 'Event Workflow', breadcrumb: 'Workflow' },
                    },
                  ],
                },
              ],
            },
            {
              path: ':eventId/offers',
              children: [
                {
                  path: '',
                  component: OffersComponent,
                  data: {
                    title: 'Offers/Promos',
                    breadcrumb: 'Offers & Promotions',
                  },
                },
                {
                  path: 'offergroup',
                  component: OfferGroupComponent,
                  data: {
                    title: 'Offer Group',
                    breadcrumb: 'Offer Group',
                  },
                },
                {
                  path: ':offerId',
                  children: [
                    {
                      path: 'childversions',
                      component: VersionOffersComponent,
                      data: {
                        title: 'Variant Offers',
                        breadcrumb: 'Variant Offers',
                      },
                    },
                    {
                      path: 'promo/childversions',
                      component: VersionPromosComponent,
                      data: {
                        title: 'Variant Promos',
                        breadcrumb: 'Variant Promos',
                      },
                    },
                    {
                      path: 'offergroups',
                      component: OfferComponent,
                      data: { title: 'Offer Groups', breadcrumb: { skip: true } },
                      children: [
                        {
                          path: 'assets',
                          component: OfferGroupAssetsComponent,
                          data: {
                            title: 'Offer Group Assets',
                            breadcrumb: 'Offer Group Assets',
                          },
                        },
                        {
                          path: 'offergrpdetails',
                          component: OfferGroupDetailsComponent,
                          data: {
                            title: 'Offer Group Details',
                            breadcrumb: ' Offer Group Details',
                          },
                        },
                        {
                          path: 'offers',
                          component: OfferGroupOffersComponent,
                          data: {
                            title: 'Offer Group Offers',
                            breadcrumb: ' Offer Group Offers',
                          },
                        },
                        {
                          path: 'versions',
                          component: OfferGroupEditVersionsComponent,
                          data: {
                            title: 'Offer Group Versions',
                            breadcrumb: 'Offer Group Versions',
                          },
                        },

                      ],
                    },
                    {
                      path: 'promos',
                      component: OfferComponent,
                      data: { title: 'Promos', breadcrumb: { skip: true } },
                      children: [
                        {
                          path: 'assets',
                          component: PromoAssetsComponent,
                          data: {
                            title: 'Promo Assets',
                            breadcrumb: 'Promo Assets',
                          },
                        },
                        {
                          path: 'promodetails',
                          component: PromoDetailsComponent,
                          data: {
                            title: 'Promo Details',
                            breadcrumb: ' Promo Details',
                          },
                        },
                        {
                          path: 'versions',
                          component: PromoVersionsComponent,
                          data: {
                            title: 'Promo Versions',
                            breadcrumb: 'Promo Versions',
                          },
                        },
                        {
                          path: 'activitylog',
                          component: PromoActivityLogComponent,
                          data: {
                            title: 'Promo ActivityLog',
                            breadcrumb: 'Activity Log',
                          },
                        },
                      ],
                    },
                    {
                      path: '',
                      component: OfferComponent,
                      data: { title: 'Offers', breadcrumb: { skip: true } },
                      children: [
                        {
                          path: 'assets',
                          component: OfferAssetsComponent,
                          data: { title: 'Offer Assets', breadcrumb: 'Assets' },
                        },
                        {
                          path: 'activitylog',
                          component: OfferActivityLogComponent,
                          data: {
                            title: 'Offer ActivityLog',
                            breadcrumb: 'Activity Log',
                          },
                        },
                        {
                          path: 'details',
                          component: OfferDetailsComponent,
                          data: {
                            title: 'Offer Details',
                            breadcrumb: 'Details',
                          },
                        },
                        {
                          path: 'products',
                          component: OfferProductsComponent,
                          data: {
                            title: 'Offer Products',
                            breadcrumb: 'Products',
                          },
                        },
                        {
                          path: 'versions',
                          component: OfferVersionsComponent,
                          data: {
                            title: 'Offer Versions',
                            breadcrumb: 'Versions',
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: ':offerId/products',
                  data: { title: 'Products', breadcrumb: 'Product' },
                  children: [
                    {
                      path: ':productId',
                      component: OfferProductUpdateRankComponent,
                      data: {
                        title: 'Product Rank Details',
                        breadcrumb: 'Rank',
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: ':eventId/versions/:versionId',
              children: [
                {
                  path: '',
                  component: VersionComponent,
                  data: {
                    title: 'Version Details',
                    breadcrumb: { skip: true },
                  },
                  children: [
                    {
                      path: 'details',
                      component: VersionDetailsComponent,
                      data: { title: 'Version Details', breadcrumb: 'Version' },
                    },
                    {
                      path: 'stores',
                      component: VersionStoreListComponent,
                      data: { title: 'Version Stores', breadcrumb: 'Stores' },
                    },
                    {
                      path: 'version-assets',
                      component: VersionAssetListComponent,
                      data: { title: 'Assets', breadcrumb: 'Assets' },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'eventtypes',
          children: [
            {
              path: '',
              component: EventTypesComponent,
              data: { title: 'Event Types', breadcrumb: 'Event Types' },
            },
            {
              path: ':eventtypeId',
              component: EventTypeComponent,
              data: { title: 'Event Type Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'offertags',
          children: [
            {
              path: '',
              component: OfferTagsComponent,
              data: { title: 'Offer Tags', breadcrumb: 'Offer Tags' },
            },
            {
              path: ':offertagId',
              component: OfferTagComponent,
              data: { title: 'Offer Tag Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'assets',
          children: [
            {
              path: '',
              component: AssetsComponent,
              data: { title: 'Assets', breadcrumb: 'Asset Management' },
            },
            {
              path: 'upload-assets',
              component: UploadAssetsComponent,
              data: { title: 'Upload Assets', breadcrumb: 'Upload Assets' },
            },
            {
              path: ':assetId',
              component: AssetsEditDetailsComponent,
              data: { title: 'Edit Asset', breadcrumb: 'Edit Asset' },
            },
          ],
        },
        {
          path: 'stores',
          children: [
            {
              path: '',
              component: StoresComponent,
              data: { title: 'Stores', breadcrumb: 'Stores' },
            },
            {
              path: ':storeId',
              component: StoreComponent,
              data: { title: 'Store Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'products',
          children: [
            {
              path: '',
              component: ProductsComponent,
              data: { title: 'Products', breadcrumb: 'Products' },
            },
            // {
            //   path: ':productId',
            //   component: ProductComponent,
            //   data: { title: 'Product Details', breadcrumb: 'Details' },
            // }
            {
              path: ':productId',
              children: [
                {
                  path: '',
                  component: ProductTabsComponent,
                  data: {
                    title: 'Product Details',
                    breadcrumb: { skip: true },
                  },
                  children: [
                    {
                      path: 'details',
                      component: ProductComponent,
                      data: { title: 'Product Details', breadcrumb: 'Details' },
                    },
                    {
                      path: 'product-assets',
                      component: ProductAssetListComponent,
                      data: { title: 'Assets', breadcrumb: 'Assets' },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'product-categories',
          children: [
            {
              path: '',
              component: ProductCategoriesComponent,
              data: {
                title: 'Product Categories',
                breadcrumb: 'Product Categories',
              },
            },
            {
              path: ':categoryId',
              component: ProductCategoryComponent,
              data: {
                title: 'Product Category Details',
                breadcrumb: 'Details',
              },
            },
            {
              path: ':categoryId/:parentCategoryId',
              component: ProductCategoryComponent,
              data: {
                title: 'Product Category Details',
                breadcrumb: 'Details',
              },
            },
          ],
        },
        {
          path: 'vendors',
          children: [
            {
              path: '',
              component: VendorsComponent,
              data: { title: 'Vendors', breadcrumb: 'Vendors' },
            },
            {
              path: ':vendorId',
              component: VendorComponent,
              data: { title: 'Vendor Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'product-brands',
          children: [
            {
              path: '',
              component: ProductBrandsComponent,
              data: { title: 'Product Brands', breadcrumb: 'Product Brands' },
            },
            {
              path: ':brandId',
              children: [
                {
                  path: '',
                  component: ProductBrandTabsComponent,
                  data: {
                    title: 'Product Brand Details',
                    breadcrumb: { skip: true },
                  },
                  children: [
                    {
                      path: 'details',
                      component: ProductBrandComponent,
                      data: {
                        title: 'Product Brand Details',
                        breadcrumb: 'Details',
                      },
                    },
                    {
                      path: 'product-brand-assets',
                      component: ProductBrandAssetListComponent,
                      data: { title: 'Assets', breadcrumb: 'Assets' },
                    },
                  ],
                },
              ],
            },
            {
              path: ':brandId/:parentProductBrandId',
              data: {
                title: 'Product Brand Details',
                breadcrumb: { skip: true },
              },
              children: [
                {
                  path: 'details',
                  component: ProductBrandComponent,
                  data: {
                    title: 'Product Brand Details',
                    breadcrumb: 'Add Product Brand',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'coupons',
          children: [
            {
              path: '',
              component: CouponManagementComponent,
            },
            {
              path: ':couponId',
              children: [
                {
                  path: '',
                  component: CouponComponent,
                  children: [
                    {
                      path: 'details',
                      component: CouponDetailsComponent,
                      data: { title: 'Coupon Details', breadcrumb: 'Details' },
                    },
                    {
                      path: 'products',
                      component: CouponProductsComponent,
                      data: { title: 'Coupon Products', breadcrumb: 'Product' },
                    },
                    {
                      path: 'assets',
                      component: CouponAssetsComponent,
                      data: { title: 'Offer Assets', breadcrumb: 'Assets' },
                    },
                  ],
                },
              ],
            },
            {
              path: ':couponId/products',
              data: { title: 'Products', breadcrumb: 'Product' },
              children: [
                {
                  path: ':productId',
                  component: CouponProductUpdateRankComponent,
                  data: { title: 'Product Rank Details', breadcrumb: 'Rank' },
                },
              ],
            },
            {
              path: ':couponId/assets',
              data: { title: 'Assets', breadcrumb: 'Asset' },
              children: [],
            },
          ],
        },
        {
          path: 'shoppers',
          children: [
            {
              path: '',
              component: ShoppersComponent,
              data: { title: 'Shoppers', breadcrumb: 'Shoppers' },
            },
            {
              path: ':shopperId',
              children: [
                {
                  path: '',
                  component: ShopperComponent,
                  data: { title: 'Shoppers', breadcrumb: { skip: true } },
                  children: [
                    {
                      path: 'details',
                      component: ShopperDetailsComponent,
                      data: { title: 'Details', breadcrumb: 'Details' },
                    },
                    {
                      path: 'shoppingStats',
                      component: ShoppingStatsComponent,
                      data: {
                        title: 'Shopping Stats',
                        breadcrumb: 'Shopping Stats',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'user-management',
          children: [
            {
              path: '',
              component: UsersComponent,
              data: { title: 'Users', breadcrumb: 'Users' },
            },
            {
              path: ':userId',
              component: UserComponent,
              data: { title: 'User Details', breadcrumb: 'Details' },
            },
          ],
        },
        {
          path: 'workflow-management',
          children: [
            {
              path: '',
              component: WorkflowManagementComponent,
              data: {
                title: 'Workflow Management',
                breadcrumb: 'Workflow Management',
              },
              pathMatch: 'full',
            },
            {
              path: ':workflowId',
              component: WorkflowManagementComponent,
              data: {
                title: 'Workflow Management',
                breadcrumb: 'Details',
              },
            },
          ],
        },
        // {
        //   path: 'digitalads',
        //   children: [
        //     {
        //       path: '',
        //       component: DigitalAdsComponent,
        //       data: { title: 'Digital Ads', breadcrumb: 'Digital Ads' },
        //     },
        //     {
        //       path: ':digitaladId',
        //       children: [
        //         {
        //           path: '',
        //           component: DigitalAdComponent,
        //           data: { title: 'Digital Ad Details', breadcrumb: { skip: true } },
        //           children: [
        //             {
        //               path: 'details',
        //               component: DigitalAdDetailsComponent,
        //               data: { title: 'Digital Ad Details', breadcrumb: 'Details' },
        //             },
        //           ],
        //         },
        //       ]
        //     },
        //     {
        //       path: ':digitaladId/:eventId',
        //       data: { title: 'Digital Ad Details', breadcrumb: { skip: true } },
        //       children: [
        //         {
        //           path: 'edit-ad',
        //           component: DigitalAdEditComponent,
        //           data: { title: 'Edit Digital Ad', breadcrumb: 'Edit Ad' },
        //           canDeactivate: [CanDeactivateGuard]
        //         },
        //       ]
        //     }
        //   ]
        // },
      ],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class WalgreensV1RoutingModule {}
