import { Component, ViewChild } from '@angular/core';
import { CouponService } from '../../services/coupon.service';
import { combineLatest, distinctUntilChanged, map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Coupon } from '../../models/coupon.model';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Filter } from 'src/app/@shared';
import { MatSidenav } from '@angular/material/sidenav';
import { StatusCount } from '../../models/status-count.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CouponsListCardsComponent } from '../../components/coupons-list-cards/coupons.list.cards.component';
import { CouponSortDialogComponent } from '../../components/coupon-sort-dialog/coupon.sort.dialog.component';
import * as dayjs from 'dayjs';
import { Status } from '../../models/offer-promo-status';

// constants
///TODO: move this to a constants pattern
const DEFAULT_DATE_RANGE = {
  startDate: '',
  endDate: '',
};
@Component({
  selector: 'app-coupon-management',
  templateUrl: 'coupon.management.component.html',
  styleUrls: ['coupon.management.component.scss'],
})
export class CouponManagementComponent<T extends Coupon> {
  viewModel$ = combineLatest([
    this.couponService.isLoading$,
    this.couponService.couponList$,
    this.couponService.filters$,
    this.couponService.viewMode$,
    this.couponService.statusFilter$
  ]).pipe(
    map(([isLoading, coupons, filters, viewMode, statusFilter]) => {
      return { isLoading, coupons, filters, viewMode, statusFilter };
    })
  );
  @ViewChild('couponListCards', { static: false }) couponListCards: any;
  @ViewChild('couponListTable', { static: false }) couponListTable: any;
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  public statusCount: StatusCount = {};

  filtersForm = new FormGroup({
    Search: new FormControl(),
    start: new FormControl(new Date(DEFAULT_DATE_RANGE.startDate)),
    Expiry: new FormControl(new Date(DEFAULT_DATE_RANGE.endDate)),
    Status: new FormControl<string>('')
  });
  selection = new SelectionModel<Coupon>(true, []);
  noneValue = null;
  constructor(
    private couponService: CouponService<Coupon>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {   
  }

  ngOnInit() {
    const filters = this.filtersForm.value;
    delete filters.Search;
    this.couponService.reload();
    this.couponService.statusUpdated$.subscribe(res => {
      this.getStatusCount();
    })
  }

  getStatusCount() {
    this.couponService.getStatusCount().subscribe(res => {
      this.statusCount = res;
    })
  }

  onSearch(event: any) {
    this.couponService.search(event.target.value);
  }

  clearSearch() {
    this.couponService.search('');
    this.filtersForm.controls.Search.setValue('');
  }

  clearFilters() {
    this.filtersForm.reset();
    this.couponService.clearFilters();
  }

  removeFilter(filter: Filter) {
    this.couponService.removeFilter(filter);
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  resetFilters() {
    this.filtersForm.reset();
    this.couponService.search('');
    this.couponService.clearFilters();
    this.sidenav.toggle();
  }

  onDateRangePickerOpen() {
    this.filtersForm.controls.start.reset();
    this.filtersForm.controls.Expiry.reset();
  }

  onDateRangeChange() {
    let startDate = dayjs(this.filtersForm.controls.start.value).format();
    let endDate = dayjs(this.filtersForm.controls.Expiry.value).format();
    let isbefore = dayjs(startDate).isBefore(endDate);
    let isSame = dayjs(startDate).isSame(endDate);

    if (startDate === 'Invalid Date' && endDate === 'Invalid Date') {
      this.couponService.dateRange('', '');
      return;
    }

    if (startDate === 'Invalid Date') {
      this.couponService.dateRange('', endDate);
    } else if (endDate === 'Invalid Date') {
      this.couponService.dateRange(startDate, '');
    } else if (this.filtersForm.valid) {
      this.couponService.dateRange(startDate, endDate);
    }
  }

  onFilterChange(event: any) {
    switch(event) {
      case 'start': 
        let startDate = dayjs(this.filtersForm.controls.start.value).format();
        this.couponService.removeFilterByFieldName('start');
        const startDateFilter: Filter = {
          displayText: dayjs(this.filtersForm.controls.start.value).format('MM/DD/YYYY'),
          fieldName: 'start',
          value: startDate
        }
        this.couponService.addFilters([startDateFilter]);
        break;
      case 'end': 
        let endDate = dayjs(this.filtersForm.controls.Expiry.value).format();
        this.couponService.removeFilterByFieldName('end');
        const endDateFilter: Filter = {
          displayText: dayjs(this.filtersForm.controls.Expiry.value).format('MM/DD/YYYY'),
          fieldName: 'end',
          value: endDate
        }
        this.couponService.addFilters([endDateFilter]);
        break;
    }
  }

  deleteSelectedRecords() {
    let selectedRecordsDetails: any;
    if (this.couponListCards) {
      selectedRecordsDetails = this.couponListCards.getSelectedSectionRecords();
    } else if (this.couponListTable) {
      selectedRecordsDetails = this.couponListTable.getSelectedSectionRecords();
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove selected Offer/Promo?`,
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (confirmResult) {
          const couponIds = selectedRecordsDetails.map((record: Coupon) => record.Detail.Id);
          this.couponService.deleteBulkCoupons(couponIds).subscribe({
            next: () => {
              this.matSnackBar.open(
                `Coupon(s) deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.couponService.reload();
              this.couponListCards.selection.clear();
              this.couponListTable.selection.clear();
            },
            error: (error) => {
              this.matSnackBar.open(
                `Failed to delete coupons`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.couponListCards.selection.clear();
              this.couponListTable.selection.clear();
            }
          })
        }
      });
    }
  }

  onSort() {
    this.dialog.open(CouponSortDialogComponent)
  }

  isSelected(viewModel: any, status: string) {
    return (
      viewModel.filters &&
      viewModel.filters.length > 0 &&
      viewModel.filters.find((x: any) => x.displayText === status)
    );
  }

  updateBulkStatus(status: string) {
    let selectedRecordsDetails: any;
    if (this.couponListCards) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.couponListCards.getSelectedSectionRecords()));
    } else if(this.couponListTable) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.couponListTable.getSelectedSectionRecords()));
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let coupons: any = selectedRecordsDetails.map((coupon: any) => coupon.Detail.Id);

      this.couponService.updateStatus(status, coupons).subscribe({
        next: () => {
          this.couponService.reload();
          if (this.couponListCards) {
            this.couponListCards.clearSelection();
          } else if(this.couponListTable) {
            this.couponListTable.clearSelection();
          }
          this.couponService.updateRecordStatus(new Date());
        }
      })
    }
  }

  toggleView(mode: string) {
    this.couponService.toggleViewMode(mode);
  }

  toggleStatusFilter(status: string) {
    this.filtersForm.controls.Status.setValue(status);
    this.couponService.removeFilterByFieldName('status');
      let filter = {
        displayText: status,
        fieldName: 'status',
        value: status
      };
    this.couponService.addFilters([filter]);
  }
}
