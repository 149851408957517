<ng-container *ngIf="viewModel$ | async as viewModel; else loading">

    <div class="grid" *ngIf="viewModel.products.length">
        <mat-card appearance="outlined" *ngFor="let products of viewModel.products">
            <mat-card-content>
                <div class="grid-col-span-2 offer-name" fxLayout="row wrap">
                    <mat-checkbox class="card-checkbox" appAuthorizeControl="edit||offermang" (click)="$event.stopPropagation()" fxFlex="30px"
                        (change)="$event ? selection.toggle(products) : null"
                        [checked]="selection.isSelected(products)">
                    </mat-checkbox>
                    <div class="ellipsis-3-line" fxFlex>{{ products.Detail.ProductName }}</div>
                    <span class="rank" *ngIf="products.Rank">Rank : {{ products.Rank}}</span>
                </div>
                <div fxLayoutAlign="center center" class="offer-image">
                    <!-- <img mat-card-md-image *ngIf="products.FileName" src="{{viewModel.assetsUrl}}/{{products.FileName}}" class="product-image" alt="offer image">
                    <img mat-card-md-image *ngIf="!products.FileName" src="../.././assets/images/default-image.png" class="product-image" alt="offer image"> -->
                    <ng-container *ngIf="products.FacingAsset">
                        <img mat-card-md-image [src]="utilityService.getOfferPreviewUrl(products.FacingAsset, PreviewMode.Medium)" alt="Offer image" (error)="onImageError($event)">
                      </ng-container>
                      <img mat-card-md-image *ngIf="!products.FacingAsset" src="./assets/images/default-image.png"
                        alt="Offer image" />
                </div>
                <div class="offer-content" fxLayout="column">
                    <div class="offer-headline" *ngIf="products.ProductCategoryName">
                        <span class="ellipsis-3-line">{{ products.ProductCategoryName}}</span>
                    </div>
                    <div *ngIf="products.ProductBrandName">
                        <span class="ellipsis-3-line"> {{ products.ProductBrandName }}</span>
                    </div>
                    <div *ngIf="products.Detail.Id">
                        <span class="ellipsis-3-line"> {{ products.Detail.Id }}</span>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end end">
                        <a class="footer-text" [routerLink]="[products.Detail.Id]">
                            <span *ngIf="isAdmin()">Edit Rank</span>
                            <span *ngIf="!isAdmin()">View Details</span>
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.products.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>
    <table *ngIf="!viewModel.products.length">
        <tr>
            <td class="no-records">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
