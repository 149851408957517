import { Injectable } from '@angular/core';
import { Asset, AssetDetail, SearchAsset } from 'src/app/modules/standard/v1';

export enum PreviewMode {
  Thumbnail = 'thumbnail',
  Medium = 'medium',
  Full = 'full'
}

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() { }

  private defaultImageUrl = './assets/images/default-image.png';
  private fileTypeMap: { [key: string]: string } = {
    'application/pdf': './assets/images/pdf.png',
    'audio': './assets/images/mp3.jpg',
    'video': './assets/images/mp4.png',
    'text': './assets/images/text.png',
  };

  private formatUrl(webUrl: string, mode: PreviewMode): string {
    if (!webUrl) {
      return this.defaultImageUrl;
    }

    const hasQueryParams = webUrl.includes('?');
    const queryParams = `${hasQueryParams ? '&impolicy=thumb' : '?impolicy=thumb'}${mode === PreviewMode.Thumbnail ? '&imwidth=120' : '&imwidth=240'}`;
    return `${webUrl}${queryParams}`;
  }

  private resolveFileType(fileType: string): string {
    for (const key in this.fileTypeMap) {
      if (fileType.startsWith(key)) {
        return this.fileTypeMap[key];
      }
    }
    return this.defaultImageUrl;
  }

  private getPreviewUrl(asset: { fileType: string; webUrl?: string }, mode: PreviewMode): string {
    if (!asset.fileType) {
      return this.defaultImageUrl;
    }

    if (asset.fileType.startsWith('image/')) {
      return this.formatUrl(asset.webUrl ?? '', mode);
    }

    return this.resolveFileType(asset.fileType);
  }

  public getFilePreviewUrl(asset: Asset, mode: PreviewMode = PreviewMode.Thumbnail): string {
    let currentMode = asset.Detail.FileType;
    if (asset.Detail.FileType?.includes('x-zip-compressed')) {
      currentMode = asset.Detail.ZipType == 'Video' ? 'video' : '';
    }
    return this.getPreviewUrl({ fileType: currentMode ?? '', webUrl: asset.Detail.WebUrl }, mode);
  }

  public getOfferPreviewUrl(facingAsset: AssetDetail, mode: PreviewMode = PreviewMode.Thumbnail): string {
    return this.getPreviewUrl({ fileType: facingAsset.FileType ?? '', webUrl: facingAsset.WebUrl }, mode);
  }

  public getSearchFilePreviewUrl(asset: SearchAsset, mode: PreviewMode = PreviewMode.Thumbnail): string {
    return this.getPreviewUrl({ fileType: asset.fileType, webUrl: asset.webUrl }, mode);
  }
}

