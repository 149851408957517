import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest, filter, map } from 'rxjs';

import { AuthorizeControlService } from 'pr1-ui-components';
import {
  ConfirmDialogComponent,
  DEFAULT_SNACKBAR_CONFIG,
  Logger,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { Filter } from 'src/app/@shared/models/filter.model';
import {
  CloneEventComponent,
  EditOfferTagsComponent,
  Event,
  EventService,
  OfferPromoSortDialogComponent,
  OfferTag,
  OfferTagService,
  Version,
  VersionListComponent,
  VersionService,
} from '../..';
import { OfferPromo } from 'src/app/modules/standard/v1';
import { PromoDomain } from 'src/app/modules/standard/v1';
import { OfferPromoService } from 'src/app/modules/standard/v1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '../../models/offer-promo-status';
import { StatusCount } from '../../models/status-count.model';
import * as dayjs from 'dayjs';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { CloneComponent } from '../../pages/clone/clone.component';
import { Sort } from '@angular/material/sort';

const log = new Logger('OfferGroupOffersComponent');

@Component({
  selector: 'app-offer-group-offers',
  templateUrl: './offer-group-offers.component.html',
  styleUrls: ['./offer-group-offers.component.scss'],
})

export class OfferGroupOffersComponent< 
  TEvent extends Event,
  Tag extends OfferTag,
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy
{ 

  public statusCount: StatusCount = {};
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('offerPromoTable', { static: false }) offerPromoTable: any;
  @ViewChild('offerPromoCards', { static: false }) offerPromoCards: any;
  offerTags$ = this.offerTagService.getByEventId(
    this.route.snapshot.params['eventId'] || this.offerpromoService.eventId
  );
  
  viewModel$ = combineLatest([
    this.offerpromoService.isLoading$,
    this.offerpromoService.offerGrpviewMode$,
    this.offerpromoService.eventId$,
    this.versionService.allVersions$,
    this.offerTags$,
  ]).pipe(
    map(
      ([isLoading, viewMode, eventId, versions, offerTags]) => {
        return {
          isLoading,
          viewMode,
          versions,
          eventId,
          offerTags,
        };
      }
    )
  );
  filtersForm = new FormGroup({
    search: new FormControl(),
    versions: new FormControl(),
    offerTags: new FormControl(),
    offerPromos: new FormControl(),
    offerStatus: new FormControl(),
    noTags: new FormControl<boolean>(false),
    withComments: new FormControl<boolean>(false),
  });
  
  selectedTag = '';
  applicableWorkflowPermissions: any = null;
  awDetails: any = null;
  showStageApproval: boolean = false;
  activeStage: any = null;
  stageCompleted = false;
  stageToMarkActive: any = null;
  readOnlyMode = false;
  roles = {
    reviewer: 0,
    approver: 1,
    editor: 2,
  };

  subscription$ = new Subscription();
  userHasAccess: boolean = false;
  selectedVal = '';
  loggedInUsersRole: any;
  gotPermissions: boolean = false;
  permissions: any;
  inProgressWorkflowIndex: any;

  constructor(
    private eventService: EventService<TEvent>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private versionService: VersionService<Version>,
    private offerTagService: OfferTagService<Tag>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private ewService: EventWorkflowService,
    private orgService: OrganizationService,
    private notificationService: NotificationService,
    private authorizationService: AuthorizeControlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getWorkflowPermissions();
    this.processRequiredInitialSetup();
    this.manuallyInvokePermissionsForCurrentWorkflow();
    this.offerpromoService.offerId = this.route.snapshot.params['offerId'];
    // this.route.queryParams.subscribe((params) => {
    //   if (params['offer_filters'] && params['offer_filters'] == 'true') {
    //     let filterSelections = this.offerpromoService.getFilterSelections();
    //     if (filterSelections) {
    //       this.filtersForm.patchValue(filterSelections);
    //     }
    //   } else {
    //     this.clearFilters();
    //   }
    // });

    // this.offerpromoService.sort(this.offerSorting[0].id);
  }

  manuallyInvokePermissionsForCurrentWorkflow() {
    if (this.ewService.getSelectedEventId()?.trim().length == 0) {
      this.ewService.setSelectedEventId(
        this.route.snapshot.params['eventId']?.trim()
      );
    }
  }

  // navigateOfferGroup() {
  //   this.router.navigate(['offergroup'], { relativeTo: this.route });
  // }

  isSelected(viewModel: any, status: string) {
    return (
      viewModel.filters &&
      viewModel.filters.length > 0 &&
      viewModel.filters.find((x: any) => x.displayText === status)
    );
  }

 

  processRequiredInitialSetup() {
    this.offerpromoService.toggleOfferGrpViewMode('CARDS');
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.versionService.eventId = this.route.snapshot.params['eventId'];
    this.offerpromoService.offerGrpPage({ pageIndex: 0, pageSize: 50 });
    this.clearSearch();
  }
  getWorkflowPermissions() {

    this.readOnlyMode = !this.authorizationService.checkAccess('edit||offermang');

    this.subscription$.add(
      this.ewService.permissionForCurrentWorkflow$.subscribe(
        (permissions: any) => {
          this.permissions = permissions;
          // If no permissions found, restrict access
          if (!permissions || permissions.length == 0) {
            this.showStageApproval = false;
            return;
          }

          const inProgressWorkflowIndex = permissions.findIndex(
            (p: any) => p.Status == 'in_progress'
          );
          this.inProgressWorkflowIndex = inProgressWorkflowIndex;

          // If an active workflow exists
          if (inProgressWorkflowIndex > -1) {
            this.applicableWorkflowPermissions =
              permissions[inProgressWorkflowIndex];

            this.getWorkflowDetails(
              this.applicableWorkflowPermissions['WorkflowId']
            );
            const stages = this.applicableWorkflowPermissions?.Stages;
            console.log(`stages: `, stages);
            const activeStageIndex = stages.findIndex(
              (s: any) => s.Status == 'active' || s.Status?.trim() == ''
            );

            // Check if active stage index
            if (activeStageIndex > -1) {
              this.activeStage =
                this.applicableWorkflowPermissions?.Stages[activeStageIndex];

              // Check if logged-in user has permission to access this event
              const userFoundIndex = this.activeStage?.Users?.findIndex(
                (u: any) =>
                  u.Email?.toLowerCase()?.trim() ==
                  this.orgService?.loggedInUser?.email?.toLowerCase().trim()
              );

              let user: any = null;
              let userCanOnlyView = true;

              if (userFoundIndex > -1) {
                // User has access to event in current workflow
                this.userHasAccess = true;

                user = this.activeStage?.Users[userFoundIndex];
                this.loggedInUsersRole = user.Role;
                if (user.Role == this.roles.reviewer) {
                  // User is reviewer & can only view the event
                  userCanOnlyView = true;
                } else {
                  // User is approver & can edit the event
                  userCanOnlyView = false;
                }
              } else {
                // User DOES NOT have access to event in current workflow
                // Allow only read only access
                userCanOnlyView = true;
                this.subscription$.unsubscribe();
              }

              // User is reviewer & hence has only read only access 
              this.readOnlyMode = userCanOnlyView

              // If user is permitted, continue with stage approval
              if (
                activeStageIndex <
                this.applicableWorkflowPermissions?.Stages?.length - 1
              ) {
                this.stageToMarkActive =
                  this.applicableWorkflowPermissions?.Stages[
                    activeStageIndex + 1
                  ];
              }

              this.showStageApproval = true;

              // Get permissions call doesnt have enough data, hence call get details
              this.getWorkflowDetails(
                this.applicableWorkflowPermissions['WorkflowId']
              );
            }
            // If there is NO Active STAGE - go into default mode
            else {
              this.showStageApproval = false;
            }
          }
          // If no workflow is active, just show events in default mode
          else {
            this.showStageApproval = false; // Stage sign off does not exist in default mode
          }
          this.gotPermissions = true;
        }
      )
    );
  }

  getWorkflowDetails(workflowId: string) {
    this.subscription$.add(
      this.ewService
        .getWorkflowDetailsForFeature(
          workflowId,
          this.orgService.silentAuthToken
        )
        .subscribe((details: any) => {
          this.awDetails = details;
        })
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CloneComponent, {
      width: '80%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId'] },
    });
  }

  

  onSearch(event: any) {
    this.offerpromoService.offerGrpSearch(event.target.value);
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.offerGrpSort(sortState);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerpromoService.offerGrpSearch('');
  }

  toggleView(mode: string) {
    this.offerpromoService.toggleOfferGrpViewMode(mode);
  }

  compareWithIdFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  };

  compareWithValFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  // deleteSelectedRecords() {
  //   let selectedRecordsDetails: any;
  //   if (this.offerPromoTable) {
  //     selectedRecordsDetails = this.offerPromoTable.getSelectedSectionRecords();
  //   } else if (this.offerPromoCards) {
  //     selectedRecordsDetails = this.offerPromoCards.getSelectedSectionRecords();
  //   }
  //   if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
  //     const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
  //       data: {
  //         title: 'Confirm Delete',
  //         message: `Do you want to remove selected Offer/Promo?`,
  //       },
  //       disableClose: true,
  //     });
  //     confirmDialog.afterClosed().subscribe((confirmResult) => {
  //       if (confirmResult) {
  //         let bulkDelete: any = [];
  //         for (
  //           let index = 0;
  //           index <= selectedRecordsDetails.length - 1;
  //           index++
  //         ) {
  //           const activeVersion = selectedRecordsDetails[
  //             index
  //           ].OfferVariants.find((x: any) => x.activeItem == true);
  //           if (
  //             !activeVersion ||
  //             (activeVersion &&
  //               activeVersion.Id === selectedRecordsDetails[index].DomainId)
  //           ) {
  //             const recordstoupdate = selectedRecordsDetails[
  //               index
  //             ].OfferVariants.map((x: any) => {
  //               return {
  //                 Id: x.Id,
  //                 ClientKey: x.ClientKey,
  //                 UserId: x.UserId,
  //                 Name: x.Name,
  //                 Headline: x.Headline,
  //                 BodyCopy: x.BodyCopy,
  //                 Disclaimer: x.Disclaimer,
  //                 ClientComment: x.ClientComment,
  //                 StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
  //                 EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
  //                 Rank: x.Rank,
  //                 TargetURL: x.TargetURL,
  //                 Status: x.Status,
  //                 Version: x.Version,
  //                 Label: x.Label,
  //               };
  //             });
  //             bulkDelete = [...bulkDelete, ...recordstoupdate];
  //           } else if (
  //             activeVersion &&
  //             activeVersion.Id != selectedRecordsDetails[index].DomainId
  //           ) {
  //             const x = activeVersion;
  //             bulkDelete.push({
  //               Id: x.Id,
  //               ClientKey: x.ClientKey,
  //               UserId: x.UserId,
  //               Name: x.Name,
  //               Headline: x.Headline,
  //               BodyCopy: x.BodyCopy,
  //               Disclaimer: x.Disclaimer,
  //               ClientComment: x.ClientComment,
  //               StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
  //               EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
  //               Rank: x.Rank,
  //               TargetURL: x.TargetURL,
  //               Status: x.Status,
  //               Version: x.Version,
  //               Label: x.Label,
  //             });
  //           }
  //         }
  //         this.offerpromoService.deleteOfferPromos(bulkDelete).subscribe({
  //           next: () => {
  //             this.matSnackBar.open(
  //               `Offer/Promo(s) deleted`,
  //               'OK',
  //               DEFAULT_SNACKBAR_CONFIG
  //             );
  //             this.offerpromoService.reload();
  //             if (this.offerPromoTable) {
  //               this.offerPromoTable.clearSelection();
  //             } else if (this.offerPromoCards) {
  //               this.offerPromoCards.clearSelection();
  //             }
  //           },
  //           error: (error) => {
  //             log.error('Error in deleting Offer/Promo', error);

  //             if (error.error.value) {
  //               throw new Error(error.error.value);
  //             } else {
  //               throw new Error(error.message);
  //             }
  //           },
  //         });
  //       }
  //     });
  //   }
  // }

  deleteSelectedRecords(){

  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoTable.getSelectedSectionRecords())
      );
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoCards.getSelectedSectionRecords())
      );
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let statustoUpdate: any = [];
      for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
        const activeVersion = selectedRecordsDetails[index].OfferVariants.find(
          (x: any) => x.activeItem == true
        );
        if (
          !activeVersion ||
          (activeVersion &&
            activeVersion.Id === selectedRecordsDetails[index].DomainId)
        ) {
          const recordstoupdate = selectedRecordsDetails[
            index
          ].OfferVariants.map((x: any) => {
            return {
              Id: x.Id,
              ClientKey: x.ClientKey,
              UserId: x.UserId,
              Name: x.Name,
              Headline: x.Headline,
              BodyCopy: x.BodyCopy,
              Disclaimer: x.Disclaimer,
              ClientComment: x.ClientComment,
              StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
              EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
              Rank: x.Rank,
              TargetURL: x.TargetURL,
              Status: status,
              Version: x.Version,
              Label: x.Label,
            };
          });
          statustoUpdate = [...statustoUpdate, ...recordstoupdate];
        } else if (
          activeVersion &&
          activeVersion.Id != selectedRecordsDetails[index].DomainId
        ) {
          const x = activeVersion;
          statustoUpdate.push({
            Id: x.Id,
            ClientKey: x.ClientKey,
            UserId: x.UserId,
            Name: x.Name,
            Headline: x.Headline,
            BodyCopy: x.BodyCopy,
            Disclaimer: x.Disclaimer,
            ClientComment: x.ClientComment,
            StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
            EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
            Rank: x.Rank,
            TargetURL: x.TargetURL,
            Status: status,
            Version: x.Version,
            Label: x.Label,
          });
        }
      }

      this.offerpromoService.updateStatus(statustoUpdate).subscribe({
        next: () => {
          this.offerpromoService.offerGrpReload();
          if (this.offerPromoTable) {
            this.offerPromoTable.clearSelection();
          } else if (this.offerPromoCards) {
            this.offerPromoCards.clearSelection();
          }
        },
      });
    }
  }

  searchOfferTags(event: any) {
    this.offerTagService.search(event.target.value);
  }

  tagSelected(event: any) {
    this.offerpromoService.removeFilterByFieldName('offerTagId');
    this.selectedTag = event.option.value.OfferTagName;
    this.filtersForm['controls']['offerTags'].setValue(event.option.value.Id);
    const offerTagFilters: Filter[] = [
      {
        displayText: event.option.value.OfferTagName,
        fieldName: 'offerTagId',
        value: event.option.value.Id,
      },
    ];
    // add the filters to the event service
   // this.offerpromoService.addFilters(offerTagFilters);
  }



  triggerEmailForActiveUsersInNextStage() {
    const stages = this.applicableWorkflowPermissions?.Stages;
    const activeStageIndex = stages.findIndex((s: any) => s.Status == 'active');

    if (
      activeStageIndex != undefined &&
      activeStageIndex > -1 &&
      stages.length > activeStageIndex + 1
    ) {
      const stageToMarkActive =
        this.applicableWorkflowPermissions?.Stages[activeStageIndex + 1];
      const urlWithDomainAndEventId = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      );

      const eventId = window.location.hash.split('/')[3];

      // const emailTemplateString = `<html><body>Stage <b>Stage ${stageToMarkActive.StageName
      //   }</b> of Workflow <b>${this.awDetails?.TemplateName // -------------------> GetWorkflowName!
      //   }</b>,  is now Active.<br /> Access the Event here:<br />  ${urlWithDomainAndEventId}/offers</body></html>`;

      const emailTemplateString = this.ewService.getEmailBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      const inappTemplateString = this.ewService.getInappBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      this.ewService.triggerCustomKnockNotification(
        stageToMarkActive.users?.map((u: any) =>
          (u.email as string)?.toLowerCase()?.trim()
        ),
        emailTemplateString,
        '$users',
        'pr1-notification',
        inappTemplateString
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
