
<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
<!-- page title -->
<div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
    <div fxLayout="row" class="arrows">
        <!-- <span class="nextButtons statusAPPROVED">
            <span class="number">{{statusCount.Approved}}</span> <br /> Approved
        </span>
        <span class="nextButtons statusPENDING">
            <span class="number">{{statusCount.Pending}}</span> <br /> Pending
        </span>        
        <span class="nextButtons statusSUPPRESSED">
            <span class="number">{{statusCount.Suppressed}}</span> <br /> Suppressed
        </span> -->
        <!-- <mat-button-toggle-group #status="matButtonToggleGroup" multiple class="multiple-toggle-btns arrows">
            <mat-button-toggle title="View All Approved" value="Approved" class="nextButtons statusAPPROVED"><fa-icon *ngIf="isSelected(viewModel, 'Approved')" [icon]="['fas', 'check']"></fa-icon>
                <div class="number-text"><span class="number">{{statusCount.Approved}}</span> <br />Approved</div>
            </mat-button-toggle>
                <mat-button-toggle title="View All Pending" value="Pending" class="nextButtons statusPENDING"><fa-icon *ngIf="isSelected(viewModel, 'Pending')" [icon]="['fas', 'check']"></fa-icon>
                    <div class="number-text"><span class="number">{{statusCount.Pending}}</span> <br /> Pending</div>
                    </mat-button-toggle>
                <mat-button-toggle title="View All Suppressed" value="Suppressed" class="nextButtons statusSUPPRESSED"><fa-icon *ngIf="isSelected(viewModel, 'Suppressed')" [icon]="['fas', 'check']"></fa-icon>
                    <div class="number-text"><span class="number">{{statusCount.Suppressed}}</span> <br /> Suppressed</div>
                    </mat-button-toggle>
        </mat-button-toggle-group> -->
        <!-- <button mat-button class="nextButtons statusPENDING" title="Pending">  </button>
        <button mat-button class="nextButtons statusAPPROVED" title="Approved">Approved
            {{statusCount.Approved}}</button>
        <button mat-button class="nextButtons statusSUPPRESSED" title="Suppressed">Suppressed
            {{statusCount.Suppressed}}</button> -->
    </div>
</div>

    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button *ngIf="!readOnlyMode" appAuthorizeControl="edit||offermang" type="button" mat-raised-button
                color="accent" matTooltip="Add new record" queryParamsHandling="preserve">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Offers          
            </button>

            <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0) && !readOnlyMode"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
                <button style="margin-left: -25px;" appAuthorizeControl="edit||offermang" type="button" mat-menu-item [matMenuTriggerFor]="Select"><mat-icon>check_circle_outline</mat-icon>Status</button>
                <button style="margin-left: -25px;" type="button" mat-menu-item appAuthorizeControl="edit||offermang"
                    (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" style="margin-left: -13px;" mat-menu-item
                    *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? offerPromoTable?.getSelectedSectionRecords()?.length :
                    offerPromoCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
            </mat-menu>
            <mat-menu #Select="matMenu">
                <button mat-menu-item (click)="updateStatus(1)">Pending</button>
                <button mat-menu-item (click)="updateStatus(2)">Approve</button>
                <button mat-menu-item (click)="updateStatus(3)">Suppress</button>
            </mat-menu>

            <!-- <button *ngIf="!readOnlyMode" appAuthorizeControl="edit||offermang" type="button" mat-raised-button
                color="accent" matTooltip="Add new record" [routerLink]="[0, 'promos','promodetails']" queryParamsHandling="preserve">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Promo
            </button> -->

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Offers</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>

            <!--only display the sort by dropdown for the cards view(table view sorts on columns)-->           

            <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

           
        </div>

        <mat-sidenav-container>           

            <!--main content-->
            <!--table view-->
            <app-offergroup-offers-cards #offerPromoCards *ngIf="viewModel.viewMode === 'CARDS'"
                [readOnly]="readOnlyMode"></app-offergroup-offers-cards>
            <!--expansion view-->
            <app-offergroup-offers-table #offerPromoTable *ngIf="viewModel.viewMode === 'TABLE'" [readOnly]="readOnlyMode"></app-offergroup-offers-table>

        </mat-sidenav-container>

    </form>

</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>