import { AssetDetail, Offer, JAICreative } from "..";

export interface OfferDomain<TOffer extends Offer> {
    Detail: TOffer;
    ParentOfferId?: string;
    OfferTagIds?: string[];
    VersionIds?: string[];
    AssetIds?: string[];
    BadgeAssetId?: string;
    FacingAsset?: AssetDetail;
    OfferTypeId?: string;
    PricingPreviewHtml?: string;
    DomainId?: string;
    Versions?: any;
    PrintPageId?:string;
    PageNumber?:string;
    Position?:number;
    CommentsCount?:number;
    OfferHasProducts?: boolean;
    ProductCategoryIds?: string[];
    JAICreativeId?: string;
}