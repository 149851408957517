<!-- page title -->
<h1>Offer Tag Management</h1>

<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>
        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button *ngIf="searchInput" type="button" mat-raised-button color="accent" matTooltip="Add new record"
                [routerLink]="[0]" [queryParams]="{search: searchInput.value ? searchInput.value : null}">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Offer Tag
            </button>
    
            <button type="button" 
            *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
                <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
                <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" mat-menu-item>
                    {{getSelectedSectionRecords().length}}
                    Selected</button>
            </mat-menu>
            <mat-menu #Download="matMenu">
                <button mat-menu-item (click)="downloadJSON()">JSON</button>
                <button mat-menu-item (click)="downloadCSV()">CSV</button>
            </mat-menu>
    
            
            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search" #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
    
            <span class="menu-spacer"></span>
         
            <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
            </button>
        </div>
        <mat-sidenav-container>
            <!--filter sidenav-->
            <mat-sidenav #filterSidenav mode="over" position="end">
                <mat-toolbar>
                    Filter
                    <span class="menu-spacer"></span>
                    <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                        (click)="filterSidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
                <div style="margin: 16px">
                    <div fxLayout="column">
    
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                                (dateChange)="onDateRangeChange()">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-error *ngIf="filtersForm.controls.startDate.hasError('required')">
                                Start date is required
                            </mat-error>
                            <mat-error *ngIf="filtersForm.controls.startDate.hasError('matStartDateInvalid')">
                                Invalid start date
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                                (dateChange)="onDateRangeChange()">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-error *ngIf="filtersForm.controls.endDate.hasError('required')">
                                End date is required
                            </mat-error>
                            <mat-error *ngIf="filtersForm.controls.endDate.hasError('matEndDateInvalid')">
                                Invalid end date
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline">
                            <mat-label>Offer Tags</mat-label>
                            <mat-select multiple formControlName="offertags" (selectionChange)="onFilterChange($event)">
                              <mat-option *ngFor="let offerTag of viewModel.offerTagsWithExpired" [value]="offerTag" [class.offertags-expired]="offerTag.Expired">
                                {{offerTag.OfferTagName}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
    
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                        <button type="button" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                            Reset
                        </button>
                        <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                            (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                            Apply
                        </button>
                    </div>
                </div>
    
            </mat-sidenav>
    
            <!--main content-->
        <!--filter chips -->
        <div class="filter-chips" *ngIf="viewModel.filters.length > 0" fxLayout="row">
            <mat-chip-listbox aria-label="Selected filters">
                <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
                    {{filter.displayText}}
                    <button type="button" matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-option>
            </mat-chip-listbox>
            <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
                Clear all
            </button>
        </div>
            <!--table view-->
            <ng-container>
                <table mat-table [dataSource]="viewModel.offerTags" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" *ngIf="viewModel.offerTags.length">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td class="no-ellipsis" mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
            
                    <!-- Id Column -->
                    <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
                            Id
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Id }}</td>
                    </ng-container>
            
                    <!-- OfferTagName Column -->
                    <ng-container matColumnDef="OfferTagName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                            Name
                        </th>
                        <td mat-cell *matCellDef="let record"> {{ record.OfferTagName }}</td>
                    </ng-container>
            
                    <!-- Title Column -->
                    <ng-container matColumnDef="Title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Title"
                            [resizeColumn]="true">
                            Title
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.Title }}</td>
                    </ng-container>
                   
            
                    <!-- StartDate Column -->
                    <ng-container matColumnDef="StartDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by StartDate"
                            [resizeColumn]="true">
                            Start Date
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.StartDate }}</td>
                    </ng-container>
            
                    <!-- EndDate Column -->
                    <ng-container matColumnDef="EndDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by EndDate"
                            [resizeColumn]="true">
                            End Date
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.EndDate }}</td>
                    </ng-container>
                        
                    <!-- ClientKey Column -->
                    <ng-container matColumnDef="ClientKey">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
                            Client Key
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
                    </ng-container>
                                
                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let record">
                            <button type="button" mat-button color="accent" [routerLink]="[record.Id]" [queryParams]="{search: searchInput.value ? searchInput.value : null}">Edit</button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.offertags-expired]="row.Expired"></tr>
                </table>
            
                <table *ngIf="!viewModel.offerTags.length">
                    <tr>
                        <td class="no-records" [attr.colspan]="displayedColumns.length">
                            <h2>No Records Found</h2>
                        </td>
                    </tr>
                </table>
            
                <!-- pager -->
                <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offerTags.length"
                    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
                </mat-paginator>
            
            </ng-container>
    
        </mat-sidenav-container>
    </form>
</ng-container>