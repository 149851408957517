<div class="edit-workflow-container">
  <div class="flex-space-between">
    <div style="width: auto; display: flex; align-items: center">
      <h1>
        {{
          editWorkflowForm?.value?.template_info?.template_name
            ? editWorkflowForm?.value?.template_info?.template_name
            : template_header
        }}
      </h1>

      <!-- Start of Workflow Status -->
      <div
        *ngIf="
          editWorkflowForm?.value?.workflow_status &&
          editWorkflowForm?.value?.workflow_status.trim().length > 0
        "
        class="workflow-status-container"
      >
        <!-- Workflow status indicator / light -->
        <div
          class="workflow-status-indicator"
          [ngClass]="{
            'workflow-in-progress':
              editWorkflowForm?.value?.workflow_status == 'in_progress',
            'workflow-paused':
              editWorkflowForm?.value?.workflow_status == 'paused',
            'workflow-completed':
              editWorkflowForm?.value?.workflow_status == 'completed'
          }"
        ></div>
        <!-- Workflow Status in text -->
        <p>{{ workflowStatus }}</p>
      </div>
      <!-- End of Workflow Status -->
    </div>

    <span class="close-icon" (click)="cancelEditWorkflow()">
      <fa-icon [icon]="['fas', 'x']"></fa-icon>
    </span>
  </div>

  <form *ngIf="editWorkflowForm" [formGroup]="editWorkflowForm">
    <!-- Template Details -->
    <div
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      fxFlexFill
      formGroupName="template_info"
    >
      <mat-form-field appearance="outline" fxFlex="30">
        <mat-label>Template Name</mat-label>
        <input
          matInput
          required
          formControlName="template_name"
          [readonly]="true"
        />
        <br />
      </mat-form-field>

      <div style="flex-direction: column !important" fxFlex="30">
        <mat-form-field
          appearance="outline"
          style="
            width: 100% !important;
            padding-bottom: 0 !important;
            max-height: 68% !important;
          "
        >
          <mat-label>Group</mat-label>
          <mat-select
            formControlName="template_group"
            name="template_group"
            [disabled]="true"
          >
            <mat-option
              *ngFor="let w of workflowActiveGroupsRefData"
              [value]="w.value"
            >
              <span>{{ w.label }}</span>
            </mat-option>
          </mat-select>
          <br />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" fxFlex="30">
        <mat-label>Category</mat-label>
        <input
          matInput
          formControlName="template_category"
          name="template_category"
          [readonly]="true"
        />
        <br />
      </mat-form-field>
    </div>

    <!-- Workflow Config -->
    <mat-accordion class="config-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Workflow Configuration</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          fxLayout="row wrap"
          fxLayoutGap.gt-sm="16px"
          fxLayout.lt-md="column"
          fxFlexFill
          formGroupName="template_info"
        >
          <mat-stepper
            [linear]="false"
            labelPosition="bottom"
            #stepper
            (selectionChange)="onSelectionChange($event)"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="availableWorkflowStages"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              *ngFor="
                let stage of availableWorkflowStages;
                let stageIndex = index
              "
              class="stage-list"
            >
              <mat-step
                [matMenuTriggerFor]="
                  stage.value.stage_status != 'skipped' ? menu : null
                "
                [stepControl]="stage"
                [completed]="false"
                [ngClass]="{
                  'active-mat-step': stage.value.stage_status == 'active'
                }"
                [aria-label]="
                  stage.value.stage_status == 'active'
                    ? 'current-active-step'
                    : 'inactive-step'
                "
              >
                <ng-template matStepLabel>
                  <div>
                    {{ stage.value?.stage_name }}
                  </div>
                  <div
                    class="stage-drag"
                    cdkDragLockAxis="x"
                    cdkDrag
                    [cdkDragDisabled]="
                      stage.value?.stage_status == 'active' ||
                      stage.value?.stage_status == 'skipped' ||
                      stage.value?.stage_status == 'completed'
                    "
                    [ngClass]="{
                      'no-access-cursor':
                        stage.value?.stage_status == 'active' ||
                        stage.value?.stage_status == 'skipped' ||
                        stage.value?.stage_status == 'completed'
                    }"
                  ></div>
                </ng-template>
              </mat-step>
            </div>
          </mat-stepper>

          <mat-menu #menu="matMenu">
            <button mat-menu-item>Remove Stage</button>
          </mat-menu>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- <div
      *ngIf="availableWorkflowStages.length == 0"
      class="add-stage-link-container"
    >
      <b><a (click)="addStage()">+ Add Stage</a></b>
    </div> -->

    <!-- Workflow Stages -->
    <div formArrayName="workflow_stages">
      <div style="margin-top: 1rem !important">
        <mat-accordion
          cdkDropList
          [cdkDropListData]="availableWorkflowStages"
          (cdkDropListDropped)="drop($event)"
        >
          <mat-expansion-panel
            style="margin: 1rem 0"
            *ngFor="let w of availableWorkflowStages; let i = index"
            [ngClass]="{ 'mat-expansion-panel-error': duplicateStageFound[i] }"
            [expanded]="expandedPanels[i] == true"
            (opened)="onPanelOpen(i, $event)"
            cdkDrag
            [cdkDragDisabled]="
              w.value.stage_status == 'active' ||
              w.value.stage_status == 'skipped' ||
              w.value.stage_status == 'completed'
            "
          >
            <mat-expansion-panel-header
              [ngClass]="{
                'panel-header-active':
                  w.value.stage_status == 'active' &&
                  ewData.workflow_status == 'in_progress'
              }"
            >
              <mat-panel-title
                [ngClass]="{ 'panel-title-error': duplicateStageFound[i] }"
                style="justify-content: space-between !important"
              >
                <div class="skip-drag-container">
                  <span
                    cdkDragHandle
                    class="grabbable"
                    [ngClass]="{
                      'no-access-cursor':
                        w.value.stage_status == 'active' ||
                        w.value.stage_status == 'skipped' ||
                        w.value.stage_status == 'completed'
                    }"
                  >
                    <mat-icon class="drag-indicator-icon"
                      >drag_indicator</mat-icon
                    >
                  </span>
                  <span class="stage_name_text">{{
                    availableWorkflowStages[i].value?.stage_name
                  }}</span>
                  <span
                    *ngIf="
                      w.value.stage_status != 'skipped' &&
                      w.value.stage_status != 'completed' &&
                      w.value.stage_status != 'active' &&
                      adminAccess()
                    "
                    class="stage-title-hyphen"
                    >-</span
                  >
                  <a
                    *ngIf="
                      w.value.stage_status != 'skipped' &&
                      w.value.stage_status != 'completed' &&
                      w.value.stage_status != 'active' &&
                      adminAccess()
                    "
                    (click)="removeStage(i)"
                    >Remove Stage</a
                  >
                </div>

                <div class="skip-drag-container">
                  <span
                    *ngIf="
                      workflowInProgress &&
                      w.value.stage_status == 'active' &&
                      adminAccess() &&
                      w.value.stage_status == 'active'
                    "
                    (click)="skipStage(w, i)"
                  >
                    <a class="disabled-link">Skip Stage ></a>
                  </span>
                </div>
                <!-- <span
                  class="stage-skipped-text"
                  *ngIf="w.value.stage_status == 'skipped'"
                  ><i>Stage Skipped</i></span
                > -->
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div
              fxLayout="row wrap"
              fxLayoutGap.gt-sm="16px"
              fxLayout.lt-md="column"
              fxFlexFill
              formGroupName="{{ i }}"
            >
              <!-- First Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
              >
                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label>Stage Name</mat-label>
                  <input
                    matInput
                    required
                    formControlName="stage_name"
                    (input)="checkIfStageExists($event, i)"
                    [readonly]="w.value.stage_status == 'skipped'"
                  />

                  <br />
                  <small *ngIf="duplicateStageFound[i]"
                    >Stage already exists. Please choose a different
                    name.</small
                  >
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label>Description</mat-label>
                  <input
                    matInput
                    formControlName="stage_description"
                    [readonly]="w.value.stage_status == 'skipped'"
                  />
                  <br />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label data-testid="Task">Task</mat-label>

                  <!-- If stage is skipped -->
                  <input
                    *ngIf="w.value.stage_status == 'skipped'"
                    matInput
                    formControlName="stage_task"
                    [readonly]="true"
                  />

                  <!-- If stage is not skipped -->
                  <mat-select
                    formControlName="stage_task"
                    name="stage_task"
                    (selectionChange)="onStageTaskChange($event, i)"
                    *ngIf="w.value.stage_status != 'skipped'"
                  >
                    <mat-option *ngFor="let task of stageTasks" [value]="task">
                      <span>{{ task }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Second Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
                style="margin-top: 2.5rem !important"
              >
                <!-- Users Heading -->
                <div
                  fxLayout="row wrap"
                  fxLayoutGap.gt-sm="16px"
                  fxLayout.lt-md="column"
                  class="users-table-heading"
                >
                  <h3 class="users-heading">Users</h3>

                  <button
                    *ngIf="
                      w.value.stage_status != 'skipped' &&
                      w.value.stage_status != 'completed' &&
                      adminAccess()
                    "
                    type="button"
                    mat-raised-button
                    color="accent"
                    matTooltip="Add User"
                    (click)="openAddUsersToWorkflow(i)"
                  >
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add User
                  </button>

                  <!-- Actions drop down -->
                  <button
                    mat-raised-button
                    [matMenuTriggerFor]="actionsMenu"
                    *ngIf="
                      showActions[i] == true &&
                      adminAccess()
                    "
                  >
                    <mat-icon>more_vert</mat-icon>
                    Actions
                  </button>
                  <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="roles">
                      <mat-icon>mail</mat-icon><span>Role</span>
                    </button>

                    <button mat-menu-item [matMenuTriggerFor]="emailAlertsMenu">
                      <mat-icon>notifications</mat-icon
                      ><span>Email Alerts</span>
                    </button>

                    <button mat-menu-item (click)="deleteSelectedUsers(i)">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>

                    <button mat-menu-item style="margin-top: 2px">
                      <span>{{ selectedUsersIndex?.length }} Selected</span>
                    </button>
                  </mat-menu>

                  <!-- Task Menu -->
                  <mat-menu #roles="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let role of rolesRefData"
                      (click)="rolesMultiSet(role.label, i)"
                    >
                      {{ role.label }}
                    </button>
                  </mat-menu>

                  <!-- Email Alerts Menu -->

                  <mat-menu #emailAlertsMenu="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let alert of emailAlertsRefData"
                      (click)="emailAlertsMultiSet(alert.label, i)"
                    >
                      {{ alert.label }}
                    </button>
                  </mat-menu>
                </div>

                <!-- Users Table -->
                <div class="users-table">
                  <workflow-users-table
                    [tableData]="availableWorkflowStages[i].value?.users"
                    [usersDeleted]="usersDeleted"
                    (onCheckboxSelected)="onSelectUsers($event, i)"
                    (onUsersSelected)="selectUsers($event)"
                    [readOnly]="
                      w.value.stage_status == 'skipped' ||
                      w.value.stage_status == 'completed' ||
                      !adminAccess()
                    "
                  ></workflow-users-table>
                </div>
              </div>

              <div class="stage_status_container">
                <!-- {{ logStageStatus(w.value) }} -->
                <div
                  *ngIf="w.value.stage_status == 'completed'"
                  class="stage_status_container"
                >
                  <mat-icon class="completed-check-circle"
                    >check_circle</mat-icon
                  >
                  <p>Completed!</p>
                </div>

                <div
                  *ngIf="
                    workflowInProgress &&
                    w.value.stage_status == 'active' &&
                    adminAccess(w.value.stage_status)
                  "
                >
                  <button
                    mat-raised-button
                    class="mark_complete_btn"
                    (click)="markStageAsCompleteAndSave(i)"
                  >
                    <mat-icon>check_circle_outline</mat-icon>
                    Mark Complete
                  </button>
                </div>

                <div *ngIf="w.value.stage_status == 'not_started'">
                  <p style="color: rgba(0, 0, 0, 0.38)">Not Started</p>
                </div>

                <div *ngIf="w.value.stage_status == 'skipped'">
                  <p style="color: rgba(0, 0, 0, 0.38)">Skipped</p>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div
      class="add-stage-link-container"
      *ngIf="
        editWorkflowForm?.value?.workflow_status != 'completed' && adminAccess()
      "
    >
      <b><a (click)="addStage()">+ Add Stage</a></b>
    </div>

    <div class="edit-workflow-actions-container">
      <div class="delete-btn-container">
        <button
          *ngIf="adminAccess()"
          mat-raised-button
          color="warn"
          (click)="onDeleteWorkflow()"
        >
          Delete
        </button>
      </div>
      <div class="edit-workflow-actions">
        <button mat-button (click)="cancelEditWorkflow()">Cancel</button>
        <button
          *ngIf="adminAccess()"
          class="save-workflow-btn"
          mat-stroked-button
          color="accent"
          (click)="onSave()"
          [disabled]="
            duplicateInAnyStage ||
            templateInfoInvalid() ||
            availableWorkflowStages.length == 0 ||
            anyStageInvalid() ||
            anyStageWithNoUsers()
          "
        >
          Save Workflow
        </button>
        <div
          matTooltip="Another workflow in progress"
          matTooltipClass="example-tooltip-uppercase"
          [matTooltipPosition]="'above'"
          [matTooltipDisabled]="statusChangeDisabled() == false"
        >
          <button
            *ngIf="
              editWorkflowForm?.value?.workflow_status != 'completed' &&
              adminAccess()
            "
            mat-raised-button
            color="accent"
            (click)="changeWorkflowStatus()"
            [disabled]="
              duplicateInAnyStage ||
              templateInfoInvalid() ||
              availableWorkflowStages.length == 0 ||
              anyStageInvalid() ||
              anyStageWithNoUsers() ||
              statusChangeDisabled()
            "
          >
            <span
              *ngIf="
                (!ewData?.workflow_status ||
                  ewData?.workflow_status?.trim()?.length == 0) &&
                ewData?.workflow_status != 'in_progress' &&
                ewData?.workflow_status != 'paused'
              "
            >
              Save & Activate Workflow
            </span>

            <span
              *ngIf="
                ewData?.workflow_status &&
                ewData?.workflow_status?.trim()?.length > 0 &&
                ewData?.workflow_status == 'in_progress'
              "
            >
              Save & Pause Workflow
            </span>

            <span
              *ngIf="
                ewData?.workflow_status &&
                ewData?.workflow_status?.trim()?.length > 0 &&
                ewData?.workflow_status == 'paused'
              "
            >
              Save & Resume Workflow
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
