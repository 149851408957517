<mat-card appearance="outlined">
  <mat-card-content>
    <app-assets-list context="Events" contextId="{{eventId}}"></app-assets-list>
    <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 16px;">
      <button mat-stroked-button type="reset" color="accent" (click)="cancel()">
        <span *ngIf="canEdit()">Cancel</span>
        <span *ngIf="!canEdit()">Close</span>
      </button>
    </div>
  </mat-card-content>
</mat-card>