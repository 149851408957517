import { AssetDetail, OfferPromo } from "..";

export interface PromoDomain<T extends OfferPromo> {
    Detail: T;
    DomainId?: string;
    CommentsCount?:number;
    EventId?: string;
    OfferTagIds?: string[];
    PromoAssetId?: string;
    PricingPreviewHtml?: string;
    VersionIds?: string[];
    AssetIds?: string[];
    BadgeAssetId?: string;
    OfferTags?: string;
    EventType?: string;
    FacingAsset?: AssetDetail;
    PromoIds?: string[];
    VersionNames?: string;
    OfferVariants?: any;
    allVerisions?: any;
    OfferVariantsCount?: number;
    OfferGroupOffersCount?: number;
    Versions?: string;
}