<div fxLayout="column" class="edit-offer-tags-popup">
    <div class="edit-offer-tags-popup-header">
        <h2>Edit Offer Tags  <fa-icon class="close" (click)="closeDialog()" [icon]="['fas', 'times']"></fa-icon></h2>
       
    </div>
    <h3>Currently Applied Tags</h3>

    <div *ngIf="offerexists">
        <div class="list" *ngFor="let response of offerTagsResponse" fxLayout="row">
            <div fxFlex="20">
                <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip *ngIf="response.TagId" (removed)="removeOfferTags(response)">{{ response.TagName }}
                        ({{response && response.Offers && response.Offers.length}})
                        <button style="color: darkred;" type="button" matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-listbox>
            </div>
            <div fxFlex="80">
                <div class="list" fxLayout="row">
                    <div *ngIf="response.TagId" class="headline" fxFlex="80" >
                        <ng-container *ngFor="let offer of response.Offers; let i=index">
                            <p *ngIf="!response.showLess && i <= 10"
                                title="{{offer.Headline ? offer.Headline : offer.OfferName}}" style="margin-top: 4px;">
                                {{offer.Headline ? getDisplayValue(offer.Headline) :
                                getDisplayValue(offer.OfferName)}} </p>
                            <p *ngIf="response.showLess" title="{{offer.Headline ? offer.Headline : offer.OfferName}}"
                                style="margin-top: 4px;">
                                {{offer.Headline ? getDisplayValue(offer.Headline) :
                                getDisplayValue(offer.OfferName)}} </p>
                        </ng-container>
                    </div>
                    <div *ngIf="response.Offers && response.Offers.length > 10" class="list-btn" fxFlex="20">
                        <button *ngIf="!response.showLess  && response.TagId" mat-flat-button color="primary"
                            (click)="response.showLess = true">(+{{response.Offers.length -
                            10}}) View
                            All</button>
                        <button *ngIf="response.showLess && response.TagId" mat-flat-button color="primary"
                            (click)="response.showLess = false">View Less</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!offerexists || removeTags">
        <p class="no-tags"> None applied</p>
    </div>
    <div fxLayout="row">
        <button type="button" mat-raised-button *ngIf="offerTagsHistory.length > 0" (click)="undoTags()"><fa-icon
                [icon]="['fas', 'undo']"></fa-icon>Undo</button>
        <button (click)="removeAllTags()" *ngIf="offerexists" type="button" class="removetags"
            mat-raised-button><mat-icon>cancel</mat-icon>
            Remove All Tags</button>
    </div>

    <div class="add-new-tags">
        <h3>Add New Tags to All</h3>
        <app-pr1-offer-tags
            [placeholder]="'Associate Tag...'"
            [label]="'Offer Tags'"
            [selectedTags]="OfferTagIds"
            (tagRemoved)="removeTag($event)"
            (tagAdded)="addTag($event)"
            [offerTagsFormControl]="tagCtrl"
            (tagSelectedEmitter)="tagSelected($event)">
        </app-pr1-offer-tags>
    </div>
</div>

<mat-card-actions fxLayout="row" fxLayoutAlign="end" class="edit-offer-tags-popup-actions">
        <button mat-stroked-button type="button" (click)="closeDialog()">
            Cancel
        </button>
        <button mat-raised-button type="button" color="accent" (click)="saveOfferTags()">
            Save
        </button>
</mat-card-actions>