import { AssetDetail, Product } from "..";

export interface ProductDomain<TProduct extends Product> {
  Detail: TProduct;
  DomainId: string;
  ProductCategoryIds: string[];
  ProductBrandId: string;
  ProductBrandName?: string;
  ProductCategoryName?: string;
  AssetIds?: string[];
  VendorId?: string;
  VendorName?: string;
  Rank?: number;
  FacingAsset?: AssetDetail;
}
