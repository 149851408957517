import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { map, combineLatest, Observable } from 'rxjs';
import { Logger, PreviewMode, UtilityService } from 'src/app/@shared';
import { OfferProductService, Product, ProductDomain } from '../..';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('OfferProductsCardsComponent');

@Component({
  selector: 'app-offer-products-cards',
  templateUrl: './offer-products-cards.component.html',
  styleUrls: ['./offer-products-cards.component.scss'],
})

export class OfferProductsCardsComponent<T extends Product> implements OnInit, OnDestroy {
  viewModel$ = combineLatest([
    this.offerProductService.products$,
    this.offerProductService.isLoading$,
    this.offerProductService.totalRecords$,
    this.offerProductService.page$,
  ]).pipe(
    map(([products, isLoading, totalRecords, page]) => {
      if (products.length == 0 && page.pageIndex > 0) {
        this.offerProductService.resetpage();
        this.offerProductService.reload();
      }
      return { products, isLoading, totalRecords, page }
    }),
  );
  productsList: ProductDomain<Product>[] = [];
  PreviewMode = PreviewMode;
  selection = new SelectionModel<ProductDomain<Product>>(true, []);
  constructor(private offerProductService: OfferProductService<T>,
    private route: ActivatedRoute, public utilityService: UtilityService, private authorizationService: AuthorizeControlService){}

  ngOnInit(): void {
    log.debug('init');
    this.offerProductService.offerId = this.route.snapshot.params['offerId'];
    this.offerProductService.products$.subscribe((data) => {
      this.productsList = data;
    });

  }

  ngOnDestroy() { }

  onSort(sortState: Sort): void {
    this.offerProductService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerProductService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  isAdmin(): boolean {
    const hasAccess = this.authorizationService.checkAccess('admin||offermang');
    return hasAccess;
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png'; // Path to your default image
  }
}
