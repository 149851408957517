export interface OfferTag {
    ClientKey?: string;
    Id: string;
    OfferTagName: string;
    Title?:string;
    StartDate?:string;
    EndDate?:string;
    Description?:string;
    Disclaimer?:string;
    Expired?:boolean;
}

export interface OfferTagsCount {
    TagId?: string;
    TagName?: string;
    Offers: [] | string[];
    Count?: number
}