import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset } from '../..';

interface AssetModelData {
  asset: Asset,
}

@Component({
  selector: 'app-assets-rank-dialog',
  templateUrl: './assets-rank-dialog.component.html',
  styleUrls: ['./assets-rank-dialog.component.scss']
})
export class AssetsRankDialogComponent {
  asset!: Asset;
  modelData!: AssetModelData;
  @Output() onSelection: EventEmitter<{ asset: Asset, rank: number }> = new EventEmitter();

  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AssetsRankDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssetModelData
  ) {
    this.modelData = data;
    this.asset = this.modelData.asset;

    this.form = new FormGroup({
      rank: new FormControl(this.asset.Rank, [Validators.required, Validators.min(1)])
    });
  }

  get rankControl() {
    return this.form.get('rank');
  }

  save(): void {
    if (this.form.valid) {
      const rank: number = this.rankControl?.value;
      this.onSelection.emit({ asset: this.asset, rank });

      this.closeDialog();
    } else {
      console.error('Form is invalid');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
