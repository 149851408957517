import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { DEFAULT_PAGING, OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { Asset, AzureSearchConfig } from '..';


@Injectable({
  providedIn: 'root'
})
export class AssetsListService {
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private contextBehaviorSubject = new BehaviorSubject<string>('');
  private contextIdBehaviorSubject = new BehaviorSubject<string>('');

  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }
  set context(context: string) {
    this.contextBehaviorSubject.next(context);
  }

  set contextId(contextId: string) {
    this.contextIdBehaviorSubject.next(contextId);
  }

  // GetSearchMetaData for Assets Azure search
  GetSearchMetaData(): Observable<AzureSearchConfig> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetSearchMetaData`;
    return this.httpClient.get<AzureSearchConfig>(url);
  }

  public params$ = combineLatest([
    this.contextBehaviorSubject,
    this.contextIdBehaviorSubject,
    this.pageBehaviorSubject,
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([context, contextId, page, sort, search, reload]) => {
      let orderby = sort.active === 'Rank' ? `${sort.active} ${sort.direction}` : `Detail/${sort.active} ${sort.direction}`;

      let params: HttpParams = new HttpParams({
        fromObject: {
          context,
          contextId,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: orderby,
          $count: true,
          $expand: 'Detail',
          ...(search.length ? { $search: `"${search}"` } : {})
        }
      });

      return params;
    })
  );

  reload() {
    this.reloadBehaviorSubject.next(uuidv4());
  }

  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  resetServiceState(): void {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
    this.loadingBehaviorSubject.next(false);
    this.searchBehaviorSubject.next('');
    this.sortBehaviorSubject.next({ active: 'Rank', direction: 'asc' });
    this.reloadBehaviorSubject.next('');
    this.contextBehaviorSubject.next('');
    this.contextIdBehaviorSubject.next('');
  }

  private assetsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetByContext`, { params: _params })
    ),
    finalize(() => this.loadingBehaviorSubject.next(false)), // Auto-reset isLoading
    shareReplay({ refCount: true, bufferSize: 1 }) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  public assets$: Observable<Asset[]> = this.assetsResponse$.pipe(
    map((res: any) => res.value)
  );

  public totalRecords$: Observable<number> = this.assetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  public getAllIdsByContext(): Observable<string[]> {
    const context = this.contextBehaviorSubject.value;
    const contextId = this.contextIdBehaviorSubject.value;
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAllIdsByContext?context=${context}&contextId=${contextId}`;

    return this.httpClient.get<any>(url).pipe(
      map(response => response.value)
    );
  }

  public addAssociationsByContext(ids: string[]) {
    const context = this.contextBehaviorSubject.value;
    const contextId = this.contextIdBehaviorSubject.value;
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/AddAssociationsByContext?context=${context}&contextId=${contextId}`;

    return this.httpClient.post(url, ids);
  }

  public removeAssociationsByContext(ids: string[]) {
    const context = this.contextBehaviorSubject.value;
    const contextId = this.contextIdBehaviorSubject.value;
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/RemoveAssociationsByContext?context=${context}&contextId=${contextId}`;

    return this.httpClient.post(url, ids);
  }

  public updateAssociationRankByContext(assetId: string, rank: number) {
    const context = this.contextBehaviorSubject.value;
    const contextId = this.contextIdBehaviorSubject.value;
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateAssociationRankByContext?context=${context}&contextId=${contextId}&assetId=${assetId}&rank=${rank}`;

    return this.httpClient.post(url, null);
  }

}
