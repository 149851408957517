import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { Offer, OfferDomain, OfferService, Version, VersionOfferService, OfferAddVersionsComponent, OfferPromoService, OfferPromo, PromoDomain, OfferGroupAddVersionsComponent } from '../..';
import { combineLatest, map, Subscription, distinctUntilChanged } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthorizeControlService } from 'pr1-ui-components';


const log = new Logger('OfferGroupEditVersionsComponent');

@Component({
  selector: 'app-offer-group-edit-versions',
  templateUrl: './offer-group-edit-versions.component.html',
  styleUrls: ['./offer-group-edit-versions.component.scss'],
})

export class OfferGroupEditVersionsComponent implements OnInit, OnDestroy {
  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  viewModel$ = combineLatest([
    this.versionOfferService.offerGversions$,
    this.versionOfferService.isLoading$,
    this.versionOfferService.offerGtotalRecords$,
    this.versionOfferService.offerGPage$,
    this.versionOfferService.offerGroupId$,
  ]).pipe(
    map(([versions, isLoading, totalRecords, page, versionOfferId]) => {
      if (versions.length == 0 && page.pageIndex > 0) {
        this.versionOfferService.resetpage();
        this.versionOfferService.offerGreload();
      }
      return { versions, isLoading, totalRecords, page, versionOfferId }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'VersionName'];
  versionsList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  constructor(
    private versionOfferService: VersionOfferService,
    private offerService: OfferService<Offer, OfferDomain<Offer>>,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private mediaObserver: MediaObserver,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.versionOfferService.offerGreload();
    this.versionOfferService.offerGroupId = this.route.snapshot.params['offerId'];
    //this.offerService.eventId = this.route.snapshot.params['eventId'];

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'VersionName'];
        }
        else {
          this.displayedColumns = ['select', 'VersionName'];
        }
      });

    this.versionOfferService.offerGversions$.subscribe((data) => {
      this.versionsList = data;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OfferGroupAddVersionsComponent, {
      width: '80%',
      height: '90%',
      data: { offerGroupId: this.route.snapshot.params['offerId'], eventId: this.route.snapshot.params['eventId'] }
    });
    this.versionOfferService.dialogSearch('');
  }

  openAddVersionsDialog() {
    //this.offerService.addNewVariant = true;
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  diassociateVersions() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let versions: string[] = this.selection.selected.map(x => x.Id);
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove from version?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.versionOfferService.disassociateofferGVersion(versions).subscribe({
              next: () => {
                this.matSnackBar.open(`Version deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.versionOfferService.offerGreload();
                this.selection.clear();
                this.router.navigate([`../`, `versions`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
              },
              error: (error) => {
                log.error('Error adding version', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }


  onSearch(event: any) {
    this.versionOfferService.offerGsearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionOfferService.offerGsearch('');
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionOfferService.offerGpage(pageEvent);
  }

  onSort(sortState: Sort): void {
    this.versionOfferService.offerGsort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionsList.forEach(row => this.selection.select(row));

    //this.offerService.setSelectedOfferVersionsList = this.selection.selected;
  }

  updateSelectedVersions() {
    //this.offerService.setSelectedOfferVersionsList = this.selection.selected;
  }
  cancel(): void {
    this.versionOfferService.reload();
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  getOfferName(offers: any) {
    if (offers && offers.length > 0) {
      const offerDetail = offers.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['offerId']);
      if (offerDetail && offerDetail.Detail) {
        return offerDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return ''; 
  }


  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }

}
function dialogData(dialogData: any): MatDialog {
  throw new Error('Function not implemented.');
}

