import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeControlService } from 'pr1-ui-components';

@Component({
  selector: 'app-product-asset-list',
  templateUrl: './product-asset-list.component.html',
  styleUrls: ['./product-asset-list.component.scss']
})

export class ProductAssetListComponent implements OnInit {
  productId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizeControlService
  ) { }

  ngOnInit(): void {
    this.productId = this.route.snapshot.params['productId'];
  }

  cancel(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang');
  }
}
