<div fxLayout="row" fxFlexFill fxLayoutAlign="start center">
    <h1>Upload Assets</h1>
    <div fxFlex></div>
    <button type="button" flex="end" mat-raised-button (click)="uploadClose()">Close</button>
</div>
<form [formGroup]="formGroup" novalidate>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxFlex="15" fxFlex.lt-md="100">
            <h3 flex="center" style="color: rgb(65, 65, 186)">Uploading to:</h3>
        </div>
        <div fxFlex="85" fxFlex.lt-md="100">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <label>Asset Group</label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="groupName" placeholder="Choose Asset Group"
                            [compareWith]="compareGroupOption">
                            <mat-option *ngFor="let group of assetGroups$" [value]="group">
                                {{group.AssetGroupName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
    
                <ng-container
                    *ngIf="selectedGroupName?.AssetGroupName == 'Events' || selectedGroupName?.AssetGroupName == 'Offers' || selectedGroupName?.AssetGroupName == 'Promos' || selectedGroupName?.AssetGroupName == 'Versions' || selectedGroupName?.AssetGroupName == 'Image Maps'">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <label>Event</label>
                        <mat-form-field appearance="outline" (click)="openEventsialog()">
                            <input matInput readonly formControlName="event" placeholder="Choose Event" /><span
                                style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                        </mat-form-field>
                    </div>
                </ng-container>
    
                <ng-container *ngIf="selectedGroupName?.AssetGroupName == 'Products'">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <label>Product</label>
                        <mat-form-field appearance="outline" (click)="openProductsDialog()">
                            <!-- <input matInput formControlName="product" readonly /><span><button style="margin-right: 2px;" (click)="openProductsDialog()" mat-button>Edit</button></span> -->
                            <input matInput readonly formControlName="product" placeholder="Choose Product" /><span
                                style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedGroupName?.AssetGroupName == 'Brands'">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <label>Product Brand</label>
                    <mat-form-field appearance="outline" (click)="openProductBrandsDialog()">
                      <!-- <input matInput formControlName="product" readonly /><span><button style="margin-right: 2px;" (click)="openProductsDialog()" mat-button>Edit</button></span> -->
                      <input matInput readonly formControlName="productbrand" placeholder="Choose Product Brand" /><span style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                    </mat-form-field>
                  </div>
                </ng-container>
                <ng-container *ngIf="selectedGroupName?.AssetGroupName == 'Offers' && selectedEvent">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <label>Offer</label>
                        <mat-form-field appearance="outline" (click)="openOffersDialog()">
                            <!-- <input matInput formControlName="offer" readonly /><span><button style="margin-right: 2px;" (click)="openOffersDialog()" mat-button>Edit</button></span> -->
                            <input matInput readonly formControlName="offer" placeholder="Choose Offer" /><span
                                style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="(selectedGroupName?.AssetGroupName == 'Versions' || selectedGroupName?.AssetGroupName == 'Image Maps') && selectedEvent">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <label>Version</label>
                        <mat-form-field appearance="outline" (click)="openVersionsDialog()">
                            <!-- <input matInput formControlName="offer" readonly /><span><button style="margin-right: 2px;" (click)="openOffersDialog()" mat-button>Edit</button></span> -->
                            <input matInput readonly formControlName="version" placeholder="Choose Version" /><span
                                style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedGroupName?.AssetGroupName == 'Promos' && selectedEvent">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                       <label>Promo</label>
                      <mat-form-field  appearance="outline" (click)="openPromosDialog()">
                         <!-- <input matInput formControlName="offer" readonly /><span><button style="margin-right: 2px;" (click)="openOffersDialog()" mat-button>Edit</button></span> -->
                         <input matInput readonly formControlName="promo" placeholder="Choose Promo" /><span
                            style="color: rgb(65, 65, 186); padding-right: 20px;" matSuffix>Edit</span>
                      </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <label>Add Tags</label>
                    <app-pr1-offer-tags
                        [placeholder]="'Add Tag...'"
                        [selectedTags]="OfferTagIds"
                        (tagRemoved)="removeTag($event)"
                        (tagAdded)="addTag($event)"
                        [offerTagsFormControl]="tagCtrl"
                        (tagSelectedEmitter)="tagSelected($event)">
                    </app-pr1-offer-tags>
                </div>
            </div>
        </div>
    </div>

    <br />
    <h3>Assets List</h3>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <button type="button" *ngIf="showButtons" mat-flat-button color="primary" (click)="fileInput.click()">Select Files
            {{selectedFiles !== undefined
            &&
            selectedFiles.length > 0 ? '(' + selectedFiles.length + ')' : ''}}</button>
        <button type="button" *ngIf="!showButtons" mat-raised-button (click)="onClear()"> Clear all  <mat-icon>cancel</mat-icon></button>
        <button type="button" *ngIf="showButtons" mat-flat-button color="accent" [disabled]="!selectedFiles || selectedFiles.length === 0 || !formGroup.valid || progressInfos.length > 0"
            (click)="uploadFiles()">Upload</button>
        <div fxFlex></div>
        <button type="button" *ngIf="showDone" mat-raised-button color="accent" (click)="done()">Done</button>
    </div>

    <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start stretch">
        <ng-container *ngIf="selectedFiles !== undefined && selectedFiles.length > 0 && progressInfos.length <= 0">
            <div *ngFor="let progressInfo of selectedFiles">
                <h3>
                    {{ progressInfo.name }}
                </h3>
                <mat-progress-bar color="accent" mode="determinate"></mat-progress-bar>
            </div>
        </ng-container>
        
        <div *ngFor="let progressInfo of progressInfos">
            <h3>
                <fa-icon *ngIf="progressInfo.status ==='uploading'" [styles]="{'stroke': 'gray', 'color': 'gray'}"
                    [icon]="['fas', 'circle-arrow-up']">
                </fa-icon>
                <fa-icon *ngIf="progressInfo.status ==='success'" [styles]="{'stroke': 'green', 'color': 'green'}"
                    [icon]="['fas', 'circle-check']"></fa-icon>
                <fa-icon *ngIf="progressInfo.status ==='failure'" [styles]="{'stroke': 'red', 'color': 'red'}"
                    [icon]="['fas', 'circle-exclamation']">
                </fa-icon>
                {{ progressInfo.fileName }}
            </h3>
            <mat-progress-bar *ngIf="progressInfo.status !=='uploading'" color="accent" mode="determinate" value="{{ progressInfo.value }}"></mat-progress-bar>
            <mat-progress-bar *ngIf="progressInfo.status ==='uploading'" color="accent" mode="indeterminate" value="{{ progressInfo.value }}"></mat-progress-bar>
        </div>
    </div>

    <input [hidden]="true" type="file" #fileInput id="fileInput" name="fileInput" (change)="selectFiles($event)" title="upload file upto 5MB"
        multiple accept="*" />
</form>
