import { Offer } from "..";

export interface OfferGroupDomain<TOfferGroup extends Offer> {
    Detail: TOfferGroup;
    EventId?: string;
    EventType?: string;
    OfferTagIds?: string[];
    VersionIds?: string[];
    OfferIds?: string[];
    BadgeAssetId?: string;
    OfferTypeId?: string;
    FileName?: string;
    AssetIds?: string[];
    ProductCategoryIds?: string[]
    
}