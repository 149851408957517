<div
  class="activity-log-outer-container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="8px"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
  fxLayoutGap.lt-md="4px"
  *ngIf="offer$ | async as OfferDetails; else loading"
>
  <!-- First row with Offer Name & Status menu -->
  <div fxLayout="row wrap" fxLayoutAlign="space-between" style="width: 100%">
    <!-- Offer Name -->
    <div class="d-flex">
      <h2>
        {{
          offerDetails.Detail.Name == "" ? "Offer" : offerDetails.Detail.Name
        }}
      </h2>
    </div>

    <!-- Status menu -->
    <div class="d-flex flex-end">
      <div
        class="header-card-actions"
        *ngIf="offerId != '0'"
        style="top: 0; position: relative"
        [ngStyle]="{ top: canEdit() ? '0' : '17px' }"
      >
        <!-- <span
          [matMenuTriggerFor]="canEdit() ? menu : null"
          class="status-btns status {{
            offerDetails.Detail.Status === '0'
              ? 'statusPENDING'
              : 'status' + offerDetails.Detail.Status
          }}"
        >
          {{
            offerDetails.Detail.Status && offerDetails.Detail.Status === "0"
              ? "PENDING"
              : offerDetails.Detail.Status
          }}
          <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon>
        </span> -->
      </div>
      <mat-menu #menu="matMenu" class="status-menu">
        <button
          type="button"
          class="statusAPPROVED tab-status"
          mat-menu-item
          (click)="updateTableStatus(2)"
          [disabled]="offerDetails.Detail.Status === 'APPROVED'"
        >
          APPROVED
        </button>
        <button
          type="button"
          mat-menu-item
          class="statusPENDING tab-status"
          (click)="updateTableStatus(1)"
          [disabled]="offerDetails.Detail.Status === 'PENDING'"
        >
          PENDING
        </button>
        <button
          type="button"
          class="statusSUPPRESSED tab-status"
          mat-menu-item
          (click)="updateTableStatus(3)"
          [disabled]="offerDetails.Detail.Status === 'SUPPRESSED'"
        >
          SUPPRESSED
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- Container with image & activity log -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    class="activity-log-inner-container"
  >
    <div
      class="offer-overview"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="8px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="4px"
    >
      <!-- <div class="offer-product-img-container">
        <img
          mat-card-image
          src="./assets/images/offer-product-placeholder-img.png"
          class="offer-product-img"
        />
      </div>

      <h2>$0.00</h2>
      <h3><b>Headline Goes Here in This Space</b></h3>
      <p>Body copy goes here in this space</p>
    -->

      <div class="offer-product-img-container">
        <ng-container *ngIf="OfferDetails.FacingAsset && OfferDetails.OfferTypeId">
          <img mat-card-md-image
              [src]="utilityService.getOfferPreviewUrl(OfferDetails.FacingAsset, PreviewMode.Medium)"
              alt="Offer image">
      </ng-container>

        <img
          mat-card-md-image
          *ngIf="
            !OfferDetails.FacingAsset?.FileName &&
            (offerId == '0' || OfferDetails.OfferTypeId)
          "
          src="./assets/images/default-image.png"
          alt="Offer image"
        />
      </div>
      <ng-container>
        <div
          *ngIf="OfferDetails?.OfferTypeId"
          [innerHtml]="OfferDetails.PricingPreviewHtml"
        ></div>
      </ng-container>
      <ng-container *ngIf="offerId != '0' && !OfferDetails.OfferTypeId">
        <img
          mat-card-md-image
          src="./assets/images/default-offer-image.png"
          alt="Offer image"
        />
        <div style="text-align: center; padding: 2rem 0">
          Please select an offer type
        </div>
      </ng-container>
    </div>
    <div
      class="offer-details-table"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap.lt-md="4px"
      *ngIf="activityDataSource?.length > 0"
    >
      <table
        mat-table
        [dataSource]="activityDataSource"
        class="mat-elevation-z8"
      >
        <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef>Activity</th>
          <td mat-cell *matCellDef="let element" style="white-space: normal; padding: 10px 0px">
            {{ element.Activity }}
          </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let element">
            {{ element.User }}
          </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Time | date : "MMM d, y, h:mm:ss a" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            style="cursor: pointer; color:#192F41;"
            (click)="viewDetails(i)"
          >
            View
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr>
          <td *ngIf="!activityDataSource || activityDataSource.length == 0">
            <p><b>No Data Found</b></p>
          </td>
        </tr>
      </table>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngIf="!activityDataSource || activityDataSource.length == 0"
      style="width: 100%; padding-top: 1rem"
      class="offer-details-table"
    >
      <p><b>No Activity Logged Yet</b></p>
    </div>
  </div>
</div>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
