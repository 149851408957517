<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <form [formGroup]="form">
    <div formArrayName="versions">
      <div class="grid" *ngIf="viewModel.offers.length">
        <mat-card appearance="outlined" *ngFor="let offers of viewModel.offers; index as i">
          <mat-card-content>
            <span class="rank" *ngIf="offers.Detail.Rank">Rank : {{ offers.Detail.Rank }}</span>
            <div class="offer-status">
              <span *ngIf="canEdit()" [matMenuTriggerFor]="menu" class="ellipsis-3-line {{
                  offers?.Detail?.Status?.toString() === '0'
                    ? 'statusPENDING'
                    : 'status' + offers?.Detail?.Status?.toString()
                }}">{{ offers && offers.Detail && offers.Detail.Status && offers.Detail.Status.toString() === "0" ? "PENDING" : offers.Detail.Status }}
                <mat-icon class="icon">arrow_drop_down</mat-icon>
              </span>

              <span *ngIf="!canEdit()" class="ellipsis-3-line {{
                  offers?.Detail?.Status?.toString() === '0'
                    ? 'statusPENDING'
                    : 'status' + offers?.Detail?.Status?.toString()
                }}">{{
                offers &&
                offers.Detail &&
                offers.Detail.Status &&
                offers.Detail.Status.toString() === "0"
                ? "PENDING"
                : offers.Detail.Status
                }}
              </span>
            </div>
            <mat-menu #menu="matMenu">
              <button type="button" mat-menu-item class="statusAPPROVED" (click)="updateTableStatus(2, offers)">
                APPROVED
              </button>
              <button type="button" mat-menu-item class="statusPENDING" (click)="updateTableStatus(1, offers)">
                PENDING
              </button>
              <button type="button" mat-menu-item class="statusSUPPRESSED" (click)="updateTableStatus(3, offers)">
                SUPPRESSED
              </button>
            </mat-menu>
            <div class="grid-col-span-2 offer-name" title="{{ offers.Detail.Name }}" fxLayout="row wrap"
              fxLayoutAlign="start start" fxLayoutGap="15px">
              <mat-checkbox (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                (change)="$event ? selection.toggle(offers) : null" [checked]="selection.isSelected(offers)">
              </mat-checkbox>
              <!-- <div class="ellipsis-3-line offer-name-detail">{{ offers.Detail.Name }}</div> -->
            </div>
            <div fxLayoutAlign="center center" class="offer-image">
              <!-- <img mat-card-md-image *ngIf="offers.FileName" src="{{viewModel.assetsUrl}}/{{offers.FileName}}" alt="offer image">
                      <img mat-card-md-image *ngIf="!offers.FileName" src="./assets/images/default-image.png" alt="offer image"> -->
                      <ng-container *ngIf="offers.FacingAsset">
                        <img mat-card-md-image [src]="utilityService.getOfferPreviewUrl(offers.FacingAsset, PreviewMode.Medium)" alt="Offer image" (error)="onImageError($event)">
                      </ng-container>
                      <img mat-card-md-image *ngIf="!offers.FacingAsset" src="./assets/images/default-image.png"
                        alt="Offer image" />
            </div>
            <div class="offer-content" fxLayout="column">
              <div class="offer-bodycopy">
                <mat-form-field class="versions" appearance="outline">
                  <mat-select [disabled]="true" [formControlName]="i">
                    <mat-option [value]="offers" title="{{offers.Detail.OfferVariantName}}">
                      {{offers.Detail.OfferVariantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="offer-headline">
                <span [innerHtml]="offers.PricingPreviewHtml"> </span>
              </div>
            </div>
            <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">  
              <span title="{{ offers.Versions }}" class="card-footer-text version-codes" style="
              width: 100%;"> 
                Versions : {{ offers.Versions }}</span>
              <div class="card-footer-text">

                <span class="footer-text">{{ offers.EventType }}</span>
                <span class="footer-text display">Client Key:
                  <span title="{{ offers.Detail.ClientKey }}">{{
                    offers.Detail.ClientKey
                    }}</span></span>
              </div>
              <div class="card-footer-btns">
                <button class="offer-button" type="button" mat-button color="accent"
                (click)="onEdit(offers.Detail.Id)">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button>
                <!-- <button *ngIf="offers.EventType === 'PROMO'" class="offer-button" type="button" mat-button color="accent"
                  [routerLink]="[offers.Detail.Id, 'promos', 'promodetails']">
                  <span *ngIf="canEdit()">Edit</span>
                  <span *ngIf="!canEdit()">View Details</span>
                </button> -->
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>

  <!-- <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator> -->

  <table *ngIf="!viewModel.offers.length">
    <tr>
      <td class="no-records">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
