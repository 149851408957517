import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { map } from 'rxjs';
import { OfferTag, OfferTagService } from 'src/app/modules/standard/v1';
import { Logger } from '../../../../../@shared/services/logger.service';
const log = new Logger('Pr1OfferTagsComponent');

@Component({
  selector: 'app-pr1-offer-tags',
  templateUrl: './pr1-offer-tags.component.html',
  styleUrls: ['./pr1-offer-tags.component.scss'],
})

export class Pr1OfferTagsComponent<Tag extends OfferTag> implements DoCheck {
  @Input() selectedTags: any[] = []; // Holds the list of currently selected tags
  @Input() canEditTags: boolean = true; // Controls whether tags can be added or removed
  @Input() maxTagsLength: number = 0; // Max number of tags allowed; input is optional
  @Input() context: string = '';
  @Input() contextId: string = '';
  @Input() offerTagsFormControl!: FormControl; // FormControl passed from the parent for validation
  @Input() label!: string; // Label text for the field
  @Input() placeholder!: string; // Placeholder text for the input field

  // Output events to communicate tag changes (added, removed, or selected) back to the parent component
  @Output() tagRemoved = new EventEmitter<any>();
  @Output() tagAdded = new EventEmitter<any>();
  @Output() tagSelectedEmitter = new EventEmitter<any>();

  offerTagsControl = new FormControl('');
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  // Observable to hold the filtered list of offer tags excluding already selected ones
  filteredOfferTagsList$ = this.offerTagService.filteredOfferTagsList$.pipe(
    map((tags) => {
      // If tags are empty, clear the form control
      if (!tags || tags.length === 0) {
        return [];
      }
      // Filter out tags that have already been selected
      return tags.filter((tag) => {
        const index = this.selectedTags.findIndex((_tag) => _tag.Id === tag.Id);
        return index < 0;
      });
    })
  );
  formErrors: any;

  constructor(private offerTagService: OfferTagService<Tag>) { }

  ngDoCheck(): void {
    if (this.offerTagsFormControl.errors) {
      this.formErrors = this.offerTagsFormControl.errors;
    } else {
      this.formErrors = null;
    }
  }
  // Method to remove a selected tag, emits event to parent
  removeTag(tag: any) {
    this.tagRemoved.emit(tag);
  }

  // Method to add a tag, triggers when the user presses enter or selects from the list
  addTag(event: any) {
    if ((this.maxTagsLength && this.selectedTags.length >= this.maxTagsLength) || this.formErrors?.maxlength) return
    this.tagAdded.emit(event);
    this.offerTagsControl.setValue(null);
    this.tagInput.nativeElement.value = '';
    this.offerTagService.filteredOfferTagsOnSearch('');
  }

  // Method to handle the selection of a tag from the autocomplete dropdown
  tagSelected(event: any) {
    this.tagSelectedEmitter.emit(event as MatChipInputEvent);
    this.offerTagsControl.setValue(null);
    this.tagInput.nativeElement.value = '';
    this.offerTagService.filteredOfferTagsOnSearch('');
  }

  // Method to check if tags are editable
  canEdit() {
    return this.canEditTags;
  }

  // Method to search for tags based on user input
  onSearchTag(event: any) {
    this.offerTagService.filteredOfferTagsOnSearch(event.target.value);
  }
}
