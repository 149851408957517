<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <!-- page title -->
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
        <h1 class="mt-18">Coupon Management</h1>
        <div fxLayout="row" class="">
            <mat-button-toggle-group #status="matButtonToggleGroup" [value]="filtersForm.controls.Status.value" (change)="toggleStatusFilter(status.value)"
                class="multiple-toggle-btns arrows">
                <mat-button-toggle title="View All Approved" value="Approved" class="nextButtons statusAPPROVED"><fa-icon
                        *ngIf="isSelected(viewModel, 'Approved')" [icon]="['fas', 'check']"></fa-icon>
                    <div class="number-text"><span class="number">{{statusCount.Approved}}</span> <br />Approved</div>
                </mat-button-toggle>
                <mat-button-toggle title="View All Pending" value="Pending" class="nextButtons statusPENDING"><fa-icon
                        *ngIf="isSelected(viewModel, 'Pending')" [icon]="['fas', 'check']"></fa-icon>
                    <div class="number-text"><span class="number">{{statusCount.Pending}}</span> <br /> Pending</div>
                </mat-button-toggle>
                <mat-button-toggle title="View All Suppressed" value="Suppressed" class="nextButtons statusSUPPRESSED"><fa-icon
                        *ngIf="isSelected(viewModel, 'Suppressed')" [icon]="['fas', 'check']"></fa-icon>
                    <div class="number-text"><span class="number">{{statusCount.Suppressed}}</span> <br /> Suppressed</div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent" matTooltip="Add new record"
                [routerLink]="[0, 'details']">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Coupon
            </button>

            <button type="button"
            *ngIf="(viewModel.viewMode === 'TABLE' && couponListTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && couponListCards?.getSelectedSectionRecords()?.length > 0)"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item
                *ngIf="(viewModel.viewMode === 'TABLE' && couponListTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && couponListCards?.getSelectedSectionRecords()?.length > 0)">
                {{ viewModel.viewMode === 'TABLE' ? couponListTable?.getSelectedSectionRecords()?.length : couponListCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
                <button type="button" mat-menu-item appAuthorizeControl="edit||offermang"
                    (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item [matMenuTriggerFor]="Select">Status</button>
            </mat-menu>
            <mat-menu #Select="matMenu">
                <button mat-menu-item class="statusPending" (click)="updateBulkStatus('Pending')">Pending</button>
                <button mat-menu-item class="statusApproved" (click)="updateBulkStatus('Approved')">Approve</button>
                <button mat-menu-item class="statusSuppressed" (click)="updateBulkStatus('Suppressed')">Suppress</button>
            </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Coupons</mat-label>
                <input matInput formControlName="Search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>

            <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

            <!--only display the sort by dropdown for the cards view(table view sorts on columns)-->
            <button type="button" mat-raised-button matTooltip="Sort" (click)="onSort()">
                <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>Sort
            </button>

            <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
            </button>
        </div>

        <mat-sidenav-container>
            <!--filter sidenav-->
            <mat-sidenav #filterSidenav mode="over" position="end" class="pr1-filter-mat-sidenav">
                <mat-toolbar>
                    Filter
                    <span class="menu-spacer"></span>
                    <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                        (click)="filterSidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
                <div style="margin: 16px">
                    <div fxLayout="column">

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Redemption start Date</mat-label>
                                <input matInput [matDatepicker]="RdemptionStartDatePicker" formControlName="start" (dateChange)="onFilterChange('start')">
                                <mat-datepicker-toggle matSuffix [for]="RdemptionStartDatePicker" ></mat-datepicker-toggle>
                                <mat-datepicker #RdemptionStartDatePicker ></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Expiry Date</mat-label>
                                <input matInput [matDatepicker]="ExpiryDatePicker" formControlName="Expiry" (dateChange)="onFilterChange('end')">
                                <mat-datepicker-toggle matSuffix [for]="ExpiryDatePicker" ></mat-datepicker-toggle>
                                <mat-datepicker #ExpiryDatePicker ></mat-datepicker>
                            </mat-form-field>

                            <!-- <div  style="position: relative; margin-right: 16px;">
                                <span [matMenuTriggerFor]="statusmenu" class="status {{filtersForm.controls.Status.value || 'status-blank'}}">
                                    {{filtersForm.controls.Status.value || 'Status' }} <mat-icon  class="icon">arrow_drop_down</mat-icon> </span>                        
                            </div>
                            <mat-menu #statusmenu="matMenu">
                                <button type="button" class="statusApproved tab-status" mat-menu-item (click)="updateStatus('Approved')">Approved</button>
                                <button type="button" mat-menu-item class="statusPending tab-status" (click)="updateStatus('Pending')">Pending</button>                    
                                <button type="button" class="statusSuppressed tab-status" mat-menu-item (click)="updateStatus('Suppressed')">Suppressed</button>
                              </mat-menu> -->

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                        <button type="reset" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                            Reset
                        </button>
                        <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                            (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                            Apply
                        </button>
                    </div>
                </div>

            </mat-sidenav>

            <!--filter chips -->
            <div class="filter-chips" *ngIf="viewModel.filters && viewModel.filters.length > 0" fxLayout="row">
                <mat-chip-listbox aria-label="Selected filters">
                    <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
                        {{filter.displayText}}
                        <button type="button" matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-option>
                </mat-chip-listbox>
                <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
                    Clear all
                </button>
            </div>

            <app-coupons-list-cards *ngIf="viewModel?.viewMode === 'CARDS'" #couponListCards></app-coupons-list-cards>
            <app-coupon-list-table *ngIf="viewModel?.viewMode === 'TABLE'" #couponListTable></app-coupon-list-table>

        </mat-sidenav-container>

    </form>
    
</ng-container>

<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </ng-template>