<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <mat-checkbox *ngIf="viewModel && viewModel.offers && viewModel.offers.length > 0"
        (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()">Select All</mat-checkbox>
    <div class="grid" *ngIf="viewModel.offers.length">
        <mat-card appearance="outlined" *ngFor="let offer of viewModel.offers; index as i">
            <mat-card-content>
                <span class="rank" *ngIf="offer.Detail.Rank">Rank : {{ offer.Detail.Rank }}</span>
                <div class="offer-status">
                    <span class="ellipsis-3-line {{
                  offer &&
                  offer.Detail &&
                  offer.Detail.Status &&
                  offer.Detail.Status.toString() === '0'
                      ? 'statusPENDING'
                      : 'status' + offer.Detail.Status?.toString()
                  }}">{{
                        offer &&
                        offer.Detail.Status &&
                        offer.Detail.Status.toString() === "0"
                        ? "PENDING"
                        : offer.Detail.Status
                        }}
                    </span>
                </div>
                <div class="grid-col-span-2 offer-name" title="{{ offer.Detail.Name }}" fxLayout="row wrap"
                    fxLayoutAlign="start start" fxLayoutGap="15px">
                    <mat-checkbox (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                        (change)="$event ? selection.toggle(offer) : null" [checked]="selection.isSelected(offer)">
                    </mat-checkbox>
                    <div class="ellipsis-3-line offer-name-detail">{{ offer.Detail.Name }}</div>
                </div>
                <div fxLayoutAlign="center center" class="offer-image">
                    <ng-container *ngIf="offer.FacingAsset?.FileName">
                        <img mat-card-md-image [src]="getImageSrc(offer)" alt="Offer image" />
                    </ng-container>
                    <img mat-card-md-image *ngIf="!offer.FacingAsset?.FileName" src="./assets/images/default-image.png"
                        alt="Offer image" />
                </div>
                <div class="card-footer grid-col-span-2" fxLayout="row wrap" fxLayoutGap="15px">
                    <div class="card-footer-text">
                        <span class="footer-text">{{ offer.EventType }}</span>
                        <span class="footer-text display">Client Key:
                            <span title="{{ offer.Detail.ClientKey }}">{{
                                offer.Detail.ClientKey
                                }}</span></span>

                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

    <table *ngIf="!viewModel.offers.length">
        <tr>
            <td class="no-records">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>