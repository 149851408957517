import { Routes } from "@angular/router";
import { NotificationsComponent } from "pr1-ui-components";
 
// All common routes for the application are defined here

export const notificationsRoutes: Routes = [
     {
        path: 'notifications',
        children: [
          {
            path: '',
            component: NotificationsComponent,
            data: { title: 'Notifications', breadcrumb: 'Notifications' },
          }
        ],
      },  
 ];


export const commonRoutes: Routes = [
    ...notificationsRoutes
]
    
