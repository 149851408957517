import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ApiValidation, ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { EventService, Version, VersionService, Event, OfferPromoService, OfferPromo, PromoDomain, Tag, OfferTagService, OfferTag, OfferTagsResponseModel } from '../..';
import { FormControl, Validators } from '@angular/forms';
import { Observable, combineLatest, last, map, tap } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthorizeControlService } from 'pr1-ui-components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';


const log = new Logger('EditOfferTagsComponent');

interface DialogData {
  selectedRecordsDetails: any;
  eventId: string;
}

@Component({
  selector: 'app-edit-offer-tags',
  templateUrl: './edit-offer-tags.component.html',
  styleUrls: ['./edit-offer-tags.component.scss']
})

export class EditOfferTagsComponent<Tag extends OfferTag, TEvent extends Event, TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {
  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
  ]);
  readOnlyMode = false;
  offerTagsResponse: OfferTagsResponseModel[] = [];
  removeTags = false;
  OfferTagIds: Tag[] = [];
  offerexists = false;
  offerTagsHistory: OfferTagsResponseModel[] = [];
  private offerTagsList: Tag[] = [];

  constructor(private offerTagService: OfferTagService<Tag>,
    private authorizationService: AuthorizeControlService,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    public dialogRef: MatDialogRef<EditOfferTagsComponent<OfferTag, Event, OfferPromo, TPromoDomain>>,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.getTagsforOfferPromos();
  }

  getTagsforOfferPromos() {
    this.offerTagsHistory = [];
    let selectedRecords: any = [];
    for (let index = 0; index <= this.data.selectedRecordsDetails.length - 1; index++) {
      const activeVersion = this.data.selectedRecordsDetails[index].OfferVariants.find(
        (x: any) => x.activeItem == true
      );
      if (
        !activeVersion ||
        (activeVersion &&
          activeVersion.Id === this.data.selectedRecordsDetails[index].DomainId)
      ) {
        const recordstoupdate = this.data.selectedRecordsDetails[index].OfferVariants.map((x: any) => x.Id);
        selectedRecords = [...selectedRecords, ...recordstoupdate];
      } else if (
        activeVersion &&
        activeVersion.Id != this.data.selectedRecordsDetails[index].DomainId
      ) {
        const x = activeVersion;
        selectedRecords.push(x.Id);
      }
    }
    this.offerpromoService.getOfferPromoTags(this.data.eventId, selectedRecords).subscribe(((res: any) => {
      this.offerTagsResponse = res as any;
      if (this.offerTagsResponse && this.offerTagsResponse.length > 0) {
        this.offerTagsResponse.map((i: any) => {
          if (i.TagId)
            this.offerexists = true;
        });
      }
    }))
  }



  getDisplayValue(name: string | undefined) {
    if (name && name.toString().length > 20) {
      return name.substring(0, 20) + '...' + '; ';
    } else {
      return name + '; ';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


  tagSelected(event: MatAutocompleteSelectedEvent): void {
    if (
      this.OfferTagIds &&
      this.OfferTagIds.length > 0 &&
      this.OfferTagIds.includes(event.option.value)
    ) {
      return;
    }
    this.OfferTagIds.push(event.option.value);
    this.tagCtrl.setValue('');
  }

  addTag(event: MatChipInputEvent): void {
    event.chipInput!.clear();
    const value = (event.value || '').trim();
    let valid = this.tagCtrl.status !== 'INVALID';

    // Add Tag
    if (value && valid) {
      let tag = <Tag>{
        ClientKey: value,
        OfferTagName: value,
      };

      this.saveTag(tag);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue('');
  }

  saveTag(tag: Tag) {
    this.offerTagService.saveOfferTag(tag as Tag).subscribe({
      next: (response) => {
        this.OfferTagIds.push(response as Tag);
        this.matSnackBar.open(
          `${tag.OfferTagName} saved`,
          'OK',
          DEFAULT_SNACKBAR_CONFIG
        );
        this.offerTagService.reload();
      },
      error: (error) => {
        if (error.status === 500) {
          log.error('500 Error saving event', error);
        }
        if (error.status === 400) {
          const apiValidations: ApiValidation[] = error.error;

          apiValidations.forEach((validation) => {
            // if (this.formGroup.get(validation.PropertyName)) {
            //   const control = this.formGroup.get(validation.PropertyName);
            //   if (control) {
            //     control.markAsTouched();
            //     control.setErrors({ invalid: validation.ErrorMessage });
            //   }
            // } else {

            // }
          });
        }
      },
    });
  }

  removeTag(tag: Tag): void {
    const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);

    if (index >= 0) {
      this.OfferTagIds.splice(index, 1);
    }
  }

  undoTags() {
    if (this.offerTagsHistory && this.offerTagsHistory.length > 0) {
      if (this.removeTags) {
        this.offerTagsResponse = this.offerTagsHistory;
        this.offerTagsHistory = [];
        this.removeTags = false;
      } else {
        const lastItem = this.offerTagsHistory[this.offerTagsHistory.length - 1];
        if (lastItem) {
          const removedItem = this.offerTagsResponse.find((x: any) => x.TagName == lastItem.TagName && !x.TagId);
          if (removedItem) {
            removedItem.TagId = lastItem.TagId;
          }
          this.offerTagsHistory = this.offerTagsHistory.filter((x: any) => x.TagId !== lastItem.TagId);
        }
      }

    }
  }

  removeOfferTags(offertag: OfferTagsResponseModel) {
    const index = this.offerTagsResponse.findIndex((_tag) => _tag.TagId == offertag.TagId);
    this.offerTagsHistory.push(JSON.parse(JSON.stringify(this.offerTagsResponse[index])));
    if (index >= 0) {
      this.offerTagsResponse[index].TagId = '';
    }
  }

  removeAllTags() {
    if (this.offerTagsResponse && this.offerTagsResponse.length > 0) {
      if (this.offerTagsHistory && this.offerTagsHistory.length > 0) {
        const removedItems = this.offerTagsResponse.filter(item => item.TagId);
        this.offerTagsHistory = JSON.parse(JSON.stringify([...this.offerTagsHistory, ...removedItems]));
        this.offerTagsResponse.forEach((x: any) => x.TagId = '');
        this.removeTags = true;
      } else {
        this.offerTagsHistory = JSON.parse(JSON.stringify(this.offerTagsResponse));
        this.offerTagsResponse.forEach((x: any) => x.TagId = '');
        this.removeTags = true;
      }

    }
  }


  saveOfferTags() {
    const datatoSave = {
      ExistingOfferTags: this.offerTagsResponse,
      TagIdsToAdd: this.OfferTagIds.map((tag) => tag.Id)
    }
    this.offerpromoService.massSaveofferTags(datatoSave).subscribe(res => {
      this.matSnackBar.open(
        `Offer Tags Saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
      );
      this.offerpromoService.reload();
      this.dialogRef.close();
    });
  }

}
