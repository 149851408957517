import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeControlService } from 'pr1-ui-components';

@Component({
  selector: 'app-offer-assets',
  templateUrl: './offer-assets.component.html',
  styleUrls: ['./offer-assets.component.scss'],
})

export class OfferAssetsComponent implements OnInit {
  offerId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizeControlService
  ) { }

  ngOnInit(): void {
    this.offerId = this.route.snapshot.params['offerId'];
  }

  cancel(): void {
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang');
  }
}
