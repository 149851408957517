<mat-card appearance="outlined" class="card">
    <mat-card-header>
        <mat-card-title>{{data.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="assetType == 'audio'">
            <ng-container *ngIf="assetExtenstion == 'ogg'; then oggType; else others"></ng-container>
            <ng-template #others>
                <audio controls>
                    <source src={{assetUrl}} type={{data.type}}>
                    <source src={{assetUrl}} type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
            </ng-template>
            <ng-template #oggType>
                <video width="100%" controls>
                    <source src={{assetUrl}} type={{data.type}}>
                    <source src={{assetUrl}} type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="assetType == 'video'">
            <video width="100%" controls autoplay [muted]="true" playsinline preload="auto" crossorigin="anonymous">
                <source src={{assetUrl}} type={{data.type}}>
                <source src={{assetUrl}} type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </ng-container>

        <ng-container *ngIf="assetType == 'x-zip-compressed'">
            <video width="100%" controls autoplay [muted]="true" playsinline preload="auto" crossorigin="anonymous">
                <source src={{assetUrl}} type="video/mp4">
                <track kind="captions" srclang="en" src="{{data.videoCaptionsUrl}}" default />
                Your browser does not support the video tag.
            </video>
        </ng-container>

        <ng-container *ngIf="assetType == 'image'">
            <img mat-card-image src={{assetUrl}} alt={{data.name}} />
        </ng-container>

        <ng-container *ngIf="!assetType">
            <p>Asset preview unavailable.</p>
        </ng-container>

    </mat-card-content>
    <mat-card-actions>
        <div fxLayout="row" [fxLayoutAlign]="'end'">
            <button mat-stroked-button type="button" (click)="closeDialog()">Close</button>
        </div>
    </mat-card-actions>
</mat-card>
