import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizeControlService } from 'pr1-ui-components';

@Component({
  selector: 'app-event-asset-list',
  templateUrl: './event-asset-list.component.html',
  styleUrls: ['./event-asset-list.component.scss']
})

export class EventAssetListComponent implements OnInit {
  eventId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizeControlService
  ) { }

  ngOnInit(): void {
    this.eventId = this.route.snapshot.params['eventId'];
  }

  cancel(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang');
  }

}
