import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetsListService, AzureSearchConfig, AzureSearchService, AzureServiceType, CheckboxDataType, Config, DropdownDataType, DropdownFacet, DropdownFacetItem, FacetSortingMode, RangeDataType, RangeFacet } from '../..';
import { PageEvent } from '@angular/material/paginator';
import { OrganizationService, PreviewMode, UtilityService } from 'src/app/@shared';
import { distinctUntilChanged, lastValueFrom, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatTableDataSource } from '@angular/material/table';

export interface AssetModelData {
  searchPlaceholder: string,
  searchQuery: string,
  pageSize: number,
  assetsIds: string[],
  viewMode: string,
}

export interface SearchAsset {
  assetId: string;
  assetClientKey: string;
  assetName: string;
  fileName: string;
  originalFileName: string;
  webUrl: string;
  printUrl: string;
  fileType: string;
  assetType: string;
  dateAdded: string;
  colorChannel: string;
  assetGroupId: string;
  assetGroupName: string;
  assetGroupClientKey: string;
  dateCreated: string;
  expiryDate: string;
}

interface ChipsFacetConfig {
  htmlId: string;
}

interface ChipFacetConfig {
  htmlId: string;
}

interface CheckboxFacetConfig {
  htmlId: string;
  fieldName: string;
  fieldTitle: string;
  dataType: CheckboxDataType;
  count?: number;
  sort?: FacetSortingMode;
}

interface DropdownFacetConfig {
  htmlId: string;
  fieldName: string;
  fieldTitle: string;
  dataType: DropdownDataType;
  count?: number;
  sort?: FacetSortingMode;
}

interface RangeFacetConfig {
  htmlId: string;
  fieldName: string;
  fieldTitle: string;
  dataType: RangeDataType;
  minValue: number;
  maxValue: number;
}

type FacetConfig =
  | { type: 'chip'; config: ChipFacetConfig }
  | { type: 'checkbox'; config: CheckboxFacetConfig }
  | { type: 'dropdown'; config: DropdownFacetConfig }
  | { type: 'range'; config: RangeFacetConfig };

const DEFAULT_SEARCH_PLACEHOLDER = 'Search Assets';
const DEFAULT_ITEMS_PER_PAGE = 50;
export enum ViewMode {
  Table = "table",
  Card = "card",
}

@Component({
  selector: 'app-assets-search-dialog',
  templateUrl: './assets-search-dialog.component.html',
  styleUrls: ['./assets-search-dialog.component.scss'],
})
export class AssetsSearchDialogComponent {
  @Input() assetsIds: string[] = [];
  @Input() searchPlaceholder: string = DEFAULT_SEARCH_PLACEHOLDER;
  @Output() onSelection: EventEmitter<string[]> = new EventEmitter();

  azureSearchService: AzureSearchService | null = null;

  modelData!: AssetModelData;
  ViewMode = ViewMode;
  PreviewMode = PreviewMode;
  defaultViewMode = ViewMode.Table;
  dataSource = new MatTableDataSource<any>([]);

  pageIndex: number = 0;
  pageLength: number = 0; // Total number of results
  @Input() pageSize: number = DEFAULT_ITEMS_PER_PAGE; // Default page size
  pageSizeOptions = [5, 10, 20, 50, 100, 500];

  pageEvent: PageEvent = new PageEvent;

  orderby: string = 'dateAddedEPOCH';
  sortDirection: string = 'desc';
  disabled: boolean = false;
  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  showFirstLastButtons: boolean = true;

  // assetTypesFacet!: DropdownFacet;
  // assetTypes: DropdownFacetItem[] = [];
  // selectedAssetType: DropdownFacetItem[] = [];

  colorChannelsFacet!: DropdownFacet;
  colorChannels: DropdownFacetItem[] = [];
  selectedColorChannels: DropdownFacetItem[] = [];

  fileTypesFacet!: DropdownFacet;
  fileTypes: DropdownFacetItem[] = [];
  selectedFileTypes: DropdownFacetItem[] = [];

  rangeInvalid = false;
  assetDateRangeFacet!: RangeFacet;
  uploadStartDate: Date | null = null;
  uploadEndDate: Date | null = null;

  @Input() searchQuery: string = '';
  searchQueryVal: string = '';
  searchResults: SearchAsset[] = [];
  searchResultsCount: number = 0;
  showResults: boolean = false;
  isSelectionChanged: boolean = false;

  selection = new SelectionModel<any>(true, [], true, (a1, a2) => a1.assetId === a2.assetId);
  displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded'];
  flexMediaWatcher!: Subscription;

  constructor(
    private mediaObserver: MediaObserver,
    public utilityService: UtilityService,
    private assetsListService: AssetsListService,
    private organizationService: OrganizationService,
    private dialogRef: MatDialogRef<AssetsSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssetModelData
  ) {
    this.modelData = data;
  }

  async ngOnInit() {
    this.detectViewportSizeChange();
    await this.fetchAzureSearchMetaData();

    if (this.organizationService.azureSearchMetaData) {
      let config: Config = {
        index: "",
        queryKey: "",
        service: "",
        type: AzureServiceType.Search,
        dateToValidate: null
      }

      if (this.organizationService.azureSearchMetaData) {
        const { SearchIndexName: index, QueryKey: queryKey, SearchEndPoint: service } = this.organizationService.azureSearchMetaData;
        config = { ...config, index, queryKey, service };
      }

      this.pageSize = this.modelData.pageSize ?? this.pageSize;
      this.searchQuery = this.modelData.searchQuery ?? this.searchQuery;
      this.searchPlaceholder = this.modelData.searchPlaceholder ?? this.searchPlaceholder;

      if (this.modelData.viewMode === ViewMode.Card || this.modelData.viewMode === ViewMode.Table) {
        this.defaultViewMode = this.modelData.viewMode;
      }

      this.azureSearchService = new AzureSearchService(config);
      this.azureSearchService.updateSearchParameters({ count: true, searchMode: 'all', "top": this.pageSize });
      this.azureSearchService.updateSuggestionsParameters({ suggesterName: "sg", select: "assetId,assetClientKey" });
      this.azureSearchService.setResultsProcessor(this.searchResultCB.bind(this));
      this.azureSearchService.renderDropdownFacetsCB = this.renderDropdownFacetsCB.bind(this);
      this.azureSearchService.renderDateRangeFacetsCB = this.renderDateRangeFacetsCB.bind(this);

      if (this.azureSearchService) {
        this.initFacets();
      }

      this.assetsIds = Array.isArray(this.modelData.assetsIds) ? this.modelData.assetsIds : [];
      this.onSearch();
    } else {
      console.error('Error: azureSearchMetaData', this.organizationService.azureSearchMetaData)
    }

    this.selection.changed.subscribe(changeObj => {
      var selectedAssetsIds = changeObj.source.selected.map(asset => asset.assetId);
      this.isSelectionChanged = selectedAssetsIds.length ? !this.areAssetIdsEqual(selectedAssetsIds, this.assetsIds) : false;
    })
  }

  async fetchAzureSearchMetaData() {
    if (!this.organizationService.azureSearchMetaData) {
      try {
        const metaData: AzureSearchConfig = await lastValueFrom(this.assetsListService.GetSearchMetaData());
        this.organizationService.azureSearchMetaData = metaData;
      } catch (error) {
        console.error('An unknown error occurred:', error);
      }
    }
  }

  detectViewportSizeChange() {
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Image', 'AssetName'];
        } else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded'];
        } else {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded'];
        }
      });
  }

  onFiltersReset() {
    // this.selectedAssetType = [];
    this.selectedColorChannels = [];
    this.selectedFileTypes = [];
    this.uploadStartDate = null;
    this.uploadEndDate = null;

    this.onFiltersApply();
  }

  onFiltersApply() {
    let filters = {
      // assetType: this.selectedAssetType,
      colorChannel: this.selectedColorChannels,
      fileType: this.selectedFileTypes,
      startDate: this.formattedDatetoEpoch(this.uploadStartDate),
      endDate: this.formattedDatetoEpoch(this.uploadEndDate)
    }

    if (!filters.startDate) this.uploadStartDate = null;
    if (!filters.endDate) this.uploadEndDate = null;

    this.rangeInvalid = false;

    if (filters.startDate && filters.endDate && filters.startDate > filters.endDate) {
      this.rangeInvalid = true;
      return
    }

    this.updateSelectedItems(this.fileTypesFacet, this.selectedFileTypes);
    // this.updateSelectedItems(this.assetTypesFacet, this.selectedAssetType);
    this.updateSelectedItems(this.colorChannelsFacet, this.selectedColorChannels);
    this.updateDateRange(this.assetDateRangeFacet, filters.startDate, filters.endDate);

    this.pageIndex = 0;
    this.updateSearchParameters();
    this.onSearch();
  }

  updateSelectedItems(facet: DropdownFacet, selectedItems: DropdownFacetItem[]) {
    if (!facet) return;

    facet.filterClause = '';

    Object.keys(facet.values).forEach(key => {
      facet.values[key].selected = false;
    });

    selectedItems.forEach(item => {
      let facetItem = facet.values[item.value];
      if (typeof facetItem !== 'undefined') {
        facetItem.selected = true;
      }
    });
    this.azureSearchService?.buildDropdownFilter(facet);
  }

  updateDateRange(facet: RangeFacet, startDate: number | null, endDate: number | null) {
    if (!facet) return;
    facet.filterLowerBound = startDate ?? 0;
    facet.filterUpperBound = endDate ?? 0;
    facet.filterClause = '';
    this.azureSearchService?.buildDateRangeFilter(facet);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  toggleView(mode: ViewMode) {
    this.defaultViewMode = mode;
  }

  compareWithIdFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  formattedDatetoEpoch(dateObj: Date | null): number | null {
    if (!dateObj) return null;
    const dateString = new Date(dateObj).toLocaleDateString();
    const [month, day, year] = dateString.split('/').map(Number);
    // Format the date as "YYYY-MM-DDZ00:00:00"
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}Z00:00:00`;
    var epochDate = new Date(formattedDate);
    var epochVal = epochDate.getTime() / 1000.0;

    return epochVal > 0 ? epochVal : null;
  }

  areAssetIdsEqual(selectedAssetsIds: string[], assetsIds: string[]): boolean {
    if (selectedAssetsIds.length !== assetsIds.length) {
      return false;
    }

    const sortedSelectedIds = [...selectedAssetsIds].sort();
    const sortedAssetsIds = [...assetsIds].sort();

    return sortedSelectedIds.every((id, index) => id === sortedAssetsIds[index]);
  }

  onSelect() {
    const assetIds: string[] = this.selection.selected.map(asset => asset.assetId);
    const filteredAssetsIds = assetIds.filter(id => !this.assetsIds.includes(id));
    this.onSelection.emit(filteredAssetsIds);
    this.closeDialog();
  }

  initFacets() {
    let facets: FacetConfig[] = [
      // {
      //   "type": "dropdown",
      //   "config": {
      //     "htmlId": "assetTypeFacet",
      //     "fieldName": "assetType",
      //     "fieldTitle": "Asset Type",
      //     "dataType": "string"
      //   }
      // },
      {
        "type": "dropdown",
        "config": {
          "htmlId": "colorChannelFacet",
          "fieldName": "colorChannel",
          "fieldTitle": "Color Channel",
          "dataType": "string"
        }
      },
      {
        "type": "dropdown",
        "config": {
          "htmlId": "fileTypeFacet",
          "fieldName": "fileType",
          "fieldTitle": "File Type",
          "dataType": "string"
        }
      },
      {
        "type": "range",
        "config": {
          "htmlId": "",
          "fieldName": "dateAddedEPOCH",
          "fieldTitle": "",
          "dataType": "number",
          "minValue": 0,
          "maxValue": 0,
        }
      }
    ];
    let facetViewMode = "all";
    let facetViewCount = 5;
    let facetSearchEnabled = false;
    let facetIgnoreList = [];

    if (this.azureSearchService) {
      this.azureSearchService.facetSearchEnabled = facetSearchEnabled;
    }

    facets.forEach(facet => {
      if (facet.type === 'dropdown') {
        const { htmlId, fieldName, fieldTitle, dataType, count, sort } = facet.config;
        this.azureSearchService?.addDropdownFacet(htmlId, fieldName, fieldTitle, dataType, count, sort);
      }

      if (facet.type === 'range') {
        const { htmlId, fieldName, fieldTitle, dataType, minValue, maxValue } = facet.config;
        this.azureSearchService?.addDateRangeFacet(htmlId, fieldName, fieldTitle, dataType, minValue, maxValue);
      }
    });

  }

  onSuggest(): void {
    if (!this.azureSearchService) return
  }

  onSearch(params: any = null, sorting: boolean = false): void {
    if (this.azureSearchService) {
      const searchQuery = this.searchQuery.trim();

      if (typeof searchQuery === "string") {
        !sorting && this.resetSearchValues();
        this.searchQueryVal = searchQuery;
        this.azureSearchService.setInput(searchQuery);
        this.updateSearchParameters();
        this.azureSearchService.search(params || {});
      }
    }
  }

  renderDropdownFacetsCB(facet: any) {
    let values: DropdownFacetItem[] = Object.values(facet.values);
    switch (facet.key) {
      // case "assetType":
      //   this.assetTypesFacet = facet;
      //   this.assetTypes.forEach(item => {
      //     let index = values.findIndex(_item => _item.value == item.value);
      //     if (index > -1) {
      //       values[index].selected = item.selected;
      //     }
      //   })
      //   this.assetTypes = values;
      //   break;
      case "colorChannel":
        this.colorChannelsFacet = facet;
        this.colorChannels.forEach(item => {
          let index = values.findIndex(_item => _item.value == item.value);
          if (index > -1) {
            values[index].selected = item.selected;
          }
        })
        this.colorChannels = values;
        break;
      case "fileType":
        this.fileTypesFacet = facet;
        this.fileTypes.forEach(item => {
          let index = values.findIndex(_item => _item.value == item.value);
          if (index > -1) {
            values[index].selected = item.selected;
          }
        })
        this.fileTypes = values;
        break;

      default:
        break;
    }
  }

  renderDateRangeFacetsCB(facet: any) {
    switch (facet.key) {
      case "dateAddedEPOCH":
        this.assetDateRangeFacet = facet;
        break;

      default:
        break;
    }
  }

  searchResultCB(data: any) {
    this.showResults = true;
    this.searchResults = data.results;
    this.searchResultsCount = data.count;
    this.pageLength = data.count;
    this.dataSource.data = this.searchResults;

    this.searchResults.forEach((asset) => {
      if (this.assetsIds.includes(asset.assetId)) {
        this.selection.select(asset);
      }
    });
  }

  clearSearch(searchInput: HTMLInputElement): void {
    searchInput.value = '';
    this.searchQuery = '';
    this.onSearch();
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateSearchParameters();
    this.onSearch();
  }

  updateSearchParameters(): void {
    const skip = this.pageIndex * this.pageSize;
    var searchParameters = {
      top: this.pageSize,
      skip: skip,
      orderby: `${this.orderby} ${this.sortDirection}`
    }

    this.azureSearchService?.updateSearchParameters(searchParameters);
  }

  resetSearchValues() {
    this.showResults = false;
    this.searchResults = [];
    this.searchResultsCount = 0;
    this.orderby = 'dateAddedEPOCH';
    this.sortDirection = 'desc';
  }

  onSort(sortState: Sort): void {
    switch (sortState.active) {
      case 'AssetName':
        this.orderby = 'assetName';
        break;
      case 'DateAdded':
        this.orderby = 'dateAddedEPOCH';
        break;
      case 'Group':
        this.orderby = 'assetGroupName';
        break;
      case 'Type':
        this.orderby = 'fileType';
        break;
      default:
        this.orderby = 'dateAddedEPOCH';
        break;
    }

    this.pageIndex = 0;
    this.sortDirection = sortState.direction;
    this.updateSearchParameters();
    this.onSearch(null, true);
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isDisabled(asset: SearchAsset): boolean {
    return this.assetsIds.includes(asset.assetId) ? true : false
  }

  toggleSelect(checked: boolean, asset: any) {
    if (checked) {
      if (!this.selection.isSelected(asset)) {
        this.selection.select(asset);
      }
    } else {
      this.selection.deselect(asset);
    }
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.searchResults.forEach(asset => {
        if (!this.selection.isSelected(asset) && !this.assetsIds.includes(asset.assetId)) {
          this.selection.select(asset);
        }
      });
    } else {
      this.searchResults.forEach(asset => {
        !this.assetsIds.includes(asset.assetId) && this.selection.deselect(asset);
      });
    }
  }

  isAllSelectedOnPage(): boolean {
    return this.searchResults.every(asset => this.selection.isSelected(asset));
  }

  isSomeSelectedOnPage(): boolean {
    return this.searchResults.some(asset => this.selection.isSelected(asset));
  }

  onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = './assets/images/default-image.png';
  }

}
