import { Inject, Injectable, InjectionToken } from '@angular/core';

export const CONFIG_TOKEN = new InjectionToken<Config>('config');
export type Config = {
    index: string;
    queryKey: string;
    service: string;
    versionId?: string;
    type: string;
    dateToValidate: number | null;
};
export enum AzureServiceType {
    Search = "search",
    Store = "store",
}
export type CheckboxFacetItem = {
    value: string | number;
    count: number;
    selected: boolean;
};
export type DropdownFacetItem = {
    value: string | number;
    count: number;
    selected: boolean;
};
export type FacetSortingMode = "count" | "count-" | "value" | "value-";
export type RangeDataType = "number" | "date";
export type RangeFacet = {
    type: "RangeFacet";
    dataType: RangeDataType;
    key: string;
    min: number | Date;
    max: number | Date;
    filterLowerBound: number | Date;
    filterUpperBound: number | Date;
    lowerBucketCount: number;
    middleBucketCount: number;
    upperBucketCount: number;
    facetClause: string;
    filterClause: string;
    htmlId: string;
    title: string;
};
export type CheckboxDataType = "number" | "string" | "collection";
export type DropdownDataType = "number" | "string" | "collection";
export type CheckboxFacet = {
    type: "CheckboxFacet";
    dataType: CheckboxDataType;
    key: string;
    values: {
        [key: string]: CheckboxFacetItem;
    };
    count: number;
    sort: FacetSortingMode;
    facetClause: string;
    filterClause: string;
    htmlId: string;
    title: string;
};
export type DropdownFacet = {
    type: "DropdownFacet";
    dataType: DropdownDataType;
    key: string;
    values: {
        [key: string]: DropdownFacetItem;
    };
    count: number;
    sort: FacetSortingMode;
    facetClause: string;
    filterClause: string;
    htmlId: string;
    title: string;
};
type FacetResult = {
    count: number;
    value?: string | number;
    from?: number;
    to?: number;
};
type FacetMode = "simple" | "advanced";

export type SearchConfig = {
    loadmore?: boolean,
    dataId?: null | string,
    clientKey?: null | string,
    resultsCallBack?: any,
};
export interface AzureSearchConfig {
    SearchEndPoint: string;
    SearchIndexName: string;
    QueryKey: string;
}
export enum FacetViewMode {
    All = 'all',
    SinglePaged = 'singlepaged',
}

@Injectable({
    providedIn: 'root',
})

export class AzureSearchService {

    private store = {
        "config": {
            "index": "",
            "queryKey": "",
            "service": "",
            "type": ""
        },
        "results": {
            "count": -1,
            "isFetching": false,
            "lastUpdated": 0,
            "results": <any[]>[]
        },
        "parameters": {
            "input": "",
            "searchParameters": {
                "count": false,
                "orderby": null,
                "scoringProfile": null,
                "searchFields": null,
                "select": null,
                "skip": 0,
                "top": 50,
                "apiVersion": "2024-03-01-preview",
                "searchMode": "any",
                "queryType": "simple",
                "highlight": null,
                "highlightPreTag": null,
                "highlightPostTag": null,
                "scoringParameters": null
            },
            "suggestionsParameters": {
                "orderby": null,
                "searchFields": null,
                "select": null,
                "top": 5,
                "apiVersion": "2024-03-01-preview",
                "filter": null,
                "fuzzy": false,
                "highlightPostTag": null,
                "highlightPreTag": null,
                "suggesterName": null
            }
        },
        "facets": {
            "facetMode": <FacetMode>"simple",
            "globalFilters": {},
            "facets": <any>{},
            "chips": <any>[]
        },
        "suggestions": {
            "isFetching": false,
            "lastUpdated": 0,
            "suggestions": []
        }
    };
    searchURI: string = '';
    suggestionsURI: string = '';
    userAgent = "AzSearchStore/Preview";
    searchResultsCB: any;
    suggestionsResultsCB: any;
    chipsWrapperEle: HTMLElement | null = null;
    clearChips: boolean = false;
    searchfilterCB: any = null;
    renderDropdownFacetsCB: any = null;
    renderDateRangeFacetsCB: any = null;
    suggestionfilterCB: any = null;
    versionId: any;
    dateToValidate: number | null = null;
    facetViewMode: FacetViewMode = FacetViewMode.All;
    facetViewCount: number = 5;
    facetSearchEnabled: boolean = true;
    facetIgnoreList: string[] = [];

    constructor(@Inject(CONFIG_TOKEN) config: Config) {
        this.setConfig(config);
        this.versionId = config.versionId;
        this.dateToValidate = typeof config.dateToValidate === 'number' ? config.dateToValidate : null;
        this.searchURI = this.buildSearchURI();
        this.suggestionsURI = this.buildSuggestionsURI();
    }


    setConfig(config: Config) {
        this.store.config = config;
    }

    setInput(input: string) {
        this.store.parameters.input = input;
    }

    getStore() {
        return this.store
    }

    updateObject(oldObject: Object, newValues: Object) {
        return Object.assign({}, oldObject, newValues);
    }

    updateObjectAtKey(oldObject: Object, entry: Object, key: string) {
        var newObject: { [key: string]: Object } = {};
        newObject[key] = entry;
        return this.updateObject(oldObject, newObject);
    }

    buildSearchURI() {
        var service = this.store.config.service, index = this.store.config.index;
        var apiVersion = this.store.parameters.searchParameters.apiVersion;
        var uriTemplate = service + "/indexes/" + index + "/docs/search?api-version=" + apiVersion;

        var searchURI = uriTemplate;
        return searchURI.valueOf();
    }

    buildSuggestionsURI() {
        var service = this.store.config.service, index = this.store.config.index;
        var apiVersion = this.store.parameters.suggestionsParameters.apiVersion;
        var uriTemplate = service + "/indexes/" + index + "/docs/suggest?api-version=" + apiVersion;
        var searchURI = uriTemplate;
        return searchURI.valueOf();
    }

    updateSearchParameters(obj: any) {
        this.store.parameters.searchParameters = Object.assign(this.store.parameters.searchParameters, obj);
    }

    updateSuggestionsParameters(obj: any) {
        this.store.parameters.suggestionsParameters = Object.assign(this.store.parameters.suggestionsParameters, obj);
    }

    setResultsProcessor(callbackfn: any) {
        this.searchResultsCB = callbackfn;
    }

    setSuggestionsProcessor(callbackfn: any) {
        this.suggestionsResultsCB = callbackfn;
    }

    async search(paramsObj: SearchConfig = {}) {
        var defaultParams: SearchConfig = {
            loadmore: false,
            dataId: null,
            clientKey: null,
            resultsCallBack: null,
            ...paramsObj
        };
        var params: any = {};

        var parameterMap: any = {
            ...this.store.parameters.searchParameters,
            search: this.store.parameters.input ? `${this.store.parameters.input}*` : "*",
        };

        Object.keys(parameterMap).forEach(function (parameter: string) {
            var value = parameterMap[parameter];
            !(value == null) && parameter !== "apiVersion" ? params[parameter] = value : 0;
        });

        if (this.store.facets) {
            var facetClauses = this.getFacetClauses(this.store.facets);
            facetClauses ? params["facets"] = facetClauses : 0;

            var filter = this.getFilterClauses(this.store.facets);
            filter ? params["filter"] = filter : 0;
        }

        if (this.versionId) {
            if (params["filter"]) {
                params["filter"] = params["filter"] + ` and versionId eq '${this.versionId}'`
            } else {
                params["filter"] = `versionId eq '${this.versionId}'`
            }
        }

        if (this.dateToValidate) {
            params["filter"] = params["filter"] + ` and startDate le ${this.dateToValidate} and endDate ge ${this.dateToValidate}`
        }

        if (defaultParams.clientKey) {
            params["filter"] = params["filter"] + ` and dataClientKey eq '${defaultParams.clientKey}'`
        }

        if (defaultParams.dataId) {
            params["filter"] = params["filter"] + ` and dataId eq '${defaultParams.dataId}'`
        }

        var promise = fetch(this.searchURI, {
            mode: "cors",
            headers: new Headers({
                "api-key": this.store.config.queryKey,
                "Content-Type": "application/json",
                "User-Agent": this.userAgent,
                "x-ms-client-user-agent": this.userAgent
            }),
            method: "POST",
            body: JSON.stringify(params)
        });

        try {
            const response = await promise;
            const json = await response.json();
            var results = [];

            if (json["value"]) {
                results = json["value"];
                if (typeof this.searchfilterCB == 'function') {
                    results = this.searchfilterCB(results);
                }
            }

            var facets: FacetResult = json["@search.facets"];
            var count = json["@odata.count"];
            count = count >= 0 ? count : -1;

            this.store.results.count = count;

            if (facets) {
                this.removeIgnoredFacets(facets, this.facetIgnoreList);

                if (this.clearChips) {
                    this.clearChips = false;
                    this.store.facets.chips = [];
                }

                this.updateFacetsValues(facets);

                if (this.chipsWrapperEle && this.store.facets.chips.length == 0) {
                    let tagsWrap: any = this.chipsWrapperEle.querySelector('.tags_wrap');
                    if (tagsWrap) {
                        tagsWrap.style.display = 'none';
                        tagsWrap.replaceChildren();
                    }
                }

                let storeFacets = this.store.facets.facets;
                Object.keys(storeFacets).forEach(key => {
                    var facet = storeFacets[key];

                    switch (facet.type) {
                        case "CheckboxFacet":
                            this.renderCheckBoxFacets(facet);
                            break;
                        case "DropdownFacet":
                            if (typeof this.renderDropdownFacetsCB == 'function') {
                                this.renderDropdownFacetsCB(facet);
                            }
                            break;
                        case "RangeFacet":
                            if (typeof this.renderDateRangeFacetsCB == 'function') {
                                this.renderDateRangeFacetsCB(facet);
                            }
                            let copyKey = this.endwithCopySuffix(key);
                            if (copyKey) {
                                break
                            }
                            this.renderRangeFacets(facet);
                            break;
                        default: break;
                    }
                })
            }

            if (defaultParams.loadmore) {
                this.store.results.results = [...this.store.results.results, ...results];
            } else {
                this.store.results.results = results;
            }

            if (defaultParams.resultsCallBack && typeof defaultParams.resultsCallBack == 'function') {
                defaultParams.resultsCallBack(this.store.results);
            } else {
                this.searchResultsCB(this.store.results);
            }

        } catch (error) {
            console.error("search", error);

            if (defaultParams.resultsCallBack && typeof defaultParams.resultsCallBack == 'function') {
                defaultParams.resultsCallBack([]);
            }
        }
    }

    loadMore() {
        this.search({ loadmore: true })
    }

    async suggest() {
        var params: any = {};
        var parameterMap: any = {
            ...this.store.parameters.suggestionsParameters,
            search: this.store.parameters.input,
        };

        Object.keys(parameterMap).forEach(function (parameter: string) {
            var value = parameterMap[parameter];
            !(value == null) && parameter !== "apiVersion" ? params[parameter] = value : 0;
        });

        if (this.versionId) {
            params["filter"] = `versionId eq '${this.versionId}'`
        }

        var promise = fetch(this.suggestionsURI, {
            mode: "cors",
            headers: new Headers({
                "api-key": this.store.config.queryKey,
                "Content-Type": "application/json",
                "User-Agent": this.userAgent,
                "x-ms-client-user-agent": this.userAgent
            }),
            method: "POST",
            body: JSON.stringify(params)
        });

        try {
            const response = await promise;
            const json = await response.json();
            var suggestions: any = [];

            if (json["value"]) {
                suggestions = json["value"];
                if (typeof this.suggestionfilterCB == 'function') {
                    suggestions = this.suggestionfilterCB(suggestions);
                }
            }

            this.store.suggestions.suggestions = suggestions;
            this.suggestionsResultsCB(this.store.suggestions.suggestions);
        } catch (error) {
            console.error("search", error);
        }
    }

    getFacetClauses(facets: any) {
        var facetKeys = Object.keys(facets.facets);
        var facetClauses: any = facetKeys.map(function (key) {
            return facets.facets[key].facetClause;
        });

        facetClauses = facetClauses.length ? facetClauses : null;
        return facetClauses;
    }

    getFilterClauses(facets: any) {
        var facetKeys = Object.keys(facets.facets);
        var filteredFacets = facetKeys.filter(function (key) {
            return facets.facets[key].filterClause;
        }).map(function (key) {
            return facets.facets[key].filterClause;
        });

        var globalFilter = this.getGlobalFilter(facets.globalFilters);

        if (globalFilter) {
            filteredFacets.push(globalFilter);
        }

        return filteredFacets.join(" and ");
    }

    getGlobalFilter(globalFilters: any) {
        var filters = Object.keys(globalFilters).filter(function (key) {
            return globalFilters[key];
        }).map(function (key) { return globalFilters[key]; });
        return filters.join(" and ");
    }

    addCheckboxFacet(htmlId: string, fieldName: string, fieldTitle: string, dataType: CheckboxDataType, count?: number, sort?: FacetSortingMode) {
        switch (dataType) {
            case "number":
            case "collection":
            case "string":
                break;
            default:
                throw new Error("dataType of CheckboxFacet must be 'number' | 'collection' | 'string'");
        }

        if (count === undefined) { count = 5; }
        if (sort === undefined) { sort = "count"; }

        var checkFacet: CheckboxFacet = {
            type: "CheckboxFacet",
            key: fieldName,
            title: fieldTitle,
            dataType: dataType,
            values: {},
            count: count,
            sort: sort,
            filterClause: "",
            facetClause: fieldName + ",count:1000",
            // facetClause: fieldName + ",count:" + count + ",sort:" + sort,
            htmlId: htmlId
        };

        this.store.facets.facets[fieldName] = checkFacet;
        this.initFacetView(checkFacet);
    }

    addDropdownFacet(htmlId: string, fieldName: string, fieldTitle: string, dataType: DropdownDataType, count?: number, sort?: FacetSortingMode) {
        switch (dataType) {
            case "number":
            case "collection":
            case "string":
                break;
            default:
                throw new Error("dataType of DropdownFacet must be 'number' | 'collection' | 'string'");
        }

        if (count === undefined) { count = 5; }
        if (sort === undefined) { sort = "count"; }

        var dropdownFacet: DropdownFacet = {
            type: "DropdownFacet",
            key: fieldName,
            title: fieldTitle,
            dataType: dataType,
            values: {},
            count: count,
            sort: sort,
            filterClause: "",
            facetClause: fieldName + ",count:1000",
            // facetClause: fieldName + ",count:" + count + ",sort:" + sort,
            htmlId: htmlId
        };

        this.store.facets.facets[fieldName] = dropdownFacet;
        // this.initFacetView(dropdownFacet);
    }

    addCustomRangeFacet(htmlId: string, fieldName: string, fieldTitle: string) {
        var rangeFacet: RangeFacet = {
            type: "RangeFacet",
            dataType: 'number',
            key: fieldName,
            title: fieldTitle,
            min: 0,
            max: 999,
            filterLowerBound: 0,
            filterUpperBound: 999,
            lowerBucketCount: 0,
            middleBucketCount: 0,
            upperBucketCount: 0,
            filterClause: "",
            facetClause: `${fieldName},sort:value,count:1`,
            htmlId: htmlId
        };
        this.store.facets.facets[fieldName] = rangeFacet;

        var rangeFacetCopy: RangeFacet = {
            type: "RangeFacet",
            dataType: 'number',
            key: `${fieldName}Copy`,
            title: fieldTitle,
            min: 0,
            max: 999,
            filterLowerBound: 0,
            filterUpperBound: 999,
            lowerBucketCount: 0,
            middleBucketCount: 0,
            upperBucketCount: 0,
            filterClause: "",
            facetClause: `${fieldName}Copy,sort:-value,count:1`,
            htmlId: htmlId
        };
        this.store.facets.facets[rangeFacetCopy.key] = rangeFacetCopy;

        this.initFacetView(rangeFacet);
    }

    addRangeFacet(htmlId: string, fieldName: string, fieldTitle: string, dataType: RangeDataType, min: number | Date, max: number | Date) {
        switch (dataType) {
            case "number":
            case "date":
                break;
            default:
                throw new Error("dataType of RangeFacet must be 'number' | 'date'");
        }

        var filterLowerBound = min;
        var filterUpperBound = max;
        var rangeFacet: RangeFacet = {
            type: "RangeFacet",
            dataType: dataType,
            key: fieldName,
            title: fieldTitle,
            min: min,
            max: max,
            filterLowerBound: min,
            filterUpperBound: max,
            lowerBucketCount: 0,
            middleBucketCount: 0,
            upperBucketCount: 0,
            filterClause: "",
            facetClause: this.getRangeFacetClause(dataType, fieldName, filterLowerBound, filterUpperBound),
            htmlId: htmlId
        };

        this.store.facets.facets[fieldName] = rangeFacet;
        this.initFacetView(rangeFacet);
    }

    addDateRangeFacet(htmlId: string, fieldName: string, fieldTitle: string, dataType: RangeDataType, min: number | Date, max: number | Date) {
        switch (dataType) {
            case "number":
            case "date":
                break;
            default:
                throw new Error("dataType of RangeFacet must be 'number' | 'date'");
        }

        var rangeFacet: RangeFacet = {
            type: "RangeFacet",
            dataType: dataType,
            key: fieldName,
            title: fieldTitle,
            min: min,
            max: max,
            filterLowerBound: min,
            filterUpperBound: max,
            lowerBucketCount: 0,
            middleBucketCount: 0,
            upperBucketCount: 0,
            filterClause: "",
            facetClause: "",
            htmlId: htmlId
        };

        this.store.facets.facets[fieldName] = rangeFacet;
        this.initFacetView(rangeFacet);
    }

    initFacetView(facet: any) {
        let facetPlaceholder = document.getElementById(facet.htmlId);
        let templateHtml = `
        <div class="accordion">
            <h3 class="accordion-header">
                <button class="accordion-button collapsed" aria-expanded="true" aria-controls="panel-${facet.htmlId}" id="accordion-${facet.htmlId}">
                    <span class="title">{{title}}</span>
                    <span class="facetcount"></span>
                </button>
            </h3>
            <div class="accordion-collapse" id="panel-${facet.htmlId}" role="region" aria-labelledby="accordion-${facet.htmlId}"></div>
        </div>`;

        if (facetPlaceholder) {
            facetPlaceholder.innerHTML = templateHtml;

            let titleEle = facetPlaceholder?.querySelector('.accordion-button .title');
            if (titleEle) {
                titleEle.innerHTML = facet.title;
            }
            let accordionHeader = facetPlaceholder.querySelector(".accordion-header");
            let accordionHeaderBtn = facetPlaceholder.querySelector(".accordion-button") as HTMLButtonElement;

            const handleAccordionToggle = (event: Event) => {
                const target = event.currentTarget as HTMLButtonElement;

                // Check if the button was clicked or activated by keyboard
                if (target) {
                    const expanded = target.getAttribute('aria-expanded') === 'true';

                    // Set aria-expanded with a string value ('true' or 'false')
                    target.setAttribute('aria-expanded', (!expanded).toString());

                    // Get the associated panel using aria-controls
                    const panelId = target.getAttribute('aria-controls') as string;
                    const panel = facetPlaceholder?.querySelector(`#${panelId}`) as HTMLElement;

                    // Handle the case where panel could be null
                    if (panel) {
                        panel.hidden = expanded; // Hide if it was expanded, show otherwise
                    }

                    if (accordionHeader) {
                        accordionHeader.classList.toggle("active");
                    }
                }
            };

            if (accordionHeaderBtn) {
                // Add click event listener
                accordionHeaderBtn.addEventListener('click', handleAccordionToggle);
            }
        }
    }

    getRangeFacetClause(dataType: RangeDataType, fieldName: string, filterLowerBound: number | Date, filterUpperBound: number | Date) {
        var lowerClause;
        var upperClause;
        switch (dataType) {
            case "number":
                lowerClause = filterLowerBound;
                upperClause = filterUpperBound;
                break;
            case "date":
                lowerClause = (filterLowerBound as Date).toISOString();
                upperClause = (filterUpperBound as Date).toISOString();
                break;
            default:
                break;
        }

        if (filterLowerBound == 0 && filterUpperBound == 0) {
            return ''
        }

        return fieldName + ",values:" + lowerClause + "|" + upperClause;
    }

    updateFacetsValues(facetsResponse: any) {
        var facetKeys = Object.keys(facetsResponse);
        var keysToUpdate = facetKeys.filter((key) => {
            var facet = this.store.facets.facets[key];
            if (facet.type == "RangeFacet") {
                let copyKey = this.endwithCopySuffix(key);
                if (copyKey) {
                    return
                }

                if (facetsResponse[key] && facetsResponse[key].length) {
                    var facetResult = facetsResponse[key][0];
                    if (facetResult.value < 0) {
                        facetResult.value = 0
                    }
                    (facet as RangeFacet).min = facetResult.value;
                    (facet as RangeFacet).filterLowerBound = facetResult.value;
                    var facetResultCopy = facetsResponse[key + 'Copy'][0];
                    (facet as RangeFacet).max = facetResultCopy.value;
                    (facet as RangeFacet).filterUpperBound = facetResultCopy.value;
                }
            }

            return facet;
        });

        keysToUpdate.forEach((key) => {
            var facet = this.store.facets.facets[key];
            var facetResults = facetsResponse[key];
            switch (facet.type) {
                case "CheckboxFacet":
                    this.setCheckboxFacetValues(facet, facetResults);
                    break;
                case "DropdownFacet":
                    this.setDropdownFacetValues(facet, facetResults);
                    break;
                case "RangeFacet":
                    this.setRangeFacetValuesCopy(facet);
                    break;
                default: break;
            }
        });
    }

    setCheckboxFacetValues(facet: any, facetResults: any) {
        Object.keys(facet.values).forEach(val => {
            if (facet.values[val].count) {
                facet.values[val].count = 0;
            }
        });

        var values: any = {};
        facetResults.forEach((facetResult: any) => {
            var value = facetResult.value;
            var count = facetResult.count;

            values[value] = {
                value: value,
                count: count,
                selected: false
            };

            if (facet.values[value]) {
                values[value].selected = facet.values[value].selected;
            }
        });

        // facet.values = Object.assign({}, values);
        facet.values = { ...facet.values, ...values };
        facet.values = this.sortFacet(facet.values);
        facet.filterClause = facet.filterClause ? facet.filterClause : "";
    }

    setDropdownFacetValues(facet: any, facetResults: any) {
        Object.keys(facet.values).forEach(val => {
            if (facet.values[val].count) {
                facet.values[val].count = 0;
            }
        });

        var values: any = {};
        facetResults.forEach((facetResult: any) => {
            var value = facetResult.value;
            var count = facetResult.count;

            values[value] = {
                value: value,
                count: count,
                selected: false
            };

            if (facet.values[value]) {
                values[value].selected = facet.values[value].selected;
            }
        });

        // facet.values = Object.assign({}, values);
        facet.values = { ...facet.values, ...values };
        facet.values = this.sortFacet(facet.values);
        facet.filterClause = facet.filterClause ? facet.filterClause : "";
    }

    setRangeFacetValues(facet: any, facetResults: any) {
        var updatedprops = {
            filterLowerBound: facetResults[1].from,
            filterUpperBound: facetResults[1].to,
            lowerBucketCount: 0,
            upperBucketCount: 0,
            middleBucketCount: facetResults[1].count,
            filterClause: "",
        }

        facet = Object.assign(facet, updatedprops);
    }

    setRangeFacetValuesCopy(facet: any) {
        var updatedprops = {
            filterLowerBound: facet.filterLowerBound,
            filterUpperBound: facet.filterUpperBound,
            lowerBucketCount: 0,
            upperBucketCount: 0,
            middleBucketCount: this.store.results.count,
            filterClause: "",
        }

        facet = Object.assign(facet, updatedprops);
    }

    renderCheckBoxFacets(facet: any) {
        const facetPlaceholder: HTMLElement | null = document.getElementById(facet.htmlId);

        if (!facetPlaceholder || !Object.keys(facet.values).length) {
            if (facetPlaceholder) facetPlaceholder.hidden = true;
            return;
        }

        facetPlaceholder.hidden = false;

        let accordionContainer: HTMLElement | null = facetPlaceholder.querySelector('.accordion-collapse');
        let listGroupWrapper: HTMLElement | null = facetPlaceholder.querySelector('.accordion-collapse .list-group');

        if (!listGroupWrapper) {
            let searchBoxHTML = '';
            if (this.facetSearchEnabled && Object.keys(facet.values).length > this.facetViewCount) {
                searchBoxHTML = this.searchFacetHTML(facet);
            }
            accordionContainer!.innerHTML = `${searchBoxHTML}<div class="list-group"></div>`;
            listGroupWrapper = facetPlaceholder.querySelector('.accordion-collapse .list-group');
        }
        if (!listGroupWrapper) return

        listGroupWrapper?.replaceChildren();
        const _facetSearchInput = accordionContainer?.querySelector(`#pr_search${facet.key}Facet`) as HTMLInputElement;
        _facetSearchInput && (_facetSearchInput.value = '');

        const facetKeys = Object.keys(facet.values);
        const initialLimit = this.facetViewCount;
        const viewMode = this.facetViewMode;
        let renderedCount = 0;
        let showMoreLi: HTMLElement | null;

        const createListItem = (key: string) => {
            const checkboxFacet = facet.values[key];
            const checkboxFacetId = `${this.removeSpacesAndSpecialCharacters(facet.key)}_${this.removeSpacesAndSpecialCharacters(checkboxFacet.value)}`;

            const liEle = document.createElement('li');
            liEle.className = "list-group-item";
            liEle.id = checkboxFacetId;
            liEle.innerHTML = `
                <div class="checkbox-wrapper checkbox">
                    <input type="checkbox" class="styled-checkbox" id="${checkboxFacetId}" value="on">
                    <label for="${checkboxFacetId}" class="checkboxLabel">${checkboxFacet.value} ${checkboxFacet.count ? '(' + checkboxFacet.count + ')' : ''}</label>
                </div>`;

            if (showMoreLi && showMoreLi.parentNode === listGroupWrapper) {
                listGroupWrapper?.insertBefore(liEle, showMoreLi);
            } else {
                listGroupWrapper?.appendChild(liEle);
            }

            const checkbox = liEle.querySelector('input[type="checkbox"]') as HTMLInputElement;
            checkbox.checked = checkboxFacet.selected;

            checkbox.addEventListener('change', (event: Event) => handleCheckboxChange(event, facet, checkboxFacet, checkboxFacetId, key));
        };

        const renderItems = (keys: string[]) => {
            keys.forEach(createListItem);
        };

        const loadMoreItems = (keys = facetKeys) => {
            const remainingKeys = keys.slice(renderedCount, renderedCount + initialLimit);
            renderItems(remainingKeys);
            renderedCount += remainingKeys.length;

            if (renderedCount >= keys.length && showMoreLi) {
                showMoreLi.remove();
                showMoreLi = null;
            }
        };

        const loadAllRemainingItems = (keys = facetKeys) => {
            const remainingKeys = keys.slice(renderedCount);
            renderItems(remainingKeys);
            renderedCount = keys.length;
            if (showMoreLi) {
                showMoreLi.remove();
                showMoreLi = null;
            }
        };

        const handleSearch = (searchTerm: string) => {
            const filteredKeys = searchTerm
                ? facetKeys.filter(key => facet.values[key].value.toLowerCase().includes(searchTerm.toLowerCase()))
                : facetKeys;

            listGroupWrapper?.replaceChildren();
            renderItems(filteredKeys.slice(0, initialLimit));
            renderedCount = Math.min(filteredKeys.length, initialLimit);

            if (filteredKeys.length > initialLimit) {
                if (showMoreLi) {
                    showMoreLi.remove();
                    showMoreLi = null;
                }
                if (!showMoreLi) {
                    showMoreLi = document.createElement('li');
                    showMoreLi.className = "list-group-item show-more";
                    if (viewMode === FacetViewMode.All) {
                        showMoreLi.innerHTML = `<button class="show-more-link" aria-label="view ${initialLimit} more ${facet.title}">View ${initialLimit} More</button>`;
                    } else {
                        showMoreLi.innerHTML = `<button class="show-more-link" aria-label="view more ${facet.title}">View More</button>`;
                    }
                    listGroupWrapper?.appendChild(showMoreLi);

                    const showMoreLink = showMoreLi.querySelector('.show-more-link');
                    showMoreLink?.addEventListener('click', (event: Event) => {
                        event.preventDefault();
                        if (viewMode === FacetViewMode.All) {
                            loadMoreItems(filteredKeys);
                        } else {
                            loadAllRemainingItems(filteredKeys);
                        }
                    });
                }
            }
        };

        const handleCheckboxChange = (event: Event, facet: any, checkboxFacet: any, checkboxFacetId: string, key: string) => {
            const checkbox = event.currentTarget as HTMLInputElement;
            checkboxFacet.selected = checkbox.checked;
            facet.filterClause = this.buildCheckboxFilter(facet);

            const chip = createChip(facet, checkboxFacet, checkboxFacetId);
            const storeChips = this.store.facets.chips;
            const index = storeChips.findIndex((c: any) => c.key == chip.key && c.value == chip.value);

            if (checkbox.checked && index === -1) {
                storeChips.push(chip);
                this.addChip(chip, () => resetCheckbox(facet, checkboxFacetId, key));
            } else if (index > -1) {
                storeChips.splice(index, 1);
                this.removeChip(chip);
            }
        };

        const createChip = (facet: any, checkboxFacet: any, checkboxFacetId: string) => ({
            key: facet.key,
            type: "CheckboxFacet",
            htmlId: `${checkboxFacetId}_chip`,
            facethtmlId: facet.htmlId,
            ...checkboxFacet
        });

        const resetCheckbox = (facet: any, checkboxFacetId: string, key: string) => {
            const checkboxFacet = facet.values[key];
            checkboxFacet.selected = false;
            facet.filterClause = this.buildCheckboxFilter(facet);

            const liEle = document.getElementById(checkboxFacetId);
            if (!liEle) return;

            const checkbox = liEle.querySelector('input[type="checkbox"]') as HTMLInputElement;
            checkbox.checked = false;
        };


        if (this.facetSearchEnabled) {
            const facetSearchInput = accordionContainer?.querySelector(`#pr_search${facet.key}Facet`) as HTMLInputElement;
            const facetSearchIcon = accordionContainer?.querySelector(`#pr_search${facet.key}FacetIconBtn`) as HTMLElement;

            facetSearchInput?.addEventListener("input", (event: Event) => handleSearch((event.target as HTMLInputElement).value));
            facetSearchIcon?.addEventListener("click", () => handleSearch(facetSearchInput.value));
        }

        renderItems(facetKeys.slice(0, initialLimit));
        renderedCount += initialLimit;

        if (facetKeys.length > initialLimit) {
            showMoreLi = document.createElement('li');
            showMoreLi.className = "list-group-item show-more";
            showMoreLi.innerHTML = `<button class="show-more-link" aria-label="${viewMode === FacetViewMode.All ? `view ${initialLimit} more ${facet.title}` : `view more ${facet.title}`}">${viewMode === FacetViewMode.All ? `View ${initialLimit} More` : 'View More'}</button>`;
            listGroupWrapper?.appendChild(showMoreLi);

            const showMoreLink = showMoreLi.querySelector('.show-more-link');
            showMoreLink?.addEventListener('click', (event: Event) => {
                event.preventDefault();
                if (viewMode === FacetViewMode.All) {
                    loadMoreItems();
                } else {
                    loadAllRemainingItems();
                }
            });
        }

        this.updateFacetCount(facet);
    }

    updateFacetCount = (facet: any) => {
        const storeChips = this.store.facets.chips;
        const count = storeChips.filter((c: { key: any; }) => c.key === facet.key).length;
        const facetcountEle = document.querySelector(`#${facet.htmlId} .facetcount`) as HTMLElement;

        facetcountEle.innerHTML = count ? `(${count} selected)` : '';
    };

    renderRangeFacets(facet: any) {
        let facetPlaceholder = document.getElementById(facet.htmlId);
        let accordionContainer: any = facetPlaceholder?.querySelector('.accordion-collapse');
        let sliderWrapper = facetPlaceholder?.querySelector('.accordion-collapse .multi-range-slider');

        if (accordionContainer && !sliderWrapper) {
            let templateHtml = `
                <div class="multi-range-slider">
                    <input type="range" class="input-left" min="0" max="100" value="0" step="0.01" aria-label="price range start">
                    <input type="range" class="input-right" min="0" max="100" value="100" step="0.01" aria-label="price range end">

                    <div class="slider">
                        <div class="track"></div>
                        <div class="range"></div>
                        <div class="thumb left"></div>
                        <div class="thumb right"></div>
                        <div class="tooltip left">
                            <span class="lefttooltip"></span>
                            <span class="tooltipvalue">$1</span>
                            <span class="righttooltip"></span>
                            <div class="triangle-down"></div>
                        </div>
                        <div class="tooltip right">
                            <span class="lefttooltip"></span>
                            <span class="tooltipvalue">$2</span>
                            <span class="righttooltip"></span>
                            <div class="triangle-down"></div>
                        </div>
                    </div>
                </div>`;

            accordionContainer.innerHTML = templateHtml;
        }

        if (facetPlaceholder) {
            facetPlaceholder.style.removeProperty("display");

            var inputLeft: any = facetPlaceholder.querySelector(".input-left");
            var inputRight: any = facetPlaceholder.querySelector(".input-right");

            var thumbLeft: any = facetPlaceholder.querySelector(".slider > .thumb.left");
            var thumbRight: any = facetPlaceholder.querySelector(".slider > .thumb.right");
            var tooltipLeft: any = facetPlaceholder.querySelector(".slider > .tooltip.left");
            var tooltipRight: any = facetPlaceholder.querySelector(".slider > .tooltip.right");
            var range: any = facetPlaceholder.querySelector(".slider > .range");

            inputLeft.min = facet.min;
            inputLeft.max = facet.max;
            inputLeft.value = facet.filterLowerBound;
            inputRight.min = facet.min;
            inputRight.max = facet.max;
            inputRight.value = facet.filterUpperBound;

            var resetFn = () => {
                let resetProps = {
                    filterLowerBound: facet.min,
                    filterUpperBound: facet.max,
                    lowerBucketCount: 0,
                    middleBucketCount: facet.middleBucketCount,
                    upperBucketCount: 0,
                    filterClause: "",
                    facetClause: ""
                }

                resetProps.facetClause = this.getRangeFacetClause(facet.dataType, facet.key, resetProps.filterLowerBound, resetProps.filterUpperBound)
                facet = Object.assign(facet, resetProps);

                setLeftValue(true);
                setRightValue(true);
            };

            var updateRangeChip = (facet: any) => {
                let storeChips: any[] = this.store.facets.chips;
                let index = storeChips.findIndex(chip => chip.key == facet.key);
                let chip = {
                    key: facet.key,
                    type: facet.type,
                    value: `$${facet.filterLowerBound}-$${facet.filterUpperBound}`,
                    htmlId: `${facet.key}_chip`,
                    facethtmlId: facet.htmlId,
                };

                if (index > -1) {
                    storeChips.splice(index, 1);
                    this.removeChip(chip);
                }

                if (parseInt(facet.filterLowerBound) != parseInt(facet.min) || parseInt(facet.filterUpperBound) != parseInt(facet.max)) {
                    storeChips.push(chip);
                    this.addChip(chip, resetFn);
                }
            }

            var updateRangeFacet = () => {
                if (parseInt(facet.filterLowerBound) != parseInt(facet.min) || parseInt(facet.filterUpperBound) != parseInt(facet.max)) {
                    var filterClause = this.buildRangeFilter(facet);
                    facet.filterClause = filterClause;
                } else {
                    facet.filterClause = "";
                }

                // var facetClause = this.getRangeFacetClause(facet.dataType, facet.key, facet.filterLowerBound, facet.filterUpperBound);
                // facet.facetClause = facetClause;

                updateRangeChip(facet);
            }

            var setLeftValue = (reset: boolean = false) => {
                var _this = inputLeft,
                    min = parseFloat(_this.min),
                    max = parseFloat(_this.max);

                if (reset) {
                    _this.value = min;
                }

                _this.value = Math.min(parseFloat(_this.value), parseFloat(inputRight.value) - (max / 100 * 2));
                let tooltip = tooltipLeft.querySelector('.tooltipvalue');
                tooltip.innerHTML = "$" + _this.value;

                var percent = ((_this.value - min) / (max - min)) * 100;

                thumbLeft.style.left = percent + "%";
                tooltipLeft.style.left = percent + "%";
                range.style.left = percent + "%";

                facet.filterLowerBound = _this.value;

                !reset && updateRangeFacet();
            }

            setLeftValue();

            var setRightValue = (reset: boolean = false) => {
                var _this = inputRight,
                    min = parseFloat(_this.min),
                    max = parseFloat(_this.max);

                if (reset) {
                    _this.value = max;
                }

                _this.value = Math.max(parseFloat(_this.value), parseFloat(inputLeft.value) + (max / 100 * 2));
                let tooltip = tooltipRight.querySelector('.tooltipvalue');
                tooltip.innerHTML = "$" + _this.value;

                var percent = ((_this.value - min) / (max - min)) * 100;

                thumbRight.style.right = (100 - percent) + "%";
                tooltipRight.style.right = (100 - percent) + "%";
                range.style.right = (100 - percent) + "%";

                facet.filterUpperBound = _this.value;

                !reset && updateRangeFacet();
            }

            setRightValue();

            if (inputLeft && inputRight && thumbLeft && thumbRight) {
                inputLeft.addEventListener("input", () => setLeftValue());
                inputRight.addEventListener("input", () => setRightValue());
            }
        }

    }

    buildCheckboxFilter(facet: any) {
        var selectedFacets = Object.keys(facet.values).filter(function (value) {
            return facet.values[value].selected;
        });
        var clauses = selectedFacets.map((selectedValue) => {
            var clause;
            switch (facet.dataType) {
                case "number":
                    clause = facet.key + " eq " + this.replaceSingleQuote(facet.values[selectedValue].value);
                    break;
                case "string":
                    clause = facet.key + " eq '" + this.replaceSingleQuote(facet.values[selectedValue].value) + "'";
                    break;
                case "collection":
                    clause = facet.key + "/any(t: t eq '" + this.replaceSingleQuote(facet.values[selectedValue].value) + "')";
                    break;
                default:
                    clause = "";
                    break;
            }
            return clause;
        });
        var filter = clauses.join(" or ");
        filter.length ? filter = "(" + filter + ")" : filter = "";
        return filter;
    }

    buildDropdownFilter(facet: any) {
        var selectedFacets = Object.keys(facet.values).filter(function (value) {
            return facet.values[value].selected;
        });
        var clauses = selectedFacets.map((selectedValue) => {
            var clause;
            switch (facet.dataType) {
                case "number":
                    clause = facet.key + " eq " + this.replaceSingleQuote(facet.values[selectedValue].value);
                    break;
                case "string":
                    clause = facet.key + " eq '" + this.replaceSingleQuote(facet.values[selectedValue].value) + "'";
                    break;
                case "collection":
                    clause = facet.key + "/any(t: t eq '" + this.replaceSingleQuote(facet.values[selectedValue].value) + "')";
                    break;
                default:
                    clause = "";
                    break;
            }
            return clause;
        });
        var filter = clauses.join(" or ");
        filter.length ? filter = "(" + filter + ")" : filter = "";
        this.store.facets.facets[facet.key].filterClause = filter;
        // return filter;
    }

    buildDateRangeFilter(facet: any) {
        var lowerFilter;
        var upperFilter;
        var filter = '';
        switch (facet.dataType) {
            case "number":
                lowerFilter = facet.filterLowerBound;
                upperFilter = facet.filterUpperBound;
                break;
            case "date":
                lowerFilter = facet.filterLowerBound.toISOString();
                upperFilter = facet.filterUpperBound.toISOString();
                break;
            default:
                break;
        }
        if (lowerFilter > 0 && upperFilter > 0) {
            filter = facet.key + " ge " + lowerFilter + " and " + facet.key + " le " + upperFilter;
        }

        if (lowerFilter == 0 && upperFilter > 0) {
            filter = facet.key + " le " + upperFilter;
        }
        if (lowerFilter > 0 && upperFilter == 0) {
            filter = facet.key + " ge " + lowerFilter;
        }

        this.store.facets.facets[facet.key].filterClause = filter;
    }

    buildRangeFilter(facet: any) {
        var lowerFilter;
        var upperFilter;
        switch (facet.dataType) {
            case "number":
                lowerFilter = facet.filterLowerBound;
                upperFilter = facet.filterUpperBound;
                break;
            case "date":
                lowerFilter = facet.filterLowerBound.toISOString();
                upperFilter = facet.filterUpperBound.toISOString();
                break;
            default:
                break;
        }
        if (facet.min === facet.filterLowerBound && facet.max === facet.filterUpperBound) {
            return "";
        }
        if (facet.min === facet.filterLowerBound) {
            return facet.key + " le " + upperFilter;
        }
        if (facet.max === facet.filterUpperBound) {
            return facet.key + " ge " + lowerFilter;
        }
        return facet.key + " ge " + lowerFilter + " and " + facet.key + " le " + upperFilter;
    }

    clearFacetsSelections() {
        var facets: any = {};
        let storeFacts = this.store.facets;
        Object.keys(storeFacts.facets).forEach((key) => {
            var facet = storeFacts.facets[key];
            switch (facet.type) {
                case "CheckboxFacet":
                    var values: any = {};
                    Object.keys(facet.values).forEach(function (value) {
                        var currentItem = facet.values[value];
                        var item = Object.assign(currentItem, { selected: false, count: 0 });
                        values[value] = item;
                    });
                    facets[key] = Object.assign(facet, { values: values, filterClause: "" });
                    break;
                case "RangeFacet":
                    let copyKey = this.endwithCopySuffix(key);
                    facets[key] = Object.assign(facet, {
                        filterLowerBound: facet.min,
                        filterUpperBound: facet.max,
                        lowerBucketCount: 0,
                        middleBucketCount: facet.middleBucketCount,
                        upperBucketCount: 0,
                        filterClause: "",
                        facetClause: copyKey ? `${key},sort:-value,count:1` : `${key},sort:value,count:1`,
                    });
                    break;
                default: break;
            }
        });

        this.store.facets = Object.assign(this.store.facets, { facets: facets });
        this.clearChips = true;
    }

    addChipsFacet(htmlId: string) {
        var chipsWrapperEle = document.getElementById(htmlId);
        if (chipsWrapperEle) {
            this.chipsWrapperEle = chipsWrapperEle;
            this.chipsWrapperEle.innerHTML = `
            <div class="tags-btn" aria-label="Filters">
                <div class="tags_wrap" style="display: none;"></div>
            </div>`
        }
    }

    addChip(chip: any, resetFn: any) {
        let tagsWrapper = this.chipsWrapperEle?.querySelector('.tags_wrap');

        let chipEle = document.createElement('div');
        chipEle.setAttribute('class', `tag_list`);
        chipEle.setAttribute('Id', chip.htmlId);
        chipEle.setAttribute('tabindex', '0');
        chipEle.setAttribute('aria-label', `${chip.value} Filter`);
        chipEle.innerHTML = `
                <span>${chip.value}</span>
                <button class="remove-chip" aria-label="remove ${chip.value} Filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8ZM5.46875 6.53125L6.9375 8L5.46875 9.46875C5.15625 9.78125 5.15625 10.25 5.46875 10.5312C5.75 10.8438 6.21875 10.8438 6.5 10.5312L7.96875 9.0625L9.46875 10.5312C9.75 10.8438 10.2188 10.8438 10.5 10.5312C10.8125 10.25 10.8125 9.78125 10.5 9.46875L9.03125 8L10.5 6.53125C10.8125 6.25 10.8125 5.78125 10.5 5.46875C10.2188 5.1875 9.75 5.1875 9.46875 5.46875L7.96875 6.96875L6.5 5.46875C6.21875 5.1875 5.75 5.1875 5.46875 5.46875C5.15625 5.78125 5.15625 6.25 5.46875 6.53125Z"
                        fill="#aa2213" fill-opacity="0.6" />
                </svg>
                </button>`;

        tagsWrapper?.append(chipEle);

        let removeBtn = chipEle?.querySelector('.remove-chip');
        removeBtn?.addEventListener('click', () => {
            let storeChips: any[] = this.store.facets.chips;
            let index = storeChips.findIndex(c => c.key == chip.key);

            if (index > -1) {
                resetFn();
                storeChips.splice(index, 1);
                this.removeChip(chip);
            }
        });

        this.updateCount(chip);
        // console.log('add', chip, this.store.facets.chips, this.store.facets.facets);
    }

    removeChip(chip: any) {
        let tagsWrapper = this.chipsWrapperEle?.querySelector('.tags_wrap');
        let ele: any = tagsWrapper?.querySelector(`#${chip.htmlId}`);

        if (tagsWrapper && ele) {
            tagsWrapper?.removeChild(ele);
        }
        this.updateCount(chip);
        // console.log('remove', chip, this.store.facets.chips, this.store.facets.facets);
    }

    updateCount(chip: any) {
        let storeChips: any[] = this.store.facets.chips;
        if (chip.type == "CheckboxFacet") {
            let count = storeChips.filter(c => c.key == chip.key).length;
            let facetcountEle: any = document.querySelector(`#${chip.facethtmlId} .facetcount`);

            if (count) {
                facetcountEle.innerHTML = `(${count} selected)`;
            } else {
                facetcountEle.innerHTML = '';
            }
        }

        if (this.chipsWrapperEle) {
            let tagsWrap: any = this.chipsWrapperEle.querySelector('.tags_wrap');
            if (tagsWrap && this.store.facets.chips.length == 0) {
                tagsWrap.style.display = 'none';
                tagsWrap.replaceChildren();
            } else {
                tagsWrap.removeAttribute('style');
            }
        }
    }

    removeSpacesAndSpecialCharacters(text: string) {
        // Replace all non-alphanumeric characters, spaces, and underscores with an empty string
        return text.replace(/[^a-zA-Z0-9]/g, '');
    }

    endwithCopySuffix(str: string): boolean {
        const suffix = 'Copy';
        if (str.endsWith(suffix)) {
            return true;
        } else {
            return false;
        }
    }

    replaceSingleQuote(val: string) {
        return val.replace("'", "''")
    }

    sortFacet(facetValues: any) {
        // Convert the object to an array of entries
        const entries: any[] = Object.entries(facetValues);

        // Sort the entries first by the selected property, then by the count value in descending order, and then alphabetically by value
        entries.sort(([, a], [, b]) => {
            if (b.selected !== a.selected) {
                return b.selected - a.selected; // Sort by selected property
            } else if (b.count !== a.count) {
                return b.count - a.count; // Sort by count in descending order
            } else {
                return a.value.localeCompare(b.value); // Sort alphabetically by value
            }
        });

        // Convert the sorted array back to an object
        const sortedFacetValues = Object.fromEntries(entries);

        return sortedFacetValues
    }

    // Function to remove ignored facets
    removeIgnoredFacets(facets: any, ignoreList: string[]) {
        for (let key in facets) {
            facets[key] = facets[key].filter((facet: { value: string; }) => !ignoreList.includes(facet.value));
        }
    }

    searchFacetHTML(facet: any) {
        let facetHTML = `<div class="pr_searchFacetWrapper">
                        <input type="text" placeholder="Search ${facet.title}" id="pr_search${facet.key}Facet" aria-label="search ${facet.title}">
                        <button type="button" title="search" id="pr_search${facet.key}FacetIconBtn" aria-label="search">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path
                                    d="M21.0111 21.0103C21.1118 20.9096 21.2751 20.9096 21.3758 21.0103L27.1111 26.7456C27.2119 26.8464 27.2119 27.0097 27.1111 27.1104C27.0104 27.2111 26.8471 27.2111 26.7464 27.1104L21.0111 21.3751C20.9103 21.2744 20.9103 21.1111 21.0111 21.0103Z"
                                    fill="#003DA6" stroke="#003DA6"></path>
                                <path
                                    d="M13.8545 23.709C8.39662 23.709 4 19.3123 4 13.8545C4 8.39662 8.39662 4 13.8545 4C19.3123 4 23.709 8.39662 23.709 13.8545C23.709 19.3123 19.3123 23.709 13.8545 23.709ZM13.8545 5.51607C9.23046 5.51607 5.51607 9.23046 5.51607 13.8545C5.51607 18.4785 9.23046 22.1929 13.8545 22.1929C18.4785 22.1929 22.1929 18.4785 22.1929 13.8545C22.1929 9.23046 18.4785 5.51607 13.8545 5.51607Z"
                                    fill="#003DA6"></path>
                            </svg>
                        </button>
                    </div>`;

        return facetHTML
    }

}
