<div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
        <h2>Add Assets</h2>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-label>{{searchPlaceholder}}</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [(ngModel)]="searchQuery" type="text" placeholder="Search" #searchInput
                (keyup.enter)="onSearch()" (keyup)="onSuggest()" />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput?.value"
                (click)="clearSearch(searchInput)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <button mat-icon-button (click)="closeDialog()" aria-label="Close">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div fxLayout="row" fxLayout.lt-md="column" fxFlexFill fxLayoutGap="16px">
    <div fxFlex="300px" fxFlex.lt-md="100%" class="filter-container">
        <div class="title">
            <h3>Filter By</h3>
        </div>

        <div class="filter-section">
            <mat-form-field appearance="outline" class="dropdown">
                <mat-label>Asset Type</mat-label>
                <mat-select [(ngModel)]="selectedFileTypes" multiple
                    [disabled]="!fileTypes.length || !showResults || searchResults.length == 0"
                    [compareWith]="compareWithIdFn">
                    <mat-option *ngFor="let fileType of fileTypes" [value]="fileType">{{fileType?.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="dropdown">
                <mat-label>Color Channels</mat-label>
                <mat-select [(ngModel)]="selectedColorChannels" multiple
                    [disabled]="!colorChannels.length || !showResults || searchResults.length == 0"
                    [compareWith]="compareWithIdFn">
                    <mat-option *ngFor="let colorChannel of colorChannels"
                        [value]="colorChannel">{{colorChannel?.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" class="dropdown">
                <mat-label>Asset Type</mat-label>
                <mat-select [(ngModel)]="selectedAssetType" multiple
                    [disabled]="!assetTypes.length || !showResults || searchResults.length == 0"
                    [compareWith]="compareWithIdFn">
                    <mat-option *ngFor="let asset of assetTypes" [value]="asset">{{asset?.value}}</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div class="filter-section">
            <h4>Date Uploaded</h4>
            <mat-form-field appearance="outline" class="date-input">
                <mat-label>Upload Start Date</mat-label>
                <input matInput [matDatepicker]="startDate" [(ngModel)]="uploadStartDate"
                    [disabled]="!showResults || searchResults.length == 0" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="date-input">
                <mat-label>Upload End Date</mat-label>
                <input matInput [matDatepicker]="endDate" [(ngModel)]="uploadEndDate"
                    [disabled]="!showResults || searchResults.length == 0" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
            <mat-error *ngIf="rangeInvalid">Start date must be before or equal to end date.</mat-error>
        </div>

        <!-- Buttons: Reset and Apply -->
        <div fxLayout="row" fxLayoutAlign="end center" class="filter-buttons">
            <button mat-stroked-button (click)="onFiltersReset()"
                [disabled]="!showResults || searchResults.length == 0">Reset</button>
            <button mat-flat-button color="primary" (click)="onFiltersApply()"
                [disabled]="!showResults || searchResults.length == 0">Apply</button>
        </div>
    </div>

    <div fxFlex class="right-content">
        <header fxLayout="row" fxLayoutAlign="space-between center" class="search-header">
            <div fxLayoutAlign="start center">
                <h4 aria-live="polite">
                    {{ showResults ? searchQueryVal ? searchResultsCount + ' results for "' + searchQueryVal + '"':'' : 'Loading...' }}
                </h4>
            </div>

            <div fxFlex="auto" fxLayoutAlign="end center">
                <mat-button-toggle-group #group="matButtonToggleGroup" value="{{defaultViewMode}}"
                    (change)="toggleView($event.value)" aria-label="Select view mode">
                    <mat-button-toggle value="{{ViewMode.Card}}" matTooltip="Card view" aria-label="Card view">
                        <mat-icon>grid_view</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="{{ViewMode.Table}}" matTooltip="Table view" aria-label="Table view">
                        <mat-icon>table_view</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </header>

        <div fxLayout="column" class="search-content" *ngIf="showResults">
            <div *ngIf="searchResults.length > 0; else noSearchResults">
                <div class="grid" *ngIf="defaultViewMode == ViewMode.Card; else defaultView">
                    <mat-card appearance="outlined" *ngFor="let asset of searchResults">
                        <div fxLayout="row" fxLayoutGap="16px">
                            <!-- Image Section -->
                            <div fxFlex="50" fxLayoutAlign="center center" class="image-container">
                                <img mat-card-md-image
                                    [src]="utilityService.getSearchFilePreviewUrl(asset, PreviewMode.Medium)"
                                    alt="Asset Image" (error)="onImageError($event)">
                                <mat-checkbox class="corner-checkbox" appAuthorizeControl="edit||offermang"
                                    (click)="$event.stopPropagation()" fxFlex="30px"
                                    (change)="$event ? selection.toggle(asset) : null" [disabled]="isDisabled(asset)"
                                    [checked]="selection.isSelected(asset)">
                                </mat-checkbox>
                            </div>

                            <!-- Content Section -->
                            <div fxFlex="50">
                                <mat-card-content>
                                    <strong *ngIf="asset?.dateAdded" [title]="asset.assetName"
                                        class="offer-headline ellipsis-3-line">{{asset.assetName }}</strong>
                                    <div *ngIf="asset?.dateAdded" class="offer-headline ellipsis-3-line" [title]="asset.dateAdded">
                                        Uploaded: {{asset.dateAdded }}</div>
                                    <div *ngIf="asset?.assetGroupName" class="ellipsis-3-line"> {{ asset?.assetGroupName
                                        }}</div>
                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <span class="footer-text">{{ asset?.fileType }}</span>
                                    </div>
                                </mat-card-content>
                            </div>
                        </div>
                    </mat-card>
                </div>

                <ng-template #defaultView>
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort($event)"
                        matSortDisableClear [matSortStart]="'asc'">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                                <mat-checkbox appAuthorizeControl="edit||offermang" [checked]="isAllSelectedOnPage()"
                                    [indeterminate]="isSomeSelectedOnPage() && !isAllSelectedOnPage()"
                                    (change)="toggleSelectAll($event.checked)" [disabled]="searchResults.length === 0">
                                </mat-checkbox>
                            </th>
                            <td class="no-ellipsis" mat-cell *matCellDef="let row">
                                <mat-checkbox appAuthorizeControl="edit||offermang" (click)="$event.stopPropagation()"
                                    (change)="toggleSelect($event.checked, row)" [checked]="selection.isSelected(row)"
                                    [disabled]="isDisabled(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!--  Image Column -->
                        <ng-container matColumnDef="Image">
                            <th style="width:8%" mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                                Preview
                            </th>
                            <td mat-cell class="offer-image no-ellipsis" *matCellDef="let record">
                                <img mat-card-md-image
                                    [src]="utilityService.getSearchFilePreviewUrl(record, PreviewMode.Thumbnail)"
                                    alt="Asset Image" (error)="onImageError($event)">
                            </td>
                        </ng-container>

                        <!-- AssetName Column -->
                        <ng-container matColumnDef="AssetName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"
                                [resizeColumn]="true">
                                Asset Name
                            </th>
                            <td mat-cell *matCellDef="let record" [title]="record.assetName">{{ record.assetName }}</td>
                        </ng-container>

                        <!-- FileName Column -->
                        <ng-container matColumnDef="FileName">
                            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by FileName"
                                [resizeColumn]="true">
                                File Name
                            </th>
                            <td mat-cell *matCellDef="let record">{{ record.fileName }}</td>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container matColumnDef="Type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type"
                                [resizeColumn]="true">
                                Type
                            </th>
                            <td mat-cell *matCellDef="let record">{{ record.fileType }}</td>
                        </ng-container>

                        <!-- Group Column -->
                        <ng-container matColumnDef="Group">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Group"
                                [resizeColumn]="true">
                                Group
                            </th>
                            <td mat-cell *matCellDef="let record">{{ record.assetGroupName }}</td>
                        </ng-container>

                        <!-- DateAdded Column -->
                        <ng-container matColumnDef="DateAdded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by DateAdded" [resizeColumn]="true">
                                Date Uploaded
                            </th>
                            <td mat-cell *matCellDef="let record" [title]="record.dateAdded">{{ record.dateAdded }}</td>
                        </ng-container>

                        <!-- ClientKey Column -->
                        <ng-container matColumnDef="ClientKey">
                            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by ClientKey"
                                [resizeColumn]="true">
                                Client Key
                            </th>
                            <td mat-cell *matCellDef="let record">{{ record.assetClientKey }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </ng-template>
            </div>
            <ng-template #noSearchResults class="no-records">
                <div class="no-records">
                    <h2 aria-live="polite">No records found.</h2>
                </div>
            </ng-template>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="searchResults.length > 0">
            <mat-paginator (page)="onPageChange($event)" [length]="pageLength" [pageSize]="pageSize"
                [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex" aria-label="Select page">
            </mat-paginator>
        </div>

        <!-- Buttons: Cancel and Select -->
        <div fxLayout="row" fxLayoutAlign="end center" class="filter-buttons" *ngIf="searchResults.length > 0">
            <button mat-stroked-button (click)="closeDialog()">Cancel</button>
            <button mat-flat-button color="primary" (click)="onSelect()"
                [disabled]="!showResults || searchResults.length == 0 || !isSelectionChanged">Select</button>
        </div>
    </div>
</div>